import React from "react";
import { ThemeProvider } from "styled-components";
import { useSelector } from "react-redux";
import { lightTheme,darkTheme } from "./Themes";

const Theme = ({ children }) => {
  const { theme } = useSelector((state) => state.theme);
   console.log(theme,"themmmmmmmmmmm");


  return (
    <>
      <ThemeProvider theme={theme==="light"? lightTheme : darkTheme }>
        {children}
      </ThemeProvider>  
    </>
  );
};

export default Theme;
