import React, { useEffect } from "react";
import Header from "../Component/Header";
import Sidebar from "../Component/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import Stake_inr from "./Stake_inr";
import Redeem from "./Redeem";
import Earn_faq from "./Earn_faq";
import Reward_history from "./Reward_history";
import { cutAfterDecimal, stakeHistory } from "../utils/actionHandler";
import { aprSet } from "../utils/apis";
const Earn = () => {
  const theme = useSelector((state) => state.theme.theme);
  const { allstakes, prices, assets } = useSelector((state) => state.wallet);
  const { user, sessionId,userdata } = useSelector((state) => state.user);
  const usdt = assets?.find((it) => it.symbol == "USDT");
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      stakeHistory(userdata, dispatch, sessionId, () => {});
    }, 1500);
  }, [userdata]);

  return (
    <>
      <Header />
      <Sidebar />
      <main className="mt-5 pt-5 deposit_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-7 mb-3">
              <div className="mb-3">
                <h1 className="fs-3" style={{ fontWeight: "600" }}>
                  Earn
                </h1>
                <div className="text-muted ">
                  Earn daily yield & Utilize your stacking
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-lg-6 col-sm-12">
                  <div className="d-flex gap-3 earn_data">
                    <div className="">
                      <i className="fa-solid fa-check"></i>
                    </div>
                    <div className=""> Easy to get started</div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="d-flex gap-3 earn_data">
                    <div className="">
                      <i className="fa-solid fa-check"></i>
                    </div>
                    <div className="">Secure and transparent</div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="d-flex gap-3 earn_data">
                    <div className="">
                      <i className="fa-solid fa-check"></i>
                    </div>
                    <div className=""> Utilize staked INRx</div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="d-flex gap-3 earn_data">
                    <div className="">
                      <i className="fa-solid fa-check"></i>
                    </div>
                    <div className=""> Flexible exit policy</div>
                  </div>
                </div>
              </div>
              <div className="button_grid">
                <Stake_inr />
                <Redeem />
                <Earn_faq />
              </div>
              <div className="accordian_sec mt-5">
                <div>
                  <div className="fs-4" style={{ fontWeight: "600" }}>
                    {aprSet}%
                  </div>
                  <div className=" text-muted">Reference APR</div>
                </div>
                <div>
                  <div className="fs-4" style={{ fontWeight: "600" }}>
                  10,000 INRx
                    {/* 1 USDT ={" "}
                    {usdt ? cutAfterDecimal(usdt?.inrx_price, 4) : "0.00"} INRx */}
                  </div>
                  <div className=" text-muted">
                  Staking Bonus{" "}
                    {/* <i className="fa-solid fa-arrow-right-arrow-left"></i> */}
                  </div>
                </div>
                <div>
                  <div className="fs-4" style={{ fontWeight: "600" }}>
                    {allstakes
                      ? cutAfterDecimal(allstakes?.INRx?.stakeAmount, 4)
                      : "0.00"}{" "}
                    INRx
                  </div>
                  <div className=" text-muted">Total Value Staked</div>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-4 mb-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <div className="fs-5 fw-bold mb-2">My Staked INRx</div>
                    <div className="d-flex gap-3">
                      <i className="fa-solid fa-wallet"></i>
                      <i className="fa-solid fa-clock"></i>
                    </div>
                  </div>
                  <div className="d-flex gap-2 align-items-center mb-2">
                    <div className="">
                      {allstakes
                        ? cutAfterDecimal(allstakes?.INRx?.stakeAmount, 4)
                        : "0.00"}
                    </div>
                    <div>
                      <img
                        src="images/favicon.svg"
                        alt="inrx"
                        style={{ width: "20px" }}
                      />
                    </div>
                    <div className="">INRx</div>
                  </div>
                  <div className="text-muted">
                    <span>
                      {/* =$
                      {allstakes && usdt?.inrx_price
                        ? cutAfterDecimal(
                            allstakes?.INRx?.stakeAmount / usdt?.inrx_price,
                            4
                          )
                        : "0.00"} */}
                        
                        {allstakes
                        ? cutAfterDecimal(
                            allstakes?.INRx?.stakeAmount,
                            4
                          )
                        : "0.00"} {"INRx"}
                    </span>
                  </div>
                  <hr className="dropdown-divider" />
                  <div className="accordian_sec">
                    <div className="text-muted ">30-Days Reward</div>
                    <div className="d-flex gap-3">
                      <Reward_history />
                    </div>
                  </div>

                  <div className=" text-green">
                    {allstakes
                      ? cutAfterDecimal(allstakes?.INRx?.roi, 4)
                      : "0.00"}{" "}
                    INRx
                  </div>

                  <div className="text-muted">
                    <span>
                      {/* =$ */}
                      {/* {allstakes && usdt?.price
                        ? cutAfterDecimal(allstakes?.INRx?.roi / usdt?.price, 4)
                        : "0.00"} */}
                        {allstakes 
                        ? cutAfterDecimal(allstakes?.INRx?.roi, 4)
                        : "0.00"} INRx
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="card mt-5">
            <div className="card-body ">
              <div className="">
                <img
                  src={
                    theme === "dark" ? "images/earn.png" : "images/download.png"
                  }
                  alt="earn"
                  className="w-100"
                />
              </div>
            </div>
          </div> */}
          <div className="border-divider"></div>

          <div className="highlight_card">
            <div className="col-lg-12">
              <h4 className="heading">Start earning in 3 steps</h4>
              <div class="flex_row mt-3">
                <div class="mb-3">
                  <div class="text-center ">
                    <div class="card-body">
                      <div className="text-center">
                        <img
                          src="/images/number-circle-one.svg"
                          alt=""
                          className="img_50 "
                        />
                      </div>
                      <h5 class="refer_heading">Stake INRx</h5>
                      <p class="text-lgray mb-0">
                      Stake INRx and get INR as the tokenized representation of your staked INRx.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="arrow_col">
                  <img
                    src="/images/rewards/arrow.png"
                    alt=""
                    className="img_50 hide_on_mobile"
                  />
                </div>
                <div class="mb-3">
                  <div class="text-center ">
                    <div class="card-body ">
                      <div className="text-center">
                        <img
                          src="/images/number-circle-two.svg"
                          alt=""
                          className="img_50"
                        />
                      </div>
                      <h5 class="refer_heading">Earn daily rewards</h5>
                      <p class="text-lgray mb-0">
                      Earn yield by holding INR or use your INR for a variety of use cases.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="arrow_col">
                  <img
                    src="/images/rewards/arrow.png"
                    alt=""
                    className="img_50 hide_on_mobile"
                  />
                </div>
                <div class="mb-3">
                  <div class="text-center">
                    <div class="card-body ">
                      <div className="text-center">
                        <img
                          src="/images/number-circle-three.svg"
                          alt=""
                          className="img_50"
                        />
                      </div>
                      <h5 class="refer_heading">Flexibilty to Redeem INRx</h5>
                      <p class="text-lgray mb-0">
                      Redeem INRx for INRx
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Earn;
