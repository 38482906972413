import React, { useEffect, useState } from "react";
import Header from "../Component/Header";
import Sidebar from "../Component/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import QRCode from "react-qr-code";
import { depositHistory, getBank, newurl, nScreenShot, withdrawDetails } from "../utils/apis";
import { toast } from "react-toastify";
import { FaRegCheckCircle, FaSortDown, FaSortUp } from "react-icons/fa";
import { cutAfterDecimal } from "../utils/actionHandler";
import { PiSealWarningLight } from "react-icons/pi";
import { MdError } from "react-icons/md";
import { capitalizeFirstLetter } from "../utils/helperFunctions";

const Depost_inr = () => {
  const { user, sessionId } = useSelector((state) => state.user);
  const [filedata, setFileData] = useState();
  const [bankData, setBankData] = useState(null);
  const [amount, setAmount] = useState(0);
  const [transection_no, setTransectionNo] = useState(null);

  const [depositlist, setdepositlist] = useState([]);
  const [depositDetailsData, setDepositDetails] = useState();

  const uploadIMG = (input) => {
    if (input.target.files && input.target.files[0]) {
      setFileData(input.target.files[0]);
    }
  };
  useEffect(() => {
    getBank().then((res) => {
      if (res.status == 200) {
        setBankData(res.bank);
      }
    });
  }, []);

  useEffect(() => {
    if (user?.mobile && sessionId) {
      depositHistory(user.mobile, sessionId, "Rupees")
        .then((res) => {
          if (res?.status == 200) {
            setdepositlist(res?.data);
          }
        })
        .catch((e) => {
          console.log(e, "Error in depositHistory:deposit page");
        });
    }
  }, [user, sessionId]);

  useEffect(()=>{
    withdrawDetails().then((res) => {
      if (res?.status == 200) {
        setDepositDetails(res?.data);
      }
    })
    .catch((e) => {
      console.log(e, "Error in setWithdrawList:withdraw page");
    });
  },[])

  return (
    <>
      <Header />
      <Sidebar />

      <main className="mt-5 pt-5">
        <div className="container-fluid">
          <div class="d-flex justify-content-between mb-4">
            <div>
              <h4 class="heading">Deposit INR</h4>
            </div>
            <div>
              <a class="custom_btn_outline fs-14" href="/deposit">
                Deposit INRx<i class="fa-solid fa-arrow-right-long ms-1"></i>
              </a>
            </div>
          </div>

          <div className="">
            <div className="col-lg-12 m-auto">
              <div className="row">
                <div className="col-lg-4 mb-4">
                  <div className="">
                    <div className="">
                      <div class="mb-3">
                        {/* <label for="INR" class="text-muted">
                          INR
                        </label> */}
                        <div className="select_box form-control custombg">                          
                          <div className="select_box_inner">
                            <div class="d-flex align-items-center">
                              <div>
                                <img
                                  src="https://bitbse.xyz/rupee.png"
                                  alt="coin symbole"
                                  class="coin_img me-2"
                                />
                              </div>
                              <div class="product_name">
                                INR
                                <span className="unit text-muted ms-1">
                                  RUPEE
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div
                        class="alert alert-success d-flex gap-1 p-2"
                        role="alert"
                      >
                        <i class="bi bi-info-circle"></i>
                        <div class="mob-font">
                          Please Deposit INR to this address. If you Deposit any
                          other QR or below minimum limit, It will be lost
                          forever.
                        </div>
                      </div> */}

                      <label className="text-lgray mb-1" for="screenshot">
                        Upload Screenshot File
                      </label>
                      <div className="form-group">
                        <input
                          class="form-control custombg ps-3"
                          type="file"
                          onChange={(e) => {
                            uploadIMG(e);
                          }}
                        />
                      </div>
                      <label className="mt-2 mb-1 text-lgray">Amount</label>
                      <input
                        type="text"
                        className="form-control custombg buy-sell-form-bg buy-sell-theme"
                        required
                        placeholder="Amount"
                        id="amount"
                        value={amount}
                        onChange={(e) => {
                          setAmount(e.target.value);
                        }}
                      />
                      <label className="mt-2 text-lgray mb-1">
                        Transaction Id
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Transaction Id"
                        className="form-control custombg buy-sell-form-bg buy-sell-theme"
                        required
                        id="transection_no"
                        value={transection_no}
                        onChange={(e) => {
                          setTransectionNo(e.target.value);
                        }}
                      />
                      <div className="d-grid mt-3">
                        <button
                          className="btn custom_btn"
                          disabled={amount < 1000}
                          onClick={(e) => {
                            nScreenShot(
                              e,
                              user?.email,
                              sessionId,
                              filedata,
                              amount,
                              transection_no
                            ).then((res) => {
                              console.log("res", res);
                              if(res.status === 200){
                                if(res.data.status === 200){
                                  toast.success(res.data.message)
                                }else{
                                  toast.error(res.data.message)
                                }
                              } else{
                                toast.error(res.data.message)
                              }
                            });
                          }}
                        >
                          Upload
                        </button>
                      </div>

                      <div
                        className="spinner-border text-primary"
                        style={{
                          display: "none",
                        }}
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-1"></div>

                <div className="col-lg-5 mb-3">
                  <div className="card ">
                    <div className="card-body">
                      <div className="d-flex">
                        <h5 className="mb-0"> Bank Transfer </h5>
                      </div>
                      <hr/>
                      <div className="row mt-3">
                        <div className="col-lg-12">
                          <div className="table-responsive">
                            <table className="table table-borderless ">
                              <tbody>
                                <tr>
                                  <td className="text-lgray text-left">
                                    Bank Name
                                  </td>
                                  <td className="text-right">
                                    {bankData?.bank_name}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-lgray text-left">
                                    Bank Account Name
                                  </td>
                                  <td className="text-right">
                                    {bankData?.name}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-lgray text-left">
                                    Bank Account Number
                                  </td>
                                  <td className="text-right">
                                    {bankData?.account_number}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-lgray text-left">
                                    Bank IFSC Code
                                  </td>
                                  <td className="text-right">
                                    {bankData?.ifsc}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-lgray text-left">
                                    UPI ID
                                  </td>
                                  <td className="text-right">
                                    {bankData?.upi_id}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="flex_row mt-1 alert alert-warning  mob-font">
                            <div className=" text-lgray">
                              Minimum Deposit Amount
                            </div>
                            <div className="fw-semibold ">{depositDetailsData?.minimum_deposit_inr} INR</div>
                          </div>
                        </div>
                        <div className="col-lg-5 d-none">
                          <div className="my-3 text-center">
                            <img
                              src={`${newurl}/support/images/deposit/${bankData?.qr}`}
                              className="img-fluid mb-4"
                              width="200px"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            

            {/* deposit history table*/}
            <div className="row">
              <div className="col-lg-12 mt-5">
                <div className="">
                  <h4 className=""> Deposit History </h4>
                </div>
              </div>

             <div className="d-lg-block d-none mt-3">
              {depositlist?.length == 0 || !depositlist ? (
                <div className="card">
                  <div className="card-body">
                    <div colSpan={6} className="text-center">
                      <img src="images/no-data.png" className="no-data-found" />
                    </div>
                  </div>
                </div>
              ) : (
                depositlist.map((item, i) => {
                  console.log(item, "item");
                  const currenttime = Math.floor(new Date().getTime() / 1000);
                  const status = !item?.success ? "Pending" : "Success";
                  return (
                    <>
                      <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                          <h2 class="accordion-header ">
                            <button
                              className="accordion-button collapsed fs-14 d-flex justify-content-between"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#accordionDetails${i}`}
                              aria-expanded="true"
                              aria-controls={`accordionDetails${i}`}
                            >
                              <div className="d-flex justify-content-between w-75">
                              <div className="d-flex flex-column text-start">
                                <span className="fw-bold">{item?.symbol}</span>
                                <span>
                                  {new Date(item?.createdAt).toLocaleString()}
                                </span>
                              </div>
                              <div className="d-flex flex-column me-5">
                                <span className="fw-bold fs-14">
                                  {cutAfterDecimal(item?.amount, 4)}
                                </span>
                                <span
                                  className={`${
                                    item?.status === "approve"
                                        ? "text-green"
                                        : item?.status === "pending"
                                        ? "text-warning"
                                        : "text-danger"
                                  }`}
                                >
                                  {item?.status === "approve" ? "Success" : capitalizeFirstLetter(item?.status)}
                                </span>
                                {/* <span
                                  className={`${
                                    item?.type === "Rupees"
                                      ? status === "Success"
                                        ? "text-green"
                                        : status === "Pending"
                                        ? "text-warning"
                                        : "text-danger"
                                      : "text-green"
                                  }`}
                                >
                                  {item?.type === "Rupees" ? status : "Success"}
                                </span> */}
                              </div>
                              </div>
                            </button>
                          </h2>
                          <div
                            id={`accordionDetails${i}`}
                            class="accordion-collapse collapse"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <div className="text-center my-4">
                                Quantity
                                <h4 className="fw-bold">
                                  {item?.amount} {item?.symbol}
                                </h4>
                                <p
                                  className={`text-center ${
                                    item?.status === "approve"
                                        ? "text-green"
                                        : item?.status === "pending"
                                        ? "text-warning"
                                        : "text-danger"
                                  }`}
                                >
                                  {item?.type == "Rupees" ? (
                                    item?.type == "approve" ? (
                                      <FaRegCheckCircle className="me-1" />
                                    ) : item?.type == "pending" ? (
                                      <PiSealWarningLight className="me-1" />
                                    ) : (
                                      <MdError className="me-1" />
                                    )
                                  ) : (
                                    <FaRegCheckCircle className="" />
                                  )}
                                  {item?.status == "approve"
                                      ? "Succeeded"
                                      : item?.status == "pending"
                                      ? "Pending"
                                      : "Failed"
                                    }
                                </p>
                                {/* <p className="text-warning"><PiSealWarningLight className="" /> Pending</p> */}
                              </div>

                              <div className="d-flex justify-content-between mb-2">
                                <div className="text-start">
                                  <p className="mb-0 text-lgray">
                                    Deposit Account
                                  </p>
                                </div>
                                <div className="text-end">
                                  <p className="mb-0">Funding Account</p>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between mb-2">
                                <div className="text-start">
                                  <p className="mb-0 text-lgray">Chain Type</p>
                                </div>
                                <div className="text-end">
                                  <p className="mb-0">{item?.type}</p>
                                </div>
                              </div>

                              <div className="d-flex justify-content-between mb-2">
                                <div className="text-start">
                                  <p className="mb-0 text-lgray">Time</p>
                                </div>
                                <div className="text-end">
                                  <p className="mb-0">
                                    {new Date(item?.createdAt).toLocaleString()}
                                  </p>
                                </div>
                              </div>

                              <div className="d-flex justify-content-between mb-2">
                                <div className="text-start">
                                  <p className="mb-0 text-lgray">
                                    Deposit Address
                                  </p>
                                </div>
                                <div className="text-end">
                                  <p className="mb-0">0x000000000</p>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between mb-2">
                                <div className="text-start">
                                  <p className="mb-0 text-lgray">
                                    Transaction Hash
                                  </p>
                                </div>
                                <div className="text-end">
                                  <p className="mb-0">{item?.hash}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })
              )}
            </div>

              <div className=" d-lg-none d-block mt-3">
                {depositlist?.length == 0 || !depositlist ? (
                  <div className="card">
                    <div className="card-body">
                      <div colSpan={6} className="text-center">
                        <img
                          src="images/no-data.png"
                          className="no-data-found"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  depositlist.map((item, i) => {
                    console.log(item,"item")
                    const currenttime = Math.floor(new Date().getTime() / 1000);
                    const status = !item?.success ? "Pending" : "Success";
                    return (
                      <>
                        <div
                          data-bs-toggle="offcanvas"                          
                          data-bs-target={`#depositDetails${i}`}
                        >
                          <div key={i + "DepsoitList"}>
                            <div className="card mb-2">
                              <div className="card-body p-2">
                                <div className="d-flex justify-content-between">
                                  <div className="text-start">
                                    <span className="fw-bold">
                                      {item?.symbol}
                                    </span>
                                  </div>
                                  <div className="text-end">
                                    <span className="fw-bold">
                                      {" "}
                                      {cutAfterDecimal(item?.amount, 4)}
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <div className="text-end">
                                    {/* {item?.type == "Rupees"
                                  ? item?.hash
                                  : item?.address} */}
                                    {new Date(item?.createdAt).toLocaleString()}
                                  </div>
                                  <div
                                    className={`text-end ${
                                      item?.status === "approve"
                                        ? "text-green"
                                        : item?.status === "pending"
                                        ? "text-warning"
                                        : "text-danger"
                                    }`}
                                  >
                                    {item?.status == "approve"
                                      ? "Success"
                                      : capitalizeFirstLetter(item?.status)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="d-lg-none d-block">
                <div
                  class="offcanvas offcanvas-bottom"
                  tabindex="-1"
                  // id="depositDetails"
                  id={`depositDetails${i}`}
                  aria-labelledby="depositDetails"
                >
                  <div class="offcanvas-header">
                    <h5 id="depositDetails">Deposit Details</h5>
                    <div data-bs-dismiss="offcanvas" aria-label="Close"><i class="fa-solid fa-xmark fs-4 icon-color"></i></div>
                  </div>
                  <div class="offcanvas-body">
                            <div className="text-center my-4">
                              Quantity
                              <h4 className="fw-bold">{item?.amount} {item?.symbol}</h4>
                              <p className={`text-center ${
                                       item?.status === "approve"
                                        ? "text-green"
                                        : item?.status === "pending"
                                        ? "text-warning"
                                        : "text-danger"
                                    }`}>
                                    {
                                      item?.type == "Rupees"? item?.status === "approve"? <FaRegCheckCircle className="me-1 fs-5" />
                                      : item?.status === "pending"? <PiSealWarningLight className="me-1 fs-4" /> : <MdError className="me-1 fs-4" />
                                      : <FaRegCheckCircle className="fs-5" />
                                    }
                                    {
                                      item?.type == "Rupees"?  item?.status === "approve"? "Succeeded"
                                      : item?.status === "pending"? "Pending": "Failed"
                                        : "Succeeded"
                                    }
                                    
                              </p>
                              {/* <p className="text-warning"><PiSealWarningLight className="" /> Pending</p> */}
                            </div>

                            <div className="d-flex justify-content-between mb-2">
                              <div className="text-start">
                                <p className="mb-0 text-lgray">
                                  Deposit Account
                                </p>
                              </div>
                              <div className="text-end">
                                <p className="mb-0">Funding Account</p>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                              <div className="text-start">
                                <p className="mb-0 text-lgray">Chain Type</p>
                              </div>
                              <div className="text-end">
                                <p className="mb-0">{item?.type}</p>
                              </div>
                            </div>

                            <div className="d-flex justify-content-between mb-2">
                              <div className="text-start">
                                <p className="mb-0 text-lgray">Time</p>
                              </div>
                              <div className="text-end">
                                <p className="mb-0">{new Date(item?.createdAt).toLocaleString()}</p>
                              </div>
                            </div>

                            <div className="d-flex justify-content-between mb-2">
                              <div className="text-start">
                                <p className="mb-0 text-lgray">
                                  Deposit Address
                                </p>
                              </div>
                              <div className="text-end">
                                <p className="mb-0">0x000000000</p>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                              <div className="text-start">
                                <p className="mb-0 text-lgray">
                                  Transaction Hash
                                </p>
                              </div>
                              <div className="text-end">
                                <p className="mb-0">{item?.hash}</p>
                              </div>
                            </div>
                          </div>
                </div>
              </div>

                       
                      </>
                    );
                  })
                )}
              </div>
              {/*======= depositDetails========= */}
              
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Depost_inr;
