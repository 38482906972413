import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { aprSet, stakeINRXToken } from "../utils/apis";
import {
  cutAfterDecimal,
  fetchWalletHandler,
  stakeHistory,
} from "../utils/actionHandler";
import { Bounce, toast } from "react-toastify";

const Stake_inr = () => {
  const dispatch = useDispatch();
  const { user, sessionId, userdata } = useSelector((state) => state.user);
  const { assets } = useSelector((state) => state.wallet);
  const [stakeToken, setstakeToken] = useState("");
  const [getToken, setGetToken] = useState("");
  const [stakeAmount, setstakeAmount] = useState("");
  const [inrxAmount, setinrxAmount] = useState("");
  const [tokenprice, setTokenPrice] = useState(0);
  const [loading, setLoading] = useState(false);

  async function calulateToken(token) {
    const coin = token?.toUpperCase();
    if (coin != "INR" && coin != "USDT") {
      const asset = assets.find((it) => it?.symbol == coin);
      setTokenPrice(asset?.inrx_price ? asset?.inrx_price : 0);
    } else {
      setTokenPrice(1);
    }
  }

  function setMaxAmount() {
    const maxAmount = getToken?.available; // Replace with the actual variable holding the available balance
    const amountPer10 = (Number(maxAmount) * 0.1);
    const famt = amountPer10.toString().indexOf(".") > -1 ? amountPer10.toFixed(2) : amountPer10;
  
    setinrxAmount(famt?.toString());
    setstakeAmount(maxAmount);
  }
  

  function stakeamounttype(val) {
    if (val && Number(val) > 0) {
      // console.log(val,tokenprice)
      const amountPer10 = (Number(val) * 0.1);
      const getamt = (Number(val) + amountPer10) ;
      const famt = amountPer10.toString().indexOf(".") > -1 ? amountPer10.toFixed(2) : amountPer10;
      setinrxAmount(famt?.toString());
    } else {
      setinrxAmount("");
    }
    setstakeAmount(val);
  }

  // function inrxamounttype(val) {
  //   if (val && Number(val) > 0) {
  //     const amountPer10 = (Number(val) * 0.1);
  //     const getamt = (Number(val)) / Number(tokenprice);
  //     const famt =
  //       getamt.toString().indexOf(".") > -1 ? getamt.toFixed(3) : getamt;
  //     setstakeAmount(famt?.toString());
  //   } else {
  //     setstakeAmount("");
  //   }
  //   setinrxAmount(val);
  // }
  function inrxamounttype(val) {
    if (val && Number(val) > 0) {
      const getamt = (Number(val) / 0.1); // Convert the INRx amount to the base amount
      const famt = (getamt ); // Calculate the final amount using token price
      const finalAmount = famt.toString().indexOf(".") > -1 ? famt.toFixed(3) : famt;
      setstakeAmount(finalAmount?.toString());
    } else {
      setstakeAmount("");
    }
    setinrxAmount(val);
  }

  function stake() {
    const data = {
      mobile: userdata.mobile,
      symbol: "INRx",
      amount: stakeAmount,
      rewardAmount:inrxAmount,
      tokenId: sessionId,
    };
    if (stakeAmount > getToken?.available) {
      toast.error("Insufficient funds!");
      return;
    }
    if (inrxAmount > getToken?.rewardBonus) {
      toast.error("Insufficient reward bonus funds!");
      return;
    }
    if ((stakeAmount+inrxAmount) < 1000) {
      toast.error("You can stake minimum 1000 INRx!");
      return;
    }
    if(loading){
      toast.info("Please wait for pending request")
      return
    }
    setLoading(true);
    stakeINRXToken(data)
      .then((res) => {
        if (res.status == 200) {
          setstakeAmount("");
          setinrxAmount("");
          toast.success("Stake Successful!");
          fetchWalletHandler(userdata?.mobile, sessionId, dispatch);
          setTimeout(() => {
            stakeHistory(userdata, dispatch, sessionId, () => {});
          }, 1500);
        } else {
          toast.error(res?.message);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        toast.error("Something went wrong!");
        console.log(e, "Error in stake():StakeFarm.tsx");
      });
  }

  useEffect(() => {
    const staketoken = assets.find((it) => it.symbol == "USDT");
    const gettoken = assets.find((it) => it.symbol == "INRX");
    setstakeToken(staketoken);
    setGetToken(gettoken);
  }, [assets]);

  useEffect(() => {
    if (stakeAmount && tokenprice) {
      const amountPer10 = (Number(stakeAmount) * 0.1);
      const amt = (Number(stakeAmount) - amountPer10) * tokenprice;
      const famt = amountPer10.toString().indexOf(".") > -1 ? amountPer10.toFixed(2) : amountPer10;
      setinrxAmount(famt.toString());
    }
  }, [tokenprice]);

  useEffect(() => {
    calulateToken("USDT");
  }, []);

  return (
    <>
      <button
        type="button"
        className="btn earn_btn"
        data-bs-toggle="modal"
        data-bs-target="#stake"
      >
        Stake INRx
      </button>

      <div
        className="modal fade"
        id="stake"
        tabIndex="-1"
        aria-labelledby="stakeLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered staking">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="stakeLabel">
                Stake INRx{" "}
              </h1>
              <i
                className="fa-solid fa-xmark icon-color"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="d-flex">
                <div className="d-flex align-items-center jutify-content-center gap-2 m-auto">
                  <div className="border border-warning rounded-pill text-center pe-3 ps-3">
                  APR {aprSet}%
                    {/* 1 USDT ={" "}
                    {stakeToken
                      ? cutAfterDecimal(stakeToken?.inrx_price, 3)
                      : "90"}{" "}
                    INRx */}
                  </div>
                  {/* <i className="fa-solid fa-right-left text-green"></i> */}
                </div>
              </div>
              <div className="">
                <div className="mb-2 mt-sm-3 mob-font d-flex justify-content-between align-items-center">
                  <div className="text-muted me-2">Stake Amount </div>
                  <div className="text-muted me-2">
                    Available:{" "}
                    <b>
                      {stakeToken
                        ? cutAfterDecimal(getToken?.available, 4)
                        : "0.00"}
                    </b>{" "}
                    INRx{" "}
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control p-1 custombg"
                    placeholder="Min 0.00001"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    value={stakeAmount}
                    onChange={(e) => stakeamounttype(e.target.value)}
                  />
                  <span
                    className="input-group-text text-green custombg"
                    id="basic-addon2"
                    style={{ color: "#02FF01" }}
                    onClick={setMaxAmount}
                  >
                    Max
                  </span>
                  <span className="input-group-text custombg" id="basic-addon2">
                    <span className="coin_style4 ">
                      <img src="images/favicon.svg" alt="inrx" />{" "}
                    </span>{" "}
                    &nbsp; INRx
                  </span>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="coin_style2">
                    <i className="fa-solid fa-arrow-down"></i>
                  </div>
                </div>
                <div className="">
                  <div className="mb-2 mt-sm-3 mob-font ">
                    <div className="text-muted me-2"></div>
                  </div>
                  <div className="mb-2 mt-sm-3 mob-font d-flex justify-content-between align-items-center">
                    <div className="text-muted me-2">Staking Bonus </div>
                    <div className="text-muted me-2">
                      Available:{" "}
                      <b>
                        {getToken
                          ? cutAfterDecimal(getToken?.rewardBonus, 4)
                          : "0.00"}
                      </b>{" "}
                      INRx{" "}
                    </div>
                  </div>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control p-1 custombg"
                      placeholder="-----"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      value={inrxAmount}
                      onChange={(e) => inrxamounttype(e.target.value)}
                    />
                    <span
                      className="input-group-text custombg"
                      id="basic-addon2"
                    >
                      <span className="coin_style4 p-0">
                        <img src="images/favicon.svg" alt="inrx" />{" "}
                      </span>{" "}
                      &nbsp; INRx
                    </span>
                  </div>
                </div>
                <div className="">
                  <div
                    className="alert alert-success d-flex  gap-1 p-2"
                    role="alert"
                  >
                    <i className="bi bi-info-circle"></i>
                    <div className="fs-12">
                      When stacking 1 INRx, you receive less than 1 INR because
                      INR has higher value increases over time as staking
                      rewards accrue after launch. Upon redemption, you will get
                      back your initial INRx and earned rewards.
                    </div>
                  </div>
                  <table className="market-details-table mb-2 table-borderless">
                    <tbody>
                      <tr>
                        <th
                          role="cell"
                          scope="row"
                          className="fw-normal text-muted"
                        >
                          Staking Amount
                        </th>
                        <td className=" ">
                          {" "}
                          {Number(stakeAmount) + Number(inrxAmount)} {" "}
                          {/* 1 INRx ={" "}
                          {stakeToken
                            ? cutAfterDecimal(stakeToken?.inrx_price, 3)
                            : "90"}{" "} */}
                          INRx
                        </td>
                      </tr>
                      <tr>
                        <th
                          role="cell"
                          scope="row"
                          className="fw-normal text-muted"
                        >
                          Reference APR
                        </th>
                        <td className=" ">{aprSet}%</td>
                      </tr>
                      <tr>
                        <th
                          role="cell"
                          scope="row"
                          className="fw-normal text-muted"
                        >
                          Monthly Est.Reward
                        </th>
                        <td className=" ">{cutAfterDecimal((((((Number(stakeAmount) + Number(inrxAmount)) * aprSet)/100))/12),4)} INRx</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="d-grid mb-3">
                  <button className="custom_btn" type="button" onClick={stake}>
                    {loading ? (
                      <div
                        className="spinner-border text-black load-icon mx-1"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : null}
                    stake
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Stake_inr;
