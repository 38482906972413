import React, { useState } from "react";
import Header from "../Component/Header";
import Sidebar from "../Component/Sidebar";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
// import "jspdf-autotable";

import { ImInfo } from "react-icons/im";
import { TbAlertOctagonFilled } from "react-icons/tb";
import { RiAlertFill } from "react-icons/ri";

const StakingAgreement = () => {
  const [formData, setFormData] = useState({
    yourName: "",
    amountInINRx: "",
    bonusInINRx: "",
    totalCollateralInINRx: "",
    monthlyReturnInINRx: "",
    // referralRewardInINRx: "",
    // principalAmountInINRx: "",
    // aprClaimedPerMonthInINRx: "",
    // aprReceivedInINRx: "",
    // principalAmountAfter12MonthsInINRx: "",
  });
  const [errors, setErrors] = useState({});
  const [preview, setPreview] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    validateField(name, value);
  };

  const validateField = (name, value) => {
    const newErrors = { ...errors };

    // Check if the field is empty
    if (value.trim() === "") {
      newErrors[name] = "This field is required";
    } else {
      delete newErrors[name];
    }

    setErrors(newErrors);
  };

  const validateForm = () => {
    const newErrors = {};
    // Validate all fields
    for (const key in formData) {
      if (formData[key].trim() === "") {
        newErrors[key] = "This field is required";
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setPreview(true);
    }
  };

  const handleReset = () => {
    setFormData({
      yourName: "",
      amountInINRx: "",
      bonusInINRx: "",
      totalCollateralInINRx: "",
      monthlyReturnInINRx: "",
      // referralRewardInINRx: "",
      //   principalAmountInINRx: "",
      //   aprClaimedPerMonthInINRx: "",
      //   aprReceivedInINRx: "",
      //   principalAmountAfter12MonthsInINRx: "",
    });
    setErrors({});
    setPreview(false);
  };

  const generatePDF = () => {
    const input = document.querySelector("#preview");
  
    // Create a new jsPDF instance
    const pdf = new jsPDF("p", "mm", "a4");
  
    // Convert HTML to canvas
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 295; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
  
      // Add the first page
      let position = 0;
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
  
      let pageNum = 2;
      while (heightLeft > 0) {
        pdf.addPage();
        addFooter(pdf, pageNum); // Add footer for each new page
        position = heightLeft - imgHeight; // Adjust position
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        pageNum++;
      }
  
      // Save the PDF
      pdf.save("staking-agreement.pdf");
    });
  };
  
  // Function to add footer to each page
  const addFooter = (pdf, pageNum) => {
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const footerText = `Page ${pageNum}`;
  
    // Add footer with enough space at the bottom
    pdf.setFontSize(10); // Adjust font size for footer
    pdf.text(footerText, pageWidth / 2, pageHeight - 10, { align: "center" }); // Adjust footer position
  };
  
  
  

  return (
    <>
      <Header />
      <Sidebar />
      <main className="mt-5 pt-5">
        <div className="container-fluid">
          <h4 className="heading">Staking Agreement</h4>
          <div className="">
          
                <div className="container">
                  {!preview ? (
                      <div className="card pt-5">
              <div className="card-body agreement_box">
                    
                      <form onSubmit={handleSubmit}>
                        <h1 className="h4 fw-bold mb-4">
                          Staking Agreement Between [Your Name] and INRx
                          Consensus LLP
                        </h1>
                        <div class="agreement-title">
                          Staking Agreement Between
                          <input
                            type="text"
                            name="yourName"
                            placeholder="Your Name"
                            value={formData.yourName}
                            onChange={handleChange}
                          />
                          {errors.yourName && (
                            <span className="text-danger px-2">
                              {" "}
                              <RiAlertFill />
                            </span>
                          )}
                          and INRx Consensus LLP
                        </div>
                        <p>
                          This Staking Agreement ("Agreement") is entered into
                          on September 9, 2024 by and between
                          <input
                            type="text"
                            name="yourName"
                            placeholder="Your Name"
                            value={formData.yourName}
                            onChange={handleChange}
                          />
                          {errors.yourName && (
                            <span className="text-danger px-2">
                              {" "}
                              <RiAlertFill />
                            </span>
                          )}
                          ("Staker"), and INRx Consensus LLP ("Company"),
                          collectively referred to as the "Parties."
                        </p>
                        <div class="agreement-section">
                          <h3 className="heading">1. Staking Terms</h3>
                          <p>
                            <strong> 1.1. Staking Amount:</strong> The Staker
                            agrees to stake
                            <input
                              type="text"
                              name="amountInINRx"
                              value={formData.amountInINRx}
                              onChange={handleChange}
                              placeholder="Amount"
                            />
                            {errors.amountInINRx && (
                              <span className="text-danger px-2">
                                {" "}
                                <RiAlertFill />
                              </span>
                            )}
                            INRx with the Company.
                          </p>
                          <p>
                            <strong> 1.2. Bonus on Staking:</strong> Upon
                            staking, the Company shall provide a 10% bonus on
                            the Staked Amount. The bonus amounts to
                            <input
                              type="text"
                              name="bonusInINRx"
                              placeholder="Bonus"
                              value={formData.bonusInINRx}
                              onChange={handleChange}
                            />
                            {errors.bonusInINRx && (
                              <span className="text-danger">
                                {" "}
                                <RiAlertFill />
                              </span>
                            )}
                            INRx. The total staking collateral shall be
                            <input
                              type="text"
                              name="totalCollateralInINRx"
                              onChange={handleChange}
                              value={formData.totalCollateralInINRx}
                              placeholder="Total Collateral"
                            />
                            INRx.
                          </p>

                          <strong>1.3. Annual Percentage Return (APR):</strong>
                          <ul>
                            <li>
                              The Staker will receive a 48% annual return on the
                              Total Collateral, which equates to a 4% monthly
                              return on the Total Collateral.
                            </li>
                            <li>
                              Monthly Return: 4% of
                              <input
                                type="text"
                                placeholder="Total Collateral"
                                value={formData.totalCollateralInINRx}
                                name="totalCollateralInINRx"
                                onChange={handleChange}
                              />
                              INRx =
                              <input
                                type="text"
                                placeholder="Monthly Return"
                                name="monthlyReturnInINRx"
                                value={formData.monthlyReturnInINRx}
                                onChange={handleChange}
                              />
                              {errors.monthlyReturnInINRx && (
                                <span className="text-danger px-2">
                                  {" "}
                                  <RiAlertFill />
                                </span>
                              )}
                              INRx per month.
                            </li>
                          </ul>
                        </div>
                        <div class="agreement-section">
                          <strong>1.4. Referral Reward:ss</strong>
                          <ul>
                            <li>
                              The Invitee (referrer) shall receive a 12% annual
                              return on the Staker's Total Collateral,
                              equivalent to 1% monthly.
                            </li>

                            <li>
                              <strong>Monthly Referral Reward:</strong> 1% of{" "}
                              <input type="text" placeholder="" />
                              INRx = <input type="text" placeholder="" /> INRx
                              per month.
                            </li>
                            <li>
                              <strong>Cancellation of Referral Reward:</strong>{" "}
                              The Invitee’s APR will be canceled if the Staker
                              terminates the collateral staking early, in
                              accordance with the conditions outlined in section
                              2.
                            </li>
                          </ul>
                        </div>
                        <div class="agreement-section">
                          <h3 className="heading">
                            2. Staking Duration and Early Termination
                          </h3>
                          <p>
                            <strong>
                              {" "}
                              2.1. Early Termination Before 1 Month:
                            </strong>
                            <br />
                            <ul>
                              <li>
                                <li>
                                  If the Staker terminates the staking before 1
                                  month, the Staker will receive only 85% of the
                                  principal amount of
                                  <input
                                    type="text"
                                    placeholder="Principal Amount"
                                  />
                                  INRx, which is
                                  <input
                                    type="text"
                                    placeholder="85% of Principal"
                                  />
                                  INRx.
                                  <br />
                                </li>
                                <li>
                                  The provided bonus of
                                  <input type="text" placeholder="Bonus" />
                                  INRx and any APR claimed will be forfeited.
                                  <li>
                                    {" "}
                                    The Invitee’s referral reward is also
                                    canceled.
                                  </li>
                                </li>
                              </li>
                            </ul>
                          </p>
                         
                          <p>
                            <strong>
                              2.2. Early Termination Before 3 Months:
                            </strong>
                            <ul>
                              <li>
                                If the Staker terminates the staking before 3
                                months but after 1 month, the Staker will
                                receive only the principal amount of
                                <input
                                  type="text"
                                  placeholder="Principal Amount"
                                />
                                INRx.
                              </li>

                              <li>
                                The provided bonus of
                                <input type="text" placeholder="Bonus" /> INRx
                                will be deducted, and any APR claimed by the
                                Staker (
                                <input
                                  type="text"
                                  placeholder="APR Claimed per Month"
                                />
                                INRx per month) will also be deducted from the
                                principal.
                              </li>
                              <li>
                                {" "}
                                The Invitee’s referral reward is also canceled.
                              </li>
                             
                            </ul>
                          </p>
                          <br/><br/>
                          <p>
                            <strong>
                              {" "}
                              2.3. Termination Between 3 to 6 Months:
                            </strong>
                            <ul>
                              <li>
                                If the Staker terminates the staking between 3
                                to 6 months, the Staker will receive the
                                principal amount with 1% APR on the principal (
                                <input
                                  type="text"
                                  placeholder="1% APR on Principal"
                                />
                                INRx).
                              </li>
                              <li>
                                <strong> APR Received: </strong> 1% of
                                <input
                                  type="text"
                                  placeholder="Principal Amount"
                                />
                                INRx =
                                <input type="text" placeholder="APR Received" />
                                INRx.
                              </li>
                              <li>
                                The provided bonus of
                                <input type="text" placeholder="Bonus" /> INRx
                                will be deducted, and 4% APR per month claimed
                                by the Staker will also be deducted from the
                                principal.
                              </li>
                              <li>
                                The Invitee’s referral reward is also canceled.
                              </li>
                            </ul>
                          </p>
                        </div>
                        <div class="agreement-section">
                          <p>
                            {" "}
                            <strong>
                              2.4. Termination Between 6 to 9 Months:
                            </strong>
                          </p>
                          <ul>
                            <li>
                              If the Staker terminates the staking between 6 to
                              9 months, the Staker will receive the principal
                              amount with 2% APR on the principal (100,000
                              INRx).
                            </li>

                            <li>
                              <strong> APR Received: </strong> 2% of{" "}
                              <input type="text" placeholder="" /> INRx ={" "}
                              <input type="text" placeholder="" /> INRx.
                            </li>
                            <li>
                              The provided bonus of 10,000 INRx will be
                              deducted, and 4% APR per month claimed by the
                              Staker will also be deducted from the principal.
                            </li>

                            <li>
                              The Invitee’s referral reward is also canceled.
                            </li>
                          </ul>

                          <div class="agreement-section">
                            <p>
                              {" "}
                              <strong>
                                2.5. Termination Between 9 to 12 Months:
                              </strong>
                            </p>
                            <ul>
                              <li>
                                If the Staker terminates the staking between 9
                                to 12 months, the Staker will receive the
                                principal amount with 2.5% APR on the principal
                                (100,000 INRx).
                              </li>
                              <li>
                                <strong> APR Received:</strong> 2.5% of{" "}
                                <input type="text" placeholder="" /> INRx ={" "}
                                <input type="text" placeholder="" />
                                INRx.
                              </li>
                              <li>
                                The provided bonus of 10,000 INRx will be
                                deducted, and 4% APR per month claimed by the
                                Staker will also be deducted from the principal.
                              </li>
                              <li>
                                The Invitee’s referral reward is also canceled.
                              </li>
                            </ul>
                          </div>

                          <div class="agreement-section">
                            <p>
                              <strong>2.6. Completion of 12 Months:</strong>
                            </p>

                            <ul>
                              <li>
                                Upon completion of 12 months, the Staker will
                                receive the principal amount of{" "}
                                <input type="text" placeholder="" /> INRx with a
                                48% APR (4% per month) on the Total Collateral ({" "}
                                <input type="text" placeholder="" />
                                ), including the bonus.
                              </li>
                              <li>
                                {" "}
                                <strong> Total Annual APR Received:</strong> 48%
                                of <input type="text" placeholder="" /> INRx ={" "}
                                <input type="text" placeholder="" /> INRx.
                              </li>

                              <li>
                                The Staker will also retain the bonus of 10,000
                                INRx.
                              </li>
                              <li>
                                The Invitee will receive the full 12% annual
                                referral reward.
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="agreement-section">
                          <h4 className="heading">
                            3. Withdrawal and Deductions
                          </h4>
                          <p>
                            <strong>3.1. Withdrawal Procedure:</strong> The
                            Staker may request a withdrawal of the Staked Amount
                            and accrued APR at any time, subject to the
                            conditions set forth in this Agreement.
                          </p>
                          <p>
                            <strong>
                              3.2. Deductions on Early Termination:
                            </strong>{" "}
                            In the event of early termination, deductions will
                            be made from the principal as per the terms
                            mentioned in section 2.
                          </p>
                        </div>
                        <div class="agreement-section">
                          <h4 className="heading">4. Referral System</h4>
                          <p>
                            <strong>4.1. Referral Rewards:</strong> The Invitee
                            who referred the Staker shall receive 12% of the
                            annual return on the Staker's Total Collateral, paid
                            at 1% monthly. If the Staker terminates the staking
                            early, the Invitee's referral reward will be
                            canceled as per the conditions outlined in section
                            2.
                          </p>
                        </div>
                        <div class="agreement-section">
                          <h2 className="heading">5. Miscellaneous</h2>
                          <p>
                            <strong>5.1. Governing Law:</strong> This Agreement
                            shall be governed by and construed in accordance
                            with the laws of the jurisdiction in which INRx
                            Consensus LLP is registered.
                          </p>
                          <p>
                            <strong>5.2. Entire Agreement:</strong> This
                            Agreement constitutes the entire understanding
                            between the Parties and supersedes all prior
                            agreements, representations, or understandings.
                          </p>
                        </div>
                        <div class="agreement-section">
                          <h2 className="heading">6. Acceptance</h2>
                          <p>
                            By staking the funds, the Staker acknowledges that
                            they have read, understood, and agreed to the terms
                            and conditions set forth in this Agreement.
                          </p>
                        </div>
                        <div class="d-flex justify-content-between">
                          <div>
                            <div className="fw-bold">INRx Consensus LLP</div>
                            <div>INRx Governance</div>
                            <div className="text-muted my-4"></div>
                            <div className="fw-bold">
                              Date: September 9, 2024
                            </div>
                          </div>
                          <div>
                            <div className="fw-bold mt-3">Staker</div>
                            <div className="text-muted my-4"></div>
                            <div className="fw-bold">
                              Date: September 9, 2024
                            </div>
                          </div>
                        </div>

                        <div className="my-5 text-center">
                          {/* <div className="text-danger">
                            {Object.values(errors).map((error, index) => (
                              <p key={index}>{error}</p>
                            ))}
                          </div> */}

                          <button
                            type="button"
                            className="custom_btn_outline me-2"
                            onClick={handleReset}
                          >
                            Reset
                          </button>
                          <button type="submit" className="custom_btn">
                            Submit
                          </button>
                        </div>
                      </form>
                      </div></div>
                    
                  ) : (
                    <div className="row justify-content-center my-5 ">
                      <div id="preview" className="shadow">
                        {/* <div className="watermark">Watermark</div> */}
                        <div className="text-center mb-4">
                            <img src="/images/logo.svg" alt="" />
                        </div>
                        <h1 className="h4 fw-bold mb-4">
                          Staking Agreement Between{" "}
                          <span className="text-green">{formData.yourName} </span> and <span className="text-green">INRx
                          Consensus LLP</span>
                        </h1>
                        <p>
                          This Staking Agreement ("Agreement") is entered into
                          on September 9, 2024 by and between{" "}
                          <strong>{formData.yourName} </strong>
                          ("Staker"), and INRx Consensus LLP ("Company"),
                          collectively referred to as the "Parties."
                        </p>

                        <div class="agreement-section">
                          <h3 className="heading">1. Staking Terms</h3>
                          <p>
                            <strong> 1.1. Staking Amount:</strong> The Staker
                            agrees to stake
                            <strong>{formData.amountInINRx} </strong>
                            INRx with the Company.
                          </p>
                          <p>
                            <strong> 1.2. Bonus on Staking:</strong> Upon
                            staking, the Company shall provide a 10% bonus on
                            the Staked Amount. The bonus amounts to
                            <strong>{formData.bonusInINRx} </strong>
                            INRx. The total staking collateral shall be{" "}
                            <strong>{formData.totalCollateralInINRx} </strong>
                            INRx.
                          </p>
                          <p>
                            <strong>
                              1.3. Annual Percentage Return (APR):
                            </strong>
                            The Staker will receive a 48% annual return on the
                            Total Collateral, which equates to a 4% monthly
                            return on the Total Collateral.
                            <br />
                            Monthly Return: 4% of
                            <strong>{formData.totalCollateralInINRx} </strong>
                            INRx ={" "}
                            <strong>{formData.monthlyReturnInINRx} </strong>
                            INRx per month.
                          </p>
                        </div>
                        <div class="agreement-section">
                          <ul>
                            <li>
                              <strong>1.4. Referral Reward:</strong> The Invitee
                              (referrer) shall receive a 12% annual return on
                              the Staker's Total Collateral, equivalent to 1%
                              monthly.
                            </li>
                            <li>
                              Monthly Referral Reward: 1% of{" "}
                              <strong>{formData.totalCollateralInINRx} </strong>
                              INRx ={" "}
                              <strong>{formData.referralRewardInINRx} </strong>
                              INRx per month.
                            </li>

                            <li>
                              Cancellation of Referral Reward: The Invitee’s APR
                              will be canceled if the Staker terminates the
                              collateral staking early, in accordance with the
                              conditions outlined in section 2.
                            </li>

                            <li>
                              <strong>Monthly Referral Reward:</strong> 1% of{" "}
                              referralRewardInINRx{" "}
                              <strong>{formData.referralRewardInINRx} </strong>
                              INRx ={" "}
                              <strong>
                                {formData.referralRewardInINRx}{" "}
                              </strong>{" "}
                              INRx per month.
                            </li>
                            <li>
                              <strong>Cancellation of Referral Reward:</strong>{" "}
                              The Invitee’s APR will be canceled if the Staker
                              terminates the collateral staking early, in
                              accordance with the conditions outlined in section
                              2.
                            </li>
                          </ul>
                        </div>
                        <div class="agreement-section">
                          <h3 className="heading">
                            2. Staking Duration and Early Termination
                          </h3>
                          <p>
                            <strong>
                              {" "}
                              2.1. Early Termination Before 1 Month:
                            </strong>
                            <br />
                            <ul>
                              <li>
                                <li>
                                  If the Staker terminates the staking before 1
                                  month, the Staker will receive only 85% of the
                                  principal amount of
                                  <strong>
                                    {formData.referralRewardInINRx}{" "}
                                  </strong>
                                  INRx, which is
                                  <strong>
                                    {formData.referralRewardInINRx}{" "}
                                  </strong>
                                  INRx.
                                  <br />
                                </li>
                                <li>
                                  The provided bonus of
                                  <strong>
                                    {formData.referralRewardInINRx}{" "}
                                  </strong>
                                  INRx and any APR claimed will be forfeited.
                                  <li>
                                    {" "}
                                    The Invitee’s referral reward is also
                                    canceled.
                                  </li>
                                </li>
                              </li>
                            </ul>
                          </p>
                          <p>
                            <strong>
                              {" "}
                              2.2. Early Termination Before 3 Months:
                            </strong>
                            <ul>
                              <li>
                                If the Staker terminates the staking before 3
                                months but after 1 month, the Staker will
                                receive only the principal amount of
                                <strong>
                                  {formData.referralRewardInINRx}{" "}
                                </strong>
                                INRx.
                              </li>

                              <li>
                                The provided bonus of
                                <strong>
                                  {formData.referralRewardInINRx}{" "}
                                </strong>
                                INRx will be deducted, and any APR claimed by
                                the Staker (
                                <strong>
                                  {formData.referralRewardInINRx}{" "}
                                </strong>
                                INRx per month) will also be deducted from the
                                principal.
                              </li>
                              <li>
                                {" "}
                                The Invitee’s referral reward is also canceled.ddd
                              </li>
                              <br/>
                              <br/>
                              <br/>
                            </ul>
                          </p>
                          <p>
                            <strong>
                              {" "}
                              2.3. Termination Between 3 to 6 Months:
                            </strong>
                            <ul>
                              <li>
                                If the Staker terminates the staking between 3
                                to 6 months, the Staker will receive the
                                principal amount with 1% APR on the principal (
                                <strong>
                                  {formData.referralRewardInINRx}{" "}
                                </strong>
                                INRx).
                              </li>
                              <li>
                                <strong> APR Received: </strong> 1% of
                                <strong>
                                  {formData.referralRewardInINRx}{" "}
                                </strong>
                                INRx =
                                <strong>
                                  {formData.referralRewardInINRx}{" "}
                                </strong>
                                INRx.
                              </li>
                              <li>
                                The provided bonus of
                                <strong>
                                  {formData.referralRewardInINRx}{" "}
                                </strong>
                                INRx will be deducted, and 4% APR per month
                                claimed by the Staker will also be deducted from
                                the principal.
                              </li>
                              <li>
                                The Invitee’s referral reward is also canceled.
                              </li>
                            </ul>
                          </p>
                        </div>

                        <div class="agreement-section">
                          <p>
                            {" "}
                            <strong>
                              2.4. Termination Between 6 to 9 Months:
                            </strong>
                          </p>
                          <ul>
                            <li>
                              If the Staker terminates the staking between 6 to
                              9 months, the Staker will receive the principal
                              amount with 2% APR on the principal (100,000
                              INRx).
                            </li>

                            <li>
                              <strong> APR Received: </strong> 2% of{" "}
                              <strong>{formData.referralRewardInINRx} </strong>{" "}
                              INRx ={" "}
                              <strong>{formData.referralRewardInINRx} </strong>{" "}
                              INRx.
                            </li>
                            <li>
                              The provided bonus of 10,000 INRx will be
                              deducted, and 4% APR per month claimed by the
                              Staker will also be deducted from the principal.
                            </li>

                            <li>
                              The Invitee’s referral reward is also canceled.
                            </li>
                          </ul>

                          <div class="agreement-section">
                            <p>
                              {" "}
                              <strong>
                                2.5. Termination Between 9 to 12 Months:
                              </strong>
                            </p>
                            <ul>
                              <li>
                                If the Staker terminates the staking between 9
                                to 12 months, the Staker will receive the
                                principal amount with 2.5% APR on the principal
                                (100,000 INRx).
                              </li>
                              <li>
                                <strong> APR Received:</strong> 2.5% of{" "}
                                <strong>
                                  {formData.referralRewardInINRx}{" "}
                                </strong>{" "}
                                INRx ={" "}
                                <strong>
                                  {formData.referralRewardInINRx}{" "}
                                </strong>
                                INRx.
                              </li>
                              <li>
                                The provided bonus of 10,000 INRx will be
                                deducted, and 4% APR per month claimed by the
                                Staker will also be deducted from the principal.
                              </li>
                              <li>
                                The Invitee’s referral reward is also canceled.
                              </li>
                            </ul>
                          </div>

                          <div class="agreement-section">
                            <p>
                              <strong>2.6. Completion of 12 Months:</strong>
                            </p>

                            <ul>
                              <li>
                                Upon completion of 12 months, the Staker will
                                receive the principal amount of{" "}
                                <strong>
                                  {formData.referralRewardInINRx}{" "}
                                </strong>
                                INRx with a 48% APR (4% per month) on the Total
                                Collateral ({" "}
                                <strong>
                                  {formData.referralRewardInINRx}{" "}
                                </strong>
                                ), including the bonus.
                              </li>
                              <li>
                                {" "}
                                <strong> Total Annual APR Received:</strong> 48%
                                of
                                <strong>
                                  {formData.referralRewardInINRx}{" "}
                                </strong>{" "}
                                INRx ={" "}
                                <strong>
                                  {formData.referralRewardInINRx}{" "}
                                </strong>{" "}
                                INRx.
                              </li>

                              <li>
                                The Staker will also retain the bonus of 10,000
                                INRx.
                              </li>
                              <li>
                                The Invitee will receive the full 12% annual
                                referral reward.
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="agreement-section">
                          <h4 className="heading">
                            3. Withdrawal and Deductions
                          </h4>
                          <p>
                            <strong>3.1. Withdrawal Procedure:</strong> The
                            Staker may request a withdrawal of the Staked Amount
                            and accrued APR at any time, subject to the
                            conditions set forth in this Agreement.
                          </p>
                          <p>
                            <strong>
                              3.2. Deductions on Early Termination:
                            </strong>{" "}
                            In the event of early termination, deductions will
                            be made from the principal as per the terms
                            mentioned in section 2.
                          </p>
                        </div>
                        <div class="agreement-section">
                          <h4 className="heading">4. Referral System</h4>
                          <p>
                            <strong>4.1. Referral Rewards:</strong> The Invitee
                            who referred the Staker shall receive 12% of the
                            annual return on the Staker's Total Collateral, paid
                            at 1% monthly. If the Staker terminates the staking
                            early, the Invitee's referral reward will be
                            canceled as per the conditions outlined in section
                            2.
                          </p>
                          <br/>
                              <br/>
                              <br/>
                        </div>
                        <div class="agreement-section">
                          <h2 className="heading">5. Miscellaneous</h2>
                          <p>
                            <strong>5.1. Governing Law:</strong> This Agreement
                            shall be governed by and construed in accordance
                            with the laws of the jurisdiction in which INRx
                            Consensus LLP is registered.
                          </p>
                          <p>
                            <strong>5.2. Entire Agreement:</strong> This
                            Agreement constitutes the entire understanding
                            between the Parties and supersedes all prior
                            agreements, representations, or understandings.
                          </p>
                        </div>
                        <div class="agreement-section">
                          <h2 className="heading">6. Acceptance</h2>
                          <p>
                            By staking the funds, the Staker acknowledges that
                            they have read, understood, and agreed to the terms
                            and conditions set forth in this Agreement.
                          </p>
                        </div>
                        <div class="d-flex justify-content-between">
                          <div>
                            <div className="fw-bold">INRx Consensus LLP</div>
                            <div>INRx Governance</div>
                            <div className="text-muted my-4"></div>
                            <div className="fw-bold">
                              Date: September 9, 2024
                            </div>
                          </div>
                          <div>
                            <div className="fw-bold mt-3">Staker</div>
                            <div className="text-muted my-4"></div>
                            <div className="fw-bold">
                              Date: September 9, 2024
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

<div className="row justify-content-center my-5 ">
                    {preview && (
                      <div className="my-5 text-center" style={{width:"220mm"}}>
                        <button onClick={generatePDF} className="custom_btn">
                          Download PDF
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
        
      </main>
    </>
  );
};

export default StakingAgreement;
