import React from 'react';
import ReactApexChart from 'react-apexcharts';

const SparklineChart = ({ data, color, type }) => {
  const options = {
    chart: {
      type: 'line',
      sparkline: {
        enabled: true
      },
      
    
    },
    stroke: {
      curve: 'smooth',
      width: 0
    },
    colors: [color],
    tooltip: {
      enabled: false
    }
  };

  const series = [{
    name: 'Sparkline',
    data: data
  }];

  return (
    <div>
      <ReactApexChart options={options} series={series} type={type} height={100}  />
    </div>
  );
};

export default SparklineChart;
