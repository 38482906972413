import React, { useEffect, useState } from "react";
import Header from "../Component/Header";
import Sidebar from "../Component/Sidebar";
import { HiOutlineChatBubbleLeftRight } from "react-icons/hi2";
import {
  newurl,
  raiseTicket,
  raisedTicketList,
  replyTicket,
} from "../utils/apis";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { formatTime, getAmPm } from "../utils/actionHandler";
import { FaExchangeAlt } from "react-icons/fa";
import { IoRefresh } from "react-icons/io5";
import socket from "../socket/socket";

const Support = () => {
  const { user, sessionId } = useSelector((state) => state.user);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [queryfile, setQueryFile] = useState("");
  const [replymessage, setReplyMessage] = useState("");
  const [replyfile, setReplyFile] = useState("");
  const [list, setlist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  function sendRequest(msg, file, subject_msg) {
    setLoading(true);
    raiseTicket(
      user?.mobile,
      sessionId,
      subject_msg ? subject_msg : subject,
      msg,
      file
    )
      .then((resp) => {
        const res = resp?.data;
        // console.log(res, "res");
        setLoading(false);
        if (res?.status == 200) {
          // getList();
          socket.emit("message", user?.mobile);
          setQueryFile("");
          setReplyFile("");
          setMessage("");
          setReplyMessage("");
          toast.success(res?.message);
        } else {
          toast.error(res?.message);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log(e, "Error in sendREquest()");
      });
  }

  // function sendReply(load, ticketId, subject) {
  //   setRelpyLoad(load);

  //   replyTicket(
  //     ticketId,
  //     sessionId,
  //     user.mobile,
  //     replymessage,
  //     subject,
  //     replyfile
  //   )
  //     .then((resp) => {
  //       const res = resp?.data;
  //       console.log(res, "res");
  //       setRelpyLoad(0);
  //       if (res?.status == 200) {
  //         getList();
  //         toast.success(res?.message);
  //       } else {
  //         toast.error(res?.message);
  //       }
  //     })
  //     .catch((e) => {
  //       setRelpyLoad(0);
  //       console.log(e, "Error in sendREquest()");
  //     });
  // }

  function getList() {
    try {
      setRefreshing(true);
      raisedTicketList(user?.mobile, sessionId)
        .then((res) => {
          setRefreshing(false);
          if (res?.status == 200) {
            setlist(res?.data);
          }
        })
        .catch((e) => {
          setRefreshing(false);
          console.log(e, "Error in getList raisedTicketList()/support page");
        });
    } catch (e) {
      setRefreshing(false);
      console.log(e, "Error in getList");
    }
  }

  useEffect(() => {
    getList();
  }, [user]);

  useEffect(() => {
    // console.log("is socket connected", socket.connected);
    if (socket.connected) {
      socket.on("update-chat", (msg) => {
        // console.log('chat update',msg)
        if (msg === user?.mobile) {
          getList();
        }
      });
    }
    return () => {
      socket.off();
    };
  }, [socket.connected, user]);

  return (
    <>
      <Header />
      <Sidebar />
      <main className="mt-5 pt-5 support_main">
        <div className="container-fluid">
          <div className="mb-3 d-flex justify-content-between">
            <h1 className="heading">Support</h1>

            <a
              className="text-white text-nowrap"
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#ticket"
            >
              <img src="images/ticket.svg" alt="ticket" />
              <div className="text-overlay">
                <i className="fas fa-plus" /> New Ticket
              </div>
            </a>
          </div>
          {/* accordian start */}
          {list?.length > 0
            ? list.map((item, i) => {
                return (
                  <div className="" key={i + "support-query-list"}>
                    <div
                      className="accordion accordion-flush"
                      id={"accordionFlushExample" + i}
                    >
                      <div className="accordion-item mb-2 rounded">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed "
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#flush-collapseOne" + i}
                            aria-expanded="false"
                            aria-controls={"flush-collapseOne" + i}
                          >
                            <div className="card-body p-0 overflow-x-auto">
                              <div className="d-flex gap-4">
                                <div className="col mob-font ">
                                  <div className="d-flex align-items-center gap-3">
                                    <div className="text-success">
                                      <img
                                        src="images/message.svg"
                                        alt="message"
                                      />
                                    </div>
                                    <div className="">
                                      <div className="text-muted fst-normal fs-8 mb-1 text-nowrap text-nowrap">
                                        ID Ticket
                                      </div>
                                      <div className="text-nowrap">
                                        #{item?.ticketId}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col mob-font ">
                                  <div className="text-muted fst-normal fs-8 mb-1 text-nowrap">
                                    Subject of the appeal
                                  </div>
                                  <div className="text-nowrap">
                                    {item?.query_subject}
                                  </div>
                                </div>
                                <div className="col mob-font ">
                                  <div className="mb-1 text-muted fst-normal fs-8 text-nowrap">
                                    Last Update
                                  </div>
                                  <div className="text-nowrap">
                                    {new Date(
                                      item?.querytime * 1000
                                    ).toLocaleString()}
                                  </div>
                                </div>

                                <div className="col mob-font ">
                                  <div className="text-muted fst-normal fs-8 mb-1 text-nowrap">
                                    Status of the request
                                  </div>
                                  <div className="text-nowrap">
                                    <i className="fa-regular fa-message text-success"></i>{" "}
                                    Response from support . 3 {item?.status}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </button>
                        </h2>
                        <div
                          id={"flush-collapseOne" + i}
                          className="accordion-collapse collapse"
                          data-bs-parent={"#accordionFlushExample" + i}
                        >
                          <div className="accordion-body">
                            {/* {item?.query_meggage} */}
                            <div
                              style={{
                                background: "#0d0d0d",
                                height: "500px",
                                overflow: "scroll",
                                overflowX: "hidden",
                              }}
                              className=" px-3 chat_custom-scrollbar"
                            >
                              <div className="row">
                                <div className="col-lg-12 col-sm-12 mb-2">
                                  <div>
                                    <h6 className=" text-secondary py-2">
                                      Your Chats
                                    </h6>

                                    {item?.messages?.map((it, i) => {
                                      if (it.type == "user1") {
                                        return (
                                          <div
                                            className="col-lg-12 col-sm-12 mb-2"
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: "flex-start",
                                            }}
                                          >
                                            <div className="col-lg-6 col-sm-12 mb-2">
                                              <div
                                                style={{
                                                  flex: 1,
                                                  border: "1px solid #222",
                                                  borderRadius: 10,
                                                  padding: 10,
                                                }}
                                                className=" my-2"
                                              >
                                                <div className="d-flex gap-2 align-items-center">
                                                  <div className="">
                                                    <img
                                                      src={
                                                        item?.user_picture?.indexOf(
                                                          "https"
                                                        ) > -1
                                                          ? item?.user_picture
                                                          : item?.user_picture
                                                          ? `data:image/jpeg;base64,${item?.user_picture}`
                                                          : `images/user.svg`
                                                      }
                                                      alt="user"
                                                      style={{
                                                        height: "45px",
                                                        width: "45px",
                                                        borderRadius: "50%",
                                                        objectFit: "cover",
                                                      }}
                                                    />
                                                  </div>
                                                  <div style={{ flex: 1 }}>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                      <h6
                                                        className=" fw-bold"
                                                        style={{
                                                          color: "#02FF01",
                                                        }}
                                                      >
                                                        {item?.userName}
                                                      </h6>
                                                      <div className="text-muted mob-font ">
                                                        {/* {new Date(
                                                item?.querytime * 1000
                                              ).toLocaleTimeString()} */}
                                                      </div>
                                                    </div>
                                                    <p className="mb-0 mob-font text-muted">
                                                      {/* {item?.query_meggage} */}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div
                                                  style={{
                                                    flex: 1,
                                                    flexDirection: "column",
                                                  }}
                                                >
                                                  <div
                                                    className=" text-secondary py-2"
                                                    style={{ flex: 0.7 }}
                                                  >
                                                    {it.message}
                                                  </div>
                                                </div>

                                                {it?.file ? (
                                                  <img
                                                    src={(
                                                      newurl +
                                                      "/" +
                                                      it?.file
                                                    ).replace(
                                                      "/uploads",
                                                      "/support"
                                                    )}
                                                    className="chat-image"
                                                    alt="Query Image"
                                                  />
                                                ) : null}
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "flex-end",
                                                  }}
                                                >
                                                  <span className=" text-secondary">
                                                    {new Date(
                                                      it.createdAt
                                                    ).toLocaleDateString() +
                                                      " / " +
                                                      formatTime(
                                                        new Date(
                                                          it.createdAt
                                                        ).getHours()
                                                      ) +
                                                      ":" +
                                                      formatTime(
                                                        new Date(
                                                          it.createdAt
                                                        ).getSeconds()
                                                      ) +
                                                      " " +
                                                      getAmPm(
                                                        new Date(
                                                          it.createdAt
                                                        ).getTime()
                                                      )}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      } else {
                                        return (
                                          <div
                                            className="col-lg-12 col-sm-12 mb-2"
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: "flex-end",
                                            }}
                                          >
                                            <div
                                              className="col-lg-6 col-sm-12 mb-2"
                                              style={{
                                                flexDirection: "row",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  flex: 1,
                                                  border: "1px solid #222",
                                                  borderRadius: 10,
                                                  padding: 10,
                                                  alignSelf: "flex-end",
                                                }}
                                              >
                                                <div className="d-flex gap-2 align-items-center">
                                                  <div className="">
                                                    <img
                                                      alt="user"
                                                      src={
                                                        item?.replier_picture?.indexOf(
                                                          "https"
                                                        ) > -1
                                                          ? item?.replier_picture
                                                          : item?.replier_picture
                                                          ? `data:image/jpeg;base64,${item?.replier_picture}`
                                                          : `images/user.svg`
                                                      }
                                                      style={{
                                                        height: "45px",
                                                        width: "45px",
                                                        borderRadius: "50%",
                                                        objectFit: "cover",
                                                      }}
                                                    />
                                                  </div>
                                                  <div style={{ flex: 1 }}>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                      <h6
                                                        className=" fw-bold"
                                                        style={{
                                                          color: "#02FF01",
                                                        }}
                                                      >
                                                        {item?.replierName
                                                          ? item?.replierName
                                                          : "Boface"}
                                                      </h6>
                                                      <div className="text-muted mob-font "></div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  style={{
                                                    flex: 1,
                                                    flexDirection: "column",
                                                  }}
                                                >
                                                  <div
                                                    className=" text-secondary"
                                                    style={{ flex: 0.7 }}
                                                  >
                                                    {it.message}
                                                  </div>
                                                </div>

                                                {it?.file ? (
                                                  <img
                                                    src={(
                                                      newurl +
                                                      "/" +
                                                      item?.file
                                                    ).replace(
                                                      "/uploads",
                                                      "/support"
                                                    )}
                                                    style={{
                                                      height: "105px",
                                                      width: "105px",
                                                      // borderRadius: "50%",
                                                      objectFit: "cover",
                                                    }}
                                                    alt="Query Image"
                                                  />
                                                ) : null}
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "flex-end",
                                                  }}
                                                >
                                                  <span className=" text-secondary">
                                                    {formatTime(
                                                      new Date(
                                                        it.createdAt
                                                      ).getHours()
                                                    ) +
                                                      ":" +
                                                      formatTime(
                                                        new Date(
                                                          it.createdAt
                                                        ).getSeconds()
                                                      ) +
                                                      " " +
                                                      getAmPm(
                                                        new Date(
                                                          it.createdAt
                                                        ).getTime()
                                                      )}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 col-sm-12 mb-2 bg-secondary px-2">
                            {item?.status == "pending" ? (
                              <div className="d-flex justify-content-between align-items-center pt-2">
                                <div
                                  className="support_input"
                                  style={{ flex: 1 }}
                                >
                                  <input
                                    type="text"
                                    placeholder="Write your message..."
                                    className="form-control p-1  rounded-pill"
                                    id="exampleInputPassword1"
                                    style={{ width: "50%" }}
                                    value={replymessage}
                                    onChange={(e) => {
                                      let msg = e.target.value;
                                      if (e?.target?.value?.length > 500) {
                                        msg = msg.slice(0, 500);
                                      }
                                      setReplyMessage(msg);
                                    }}
                                  />
                                  <div className=" text-black px-2">
                                    {500 - replymessage?.length}/500
                                  </div>
                                </div>
                                <div
                                  className="mb-3"
                                  style={{
                                    justifyContent: "flex-end",
                                    display: "flex",
                                  }}
                                >
                                  <div className="d-flex gap-4 align-items-center ">
                                    <div
                                      className=""
                                      onClick={() =>
                                        document
                                          .getElementById("replyfile")
                                          .click()
                                      }
                                    >
                                      <i className="fa-solid fa-paperclip fs-4" />
                                    </div>
                                    <input
                                      type="file"
                                      className=" d-none "
                                      id="replyfile"
                                      onChange={(e) => {
                                        // console.log(e.target.files[0],' file name ')
                                        setReplyFile(e?.target?.files[0]);
                                      }}
                                    />
                                    <div
                                      className="coin_style3"
                                      onClick={() =>
                                        sendRequest(
                                          replymessage,
                                          replyfile,
                                          item?.query_subject
                                        )
                                      }
                                    >
                                      {loading ? (
                                        <div
                                          className="spinner-border text-black load-icon mx-1"
                                          role="status"
                                        ></div>
                                      ) : (
                                        <i className="fa-solid fa-arrow-up "></i>
                                      )}
                                    </div>
                                    {/* <IoRefresh
                                      size={22}
                                      onClick={() => {
                                        if (!refreshing) {
                                          getList();
                                        }
                                      }}
                                      className={
                                        refreshing ? "chat-refresh-round" : ""
                                      }
                                      style={{ cursor: "pointer" }}
                                    /> */}
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {replyfile ? (
                              <div
                                style={{
                                  border: "7px solid #666",
                                  width: "210px",
                                  justifyContent: "center",
                                  display: "flex",
                                  borderRadius: 25,
                                }}
                              >
                                <img
                                  src={URL.createObjectURL(replyfile)}
                                  style={{
                                    height: "200px",
                                    width: "200px",
                                    backgroundSize: "contain",
                                    borderRadius: 20,
                                  }}
                                />
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}

          {/* accordian end */}
        </div>
      </main>

      <div
        class="modal fade"
        id="ticket"
        tabindex="-1"
        aria-labelledby="ticketLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="ticketLabel">
                New Ticket
              </h1>
              <button
                type="button"
                class="bg-transparent fs-4 border-0 text-color"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body">
              <div>
                <div className="mb-3">
                  <label
                    htmlFor="exampleInputEmail8"
                    className="form-label mob-font"
                  >
                    Subject
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="subject"
                    aria-describedby="subject"
                    placeholder="Enter subject"
                    value={subject}
                    onChange={(e) => {
                      let msg = e.target.value;
                      if (e?.target?.value?.length > 30) {
                        msg = msg.slice(0, 30);
                      }
                      setSubject(msg);
                    }}
                  />
                  <div
                    className={`${
                      subject?.length < 30 ? "text-secondary" : "text-danger"
                    }`}
                  >
                    {30 - subject?.length}/30
                  </div>
                </div>

                <div className="mb-3">
                  <label className="text-color mob-font">Message</label>
                  <textarea
                    type="text"
                    aria-multiline
                    className="form-control"
                    rows={5}
                    placeholder="Enter your message"
                    value={message}
                    onChange={(e) => {
                      let msg = e.target.value;
                      if (e?.target?.value?.length > 500) {
                        msg = msg.slice(0, 500);
                      }
                      setMessage(msg);
                    }}
                  ></textarea>
                  <div
                    className={`${
                      message?.length < 500 ? "text-secondary" : "text-danger"
                    }`}
                  >
                    {500 - message?.length}/500
                  </div>
                </div>

                <div
                  className="mb-3"
                  style={{ justifyContent: "flex-end", display: "flex" }}
                >
                  <div className="d-flex gap-4 align-items-center ">
                    <div
                      className=""
                      onClick={() =>
                        document.getElementById("queryfile").click()
                      }
                    >
                      <i className="fa-solid fa-paperclip fs-4" />
                    </div>
                    <input
                      type="file"
                      className=" d-none "
                      id="queryfile"
                      onChange={(e) => {
                        // console.log(e.target.files[0],' file name ')
                        setQueryFile(e?.target?.files[0]);
                      }}
                    />
                    <div
                      className="coin_style3"
                      onClick={() => sendRequest(message, queryfile)}
                    >
                      {loading ? (
                        <div
                          className="spinner-border text-black load-icon mx-1"
                          role="status"
                        ></div>
                      ) : null}
                      <i className="fa-solid fa-arrow-up "></i>
                    </div>
                  </div>
                  {/* <button className="custom_btn" type="button">
                    Send Request
                  </button> */}
                </div>
                {queryfile ? (
                  <div
                    style={{
                      border: "7px solid #666",
                      width: "210px",
                      justifyContent: "center",
                      display: "flex",
                      borderRadius: 25,
                    }}
                  >
                    <img
                      src={URL.createObjectURL(queryfile)}
                      style={{
                        height: "200px",
                        width: "200px",
                        backgroundSize: "contain",
                        borderRadius: 20,
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
