import React from "react";
import Sidebar from "../Component/Sidebar";
import Header from "../Component/Header";
import Change_email from "./Change_email";
import Sms_setting from "./Sms_setting";
import Change_password from "./Change_password";
import Authentication from "./Authentication";
import Phinisingcode from "./Phinisingcode";
import Fund_Password from "./Fund_Password";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Setting = () => {
  const { user, sessionId, userdata } = useSelector((state) => state.user);
  console.log(userdata,"userdata",user)
  return (
    <>
      <Header />

      <Sidebar />
      <main className="mt-5 pt-5 setting_main">
        <div>
          <h1 className="heading">Settings</h1>
          <div className="card h-100">
            <div className="card-body">
              <div className="">
                <div className="setting_header2  pt-2 pb-2">
                  <div>
                    <div className="icon_bg">
                      <i className="bi bi-person-circle"></i>
                    </div>
                  </div>
                  <div className="">
                    <div className="text_style">Profile Picture</div>
                    <div className="text-muted  mob-font fs-8 mb-1">
                      Profile Picture is directly access from trust id, Trust Id
                      profile Picture is Adhar authenticated.
                    </div>
                  </div>
                </div>
                <hr className="dropdown-divider" />
              </div>
              <div className="">
                <div className="setting_header pt-2 pb-2">
                  <div className="setting_header2">
                    <div>
                      <div className="icon_bg pt-2">
                        <i className="fa-regular fa-envelope"></i>
                      </div>
                    </div>
                    <div className="">
                      <div className="text_style">Email Authentication</div>
                      <div className="text-muted  mob-font fs-8 mb-1">
                        Managed by google For Login, withdraw, password
                        retrieval, security.
                      </div>
                    </div>
                  </div>
                  <div className="action_wrapper">
                    <Change_email />
                  </div>
                </div>
                <hr className="dropdown-divider" />
              </div>
              <div className="">
                <div className="setting_header pt-2 pb-2">
                  <div className="setting_header2 ">
                    <div>
                      <div className="icon_bg">
                        <i className="bi bi-phone"></i>
                      </div>
                    </div>
                    <div className="">
                      <div className="text_style">SMS Authentication</div>
                      <div className="text-muted  mob-font fs-8 mb-1">
                       Verify mobile number for login, password reset, kyc verification, & security settings
                      </div>
                    </div>
                  </div>
                  <div className="action_wrapper">
                    <Sms_setting />
                  </div>
                </div>
                <hr className="dropdown-divider" />
              </div>
              <div className="">
                <div className="setting_header pt-2 pb-2">
                  <div className="setting_header2 ">
                    <div>
                      <div className="icon_bg">
                        <i className="bi bi-person-badge"></i>
                      </div>
                    </div>
                    <div className="">
                      <div className="text_style">Identity Verification</div>
                      <div className="text-muted  mob-font fs-8 mb-1">
                        Complete verification to increase daily withdrawal limit
                      </div>
                    </div>
                  </div>

                  <div className="action_wrapper">
                    {/* <Change_email/> */}
                    {userdata?.ev === true && userdata?.mobilelogin === true ? (
                      userdata?.kyc === true ? (
                        <Link
                        // to="/identity-verfication"
                        className="btn btn-success-green "
                      >
                        KYC SUCCESS
                      </Link>
                      ):(
                        <Link
                        to="/identity-verfication"
                        className="btn button-outline"
                      >
                        Verify Now
                      </Link>
                      )
                     
                    ) : (
                      <button className="btn button-outline" disabled>
                        Verify Now
                      </button>
                    )}
                  </div>

                </div>
                <hr className="dropdown-divider" />
              </div>
              <div className="">
                <div className="setting_header pt-2 pb-2">
                  <div className="setting_header2 ">
                    <div>
                      <div className="icon_bg">
                        <i className="bi bi-person-badge"></i>
                      </div>
                    </div>
                    <div className="">
                      <div className="text_style">Bank & UPI Verification</div>
                      <div className="text-muted  mob-font fs-8 mb-1">
                      Please verify bank account and UPI for deposit & withdrwal INR.
                      </div>
                    </div>
                  </div>
                  <div className="action_wrapper">
                  {userdata?.kyc === true && userdata?.kyc_status == "success" ? (
                      <Link
                        to="/bank-verfication"
                        className="btn button-outline"
                      >
                        Verify Now
                      </Link>
                    ) : (
                      <button className="btn button-outline" disabled>
                        Verify Now
                      </button>
                    )}
                    </div>
                 
                </div>
                <hr className="dropdown-divider" />
              </div>
              <div className="">
                <div className="setting_header pt-2 pb-2">
                  <div className="setting_header2">
                    <div>
                      <div className="icon_bg">
                        <i className="bi bi-lock"></i>
                      </div>
                    </div>
                    <div className="">
                      <div className="text_style">Reset Password</div>
                      <div className="text-muted  mob-font fs-8 mb-1">Change to new password</div>
                    </div>
                  </div>
                  <div className="action_wrapper">
                    <Change_password />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="my-4">
            <h1 className="heading">Advanced Protection</h1>
          </div>
          <div className="card h-100">
            <div className="card-body">
              <div className="">
                <div className="setting_header  pt-2 pb-2">
                  <div className="setting_header2">
                    <div>
                      <div className="icon_bg">
                        <i className="bi bi-google"></i>
                      </div>
                    </div>
                    <div className="">
                      <div className="text_style">
                        Google Two Factor Authentication
                      </div>
                      <div className="text-muted  mob-font fs-8 mb-1">
                        For login, withdrawal, password reset, change of
                        security settings, and API management verification
                      </div>
                    </div>
                  </div>
                  <div className="action_wrapper">
                    <Authentication />
                  </div>
                </div>
                <hr className="dropdown-divider" />
              </div>
              <div className="">
                <div className="setting_header pt-2 pb-2">
                  <div className="setting_header2">
                    <div>
                      <div className="icon_bg ">
                        <i className="bi bi-fullscreen"></i>
                      </div>
                    </div>
                    <div className="">
                      <div className="text_style">Anti-phishing Code</div>
                      <div className="text-muted  mob-font fs-8 mb-1">
                        After you've successfully set up the anti-phishing code,
                        it will appear in all official emails from INRx to
                        prevent phishing attempts.
                      </div>
                    </div>
                  </div>
                  <div className="action_wrapper">
                    <Phinisingcode />
                  </div>
                </div>
                <hr className="dropdown-divider" />
              </div>
              <div className="">
                <div className="setting_header pt-2 pb-2">
                  <div className="setting_header2 ">
                    <div>
                      <div className="icon_bg">
                        <i className="bi bi-shield-check"></i>
                      </div>
                    </div>
                    <div className="">
                      <div className="text_style">Fund Password </div>
                      <div className="text-muted  mob-font fs-8 mb-1">
                        using during withdrawal and other scenarios to conduct
                        security verification
                      </div>
                    </div>
                  </div>
                  <div className="action_wrapper">
                    <Fund_Password />
                  </div>
                </div>
                <hr className="dropdown-divider" />
              </div>
              <div className="">
                <div className="setting_header pt-2 pb-2">
                  <div className="setting_header2 ">
                    <div>
                      <div className="icon_bg pt-1">
                        <i className="fa-solid fa-wallet"></i>
                      </div>
                    </div>
                    <div className="">
                      <div className="text_style">
                        Withdrawal Address Whitlist
                      </div>
                      <div className="text-muted  mob-font fs-8 mb-1">
                        Once enable, the Whitlisted withdrawal address don't
                        have to go through the 2FA verification process anymore.
                      </div>
                    </div>
                  </div>

                  <div className="action_wrapper">
                    <div className="custom_check">
                      <div className="form-check form-switch setting-toggle">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          defaultChecked
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="my-4">
            <h1 className="heading">Account Activities</h1>
          </div>
          <div className="card h-100 mb-3">
            <div className="card-body">
              <div className="">
                <div className="setting_header  pt-2 pb-2">
                  <div className="setting_header2">
                    <div>
                      <div className="icon_bg pt-2">
                        <i className="fa-solid fa-desktop"></i>
                      </div>
                    </div>
                    <div className="">
                      <div className="text_style">
                        Trusted Device Management
                      </div>
                      <div className="text-muted  mob-font fs-8 mb-1">
                        Once enable, the Whitlisted withdrawal address don't
                        have to go through the 2FA verification process anymore.
                      </div>
                    </div>
                  </div>
                  <div className="action_wrapper">
                    <Link to="/managment">
                      <button type="button" className="button-outline">
                        Management
                      </button>
                    </Link>
                  </div>
                </div>
                <hr className="dropdown-divider" />
              </div>
              <div className="">
                <div className="setting_header pt-2 pb-2">
                  <div className="setting_header2">
                    <div>
                      <div className="icon_bg pt-2">
                        <i className="fa-regular fa-file"></i>
                      </div>
                    </div>
                    <div className="">
                      <div className="text_style">Account Activites</div>
                      <div className="text-muted  mob-font fs-8 mb-1">
                        Once enabled,you can only withdraw to address saved in
                        your Address book .Manage withdrawal address.
                      </div>
                    </div>
                  </div>
                  <div className="action_wrapper">
                    <Link to="/account_activity">
                      <button type="button" className="button-outline">
                        View
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Setting;
