import React, { useEffect, useRef, useState } from "react";
import Header from "../Component/Header";
import Sidebar from "../Component/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { claimTokenRef, cutAfterDecimal, fetchWalletHandler, stakeHistory, stakeRefHistory } from "../utils/actionHandler";
import { FaArrowRight } from "react-icons/fa";
import { GoCopy } from "react-icons/go";
import { Link } from "react-router-dom";
import "@lottiefiles/lottie-player";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getReferrralsList } from "../utils/apis";
import Tooltip from '@mui/material/Tooltip';
import { Snackbar } from '@mui/material';
import { saveAs } from 'file-saver';
import QRCode from 'qrcode';
import { toast } from "react-toastify";

const Referral = () => {
  const dispatch = useDispatch();
  const [copiedCode, setCopiedCode] = useState(false);
  const [copiedLink, setCopiedLink] = useState(false);
  const [list, setList] = useState([]);
  const { userdata, user, sessionId, conditionsData } = useSelector((state) => state.user);
  const { prices, assets, allRefstakes, stakesRef } = useSelector((state) => state.wallet);

  const usdt = assets.find((it) => it?.symbol == "USDT");
  const {
    premiumCommision,
    stakingCommision,
    totalReferrals,
    totalReferralRewards,
    totalPremiumReferrals,
    referralId,
  } = userdata;
  const ref_url = "https://auth.inrx.io/invite/ref=" + referralId;
  const ref_url_local = "http://localhost:3000/invite/ref=" + referralId;
  const [referralText, setReferralText] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loadingState, setLoadingState] = useState([]);
  const [totalReferralBonus,setTotalReferralBonus]= useState({});


  const handleCopyCode = () => {
    setCopiedCode(true);
    setTimeout(() => setCopiedCode(false), 2000);
  };

  const handleCopyLink = () => {
    setCopiedLink(true);
    setTimeout(() => setCopiedLink(false), 2000);
  };

  const canvasRef = useRef(null);

  const handleSaveImage = async () => {
    const canvas = canvasRef.current;
    if (!canvas) {
      console.error('Canvas element not found');
      return;
    }
  
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      console.error('Failed to get canvas context');
      return;
    }
  
    // if (isSaving) return; // Prevent multiple clicks
    // setIsSaving(true); // Set flag to indicate saving is in progress
  
    try {
      // Generate QR code data URL
      const qrCodeUrl = await QRCode.toDataURL(ref_url, { width: 300 });
      const logoUrl = '/images/inrx_circle.svg'; // Update with your logo path
  
      // Create a new image for the QR code
      const img = new Image();
      img.src = qrCodeUrl;
  
      // Create a new image for the logo
      const logoImg = new Image();
      logoImg.src = logoUrl;
  
      // Function to draw on canvas
      const drawCanvas = () => {
        const width = img.width;
        const height = img.height;
        canvas.width = width;
        canvas.height = height + 80; // Add extra space for text
  
        // Draw QR code image onto canvas
        ctx.drawImage(img, 0, 80); // Move the QR code down to make space for text
  
        // Draw the "INRx" logo in the center of the QR code
        const logoSize = 50; // Size of the logo
        const logoX = (width - logoSize) / 2;
        const logoY = (height - logoSize) / 2 + 80; // Offset for text space
  
        ctx.drawImage(logoImg, logoX, logoY, logoSize, logoSize);
  
        // Add text above the QR code
        ctx.font = '20px Arial';
        ctx.textAlign = 'center';
  
        // Draw the "INR" text in green
        ctx.fillStyle = 'green';
        ctx.fillText('INR', width / 2 - 10, 30);
  
        // Draw the "x" text in black
        ctx.fillStyle = '#000'; // Text color
        ctx.fillText('x', width / 2 + 10, 30);
  
        // Draw the remaining text
        ctx.font = '16px Arial';
        ctx.fillStyle = '#000'; // Text color
        ctx.fillText('Referral Program', width / 2, 50);
  
        // Convert canvas to Blob
        canvas.toBlob((blob) => {
          if (blob) {
            saveAs(blob, 'referral-qr-code.png');
          } else {
            console.error('Failed to convert canvas to Blob');
          }
          setIsSaving(false); // Reset flag after saving
        }, 'image/png');
      };
  
      // Ensure both images are loaded before drawing on canvas
      img.onload = () => {
        logoImg.onload = () => {
          drawCanvas();
        };
        logoImg.onerror = () => {
          console.error('Failed to load logo image');
          setIsSaving(false); // Reset flag if logo image fails
        };
      };
      img.onerror = () => {
        console.error('Failed to load QR code image');
        setIsSaving(false); // Reset flag if QR code image fails
      };
    } catch (error) {
      console.error('Error generating QR code:', error);
      setIsSaving(false); // Reset flag on error
    }
  };
  
  
  // State to manage saving status
  const [isSaving, setIsSaving] = useState(false);  
  

  const handleShare = async(platform) => {
    const shareData = {
      title: 'INRx Referral Program',
      text: referralText,
      url: ref_url
    };

    switch (platform) {
      case 'whatsapp':
        window.open(`https://wa.me/?text=${encodeURIComponent(shareData.text)}`, '_blank');
        break;
      case 'twitter':
        window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareData.url)}&text=${encodeURIComponent(shareData.text)}`, '_blank');
        break;
      case 'linkedin':
        window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareData.url)}`, '_blank');
        break;
      case 'facebook':
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareData.url)}`, '_blank');
        break;
      case 'download':
       await handleSaveImage()
        // Handle download logic here
        break;
      default:
        break;
    }
  };

  function claim(stakeId) {
    if (loadingState[stakeId]) {
      toast.info("Please wait for pending request");
      return;
    }
  
    // Set loading to true for the specific stakeId
    setLoadingState((prevState) => ({
      ...prevState,
      [stakeId]: true,
    }));
  
    claimTokenRef(userdata, sessionId, stakeId, (msg) => {
      // Set loading to false after the request completes
      setLoadingState((prevState) => ({
        ...prevState,
        [stakeId]: false,
      }));
      toast.success(msg ? msg : "Claimed Successfully!");
      fetchWalletHandler(userdata?.mobile, sessionId, dispatch);
      setTimeout(() => {
        stakeRefHistory(userdata, dispatch, sessionId, () => {});
      }, 1500);
    });
  }


  useEffect(() => {
    if (userdata?.mobile) {
      getReferrralsList(userdata?.mobile, sessionId)
        .then((res) => {
          if (res?.status == 200) {
            setList(res?.data);
            setTotalReferralBonus(res?.referralBounsTotalData)
          }
        })
        .catch((e) => {
          console.log("Error in referrals page useEffect");
        });
        
    }
    stakeRefHistory(userdata, dispatch, sessionId, () => {});

  }, [userdata, sessionId]);


  return (
    <>
      <Header />
      <Sidebar />
      <main className="mt-5 pt-5 deposit_main">
      <div className="mb-3">
            <h1 className="heading mb-1">Affiliate</h1>
            <div className="text-muted mob-font">
              Refer and earn with your friends and colleague
            </div>
          </div>
        <div className="container-fluid">
         

          <div className="col-lg-12 col-sm-12 mb-3">
            <div className="row align-items-center full_banner">
              <div className="col-lg-10">
                <div className="banner_heading">
                  Invite a friend <br /> and you'll earn {conditionsData?.referral_bonus} INRx
                </div>
                <p className="sub_description">
                  Get {conditionsData?.referral_bonus} INRx on first INRx deposit of your referral
                </p>

                <a
                  href="#"
                  className="btn custom_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#inviteFriend"
                  onClick={() =>
                    setReferralText(
                      `Sign up for an INRx account and claim exclusive rewards from the INRx referral program! Plus, claim up to 500 INRx bonus at ${ref_url}`
                    )
                  }
                >
                  Invite Friends
                </a>
              </div>
              <div className="col-lg-2 text-end">
                <img
                  src="/images/business-team-sharing-rewards.svg"
                  className="img_fluid hide_on_mobile"
                />
              </div>
            </div>
          </div>

          <h4 className="heading text-center mb-4">
            One Invitation for Multiple Rewards
          </h4>

          <div className="row justify-content-center mb-5">
            <div className="col-lg-4 mb-3">
              <div className="card overflow-hidden position-relative">
                <div className="card-body">
                  <div className="text-color">Get Up to</div>
                  <div className="dollar_icon">
                    <img src="/images/inrx_circle.svg" alt="reward_img" />
                  </div>
                  <div className="d-flex gap-3 align-items-center mb-3 text-color">
                    <div>
                      <div>
                        <span className="value_div"> {conditionsData?.referral_bonus} </span>
                        <span>INRx</span>
                      </div>
                      <div>in Rewards</div>
                    </div>
                  </div>
                  <h6 className="mb-3 headingh6">
                    On first depositing INRx from your invitee
                  </h6>
                  <div className="d-grid">
                    <button className="btn custom_btn"   data-bs-toggle="modal"
                  data-bs-target="#inviteFriend"
                  onClick={() =>
                    setReferralText(
                      `Sign up for an INRx account and claim exclusive rewards from the INRx referral program! Plus, claim up to ${conditionsData?.referral_bonus} INRx bonus at ${ref_url}`
                    )
                  }
                  >Invite</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="card overflow-hidden position-relative">
                <div className="card-body">
                  <div className="text-color">Get Up to</div>
                  <div className="dollar_icon">
                    <img src="/images/inrx_circle.svg" alt="reward_img" />
                  </div>

                  <div className="d-flex gap-3 align-items-center mb-3 text-color">
                    <div>
                      <div className="value_div">10%</div>
                      <div>Commission</div>
                    </div>
                  </div>

                  <h6 className="mb-3 headingh6">
                    On premium subscription of your invitee
                  </h6>
                  <div className="d-grid">
                    <button className="btn custom_btn"   
                      data-bs-toggle="modal"
                      data-bs-target="#inviteFriend"
                      onClick={() =>
                        setReferralText(
                          `Earn a 10% commission on every premium subscription your invitee purchases! Share the link to get started: ${ref_url}`
                        )
                      }
                      >Invite</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="card overflow-hidden position-relative">
                <div className="card-body">
                  <div className="text-color">Get Up to</div>
                  <div className="dollar_icon">
                    <img src="/images/inrx_circle.svg" alt="reward_img" />
                  </div>

                  <div className="d-flex gap-3 align-items-center mb-3 text-color">
                    <div>
                      <div className="value_div">1%</div>
                      <div>Commission</div>
                    </div>
                  </div>

                  <h6 className="mb-3 headingh6">
                    From Staking Apr of your invitee
                  </h6>
                  <div className="d-grid">
                    <button className="btn custom_btn"   data-bs-toggle="modal"
                  data-bs-target="#inviteFriend"
                  onClick={() =>
                    setReferralText(
                      `Earn a 1% commission on the APR from the staking rewards of your invitee! Share the link to get started: ${ref_url}`
                    )
                  }
                  >Invite</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="highlight_card">
            <div className="col-lg-12">
              <h4 className="heading">How to Invite</h4>
              <div class="flex_row mt-3">
                <div class="mb-3">
                  <div class="text-center ">
                    <div class="card-body">
                      <div className="text-center">
                        <img
                          src="/images/rewards/user-dark.png"
                          alt=""
                          className="img_50 "
                        />
                      </div>
                      <h5 class="refer_heading">Share Your Code and Link</h5>
                      <p class="text-lgray mb-0">
                        You can invite your friends to use all INRx products
                        with just one referral code.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="arrow_col">
                  <img
                    src="/images/rewards/arrow.png"
                    alt=""
                    className="img_50 hide_on_mobile"
                  />
                </div>
                <div class="mb-3">
                  <div class="text-center ">
                    <div class="card-body ">
                      <div className="text-center">
                        <img
                          src="/images/rewards/connect-light.png"
                          alt=""
                          className="img_50"
                        />
                      </div>
                      <h5 class="refer_heading">Connect with Your Friends</h5>
                      <p class="text-lgray mb-0">
                        Your friends will be associated with you after they sign
                        up.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="arrow_col">
                  <img
                    src="/images/rewards/arrow.png"
                    alt=""
                    className="img_50 hide_on_mobile"
                  />
                </div>
                <div class="mb-3">
                  <div class="text-center">
                    <div class="card-body ">
                      <div className="text-center">
                        <img
                          src="/images/rewards/reward-light.png"
                          alt=""
                          className="img_50"
                        />
                      </div>
                      <h5 class="refer_heading">Get Multiple Rewards</h5>
                      <p class="text-lgray mb-0">
                        INRx Rewards when your friends trade, apply for a INRx
                        Card or start copy trading.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-divider"></div>

              <div className="row">
                <div className="col-lg-6 mb-3">
                  <h6>My Referral Code</h6>
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div> {referralId}</div>
                        <div>
                          {copiedCode && (
                            <span
                              id="codeTooltip"
                              effect="solid"
                              className="bg-dark p-1 text-white rounded me-1"
                            >
                              Copied!
                            </span>
                          )}
                          <CopyToClipboard
                            text={referralId}
                            onCopy={handleCopyCode}
                          >
                            <GoCopy
                              className="text-green"
                              data-tip
                              data-for="codeTooltip"
                              style={{ cursor: "pointer" }}
                            />
                          </CopyToClipboard>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-3">
                  <h6>My Referral Link</h6>
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>{ref_url}</div>
                        <div>
                          {copiedLink && (
                            <span
                              id="codeTooltip"
                              effect="solid"
                              className="bg-dark p-1 text-white rounded me-1"
                            >
                              Copied!
                            </span>
                          )}
                          <CopyToClipboard
                            text={ref_url}
                            onCopy={handleCopyLink}
                          >
                            <GoCopy
                              className="text-green"
                              data-tip
                              data-for="codeTooltip"
                              style={{ cursor: "pointer" }}
                            />
                          </CopyToClipboard>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row spacer">
            <h2 className="heading">My Invitations</h2>
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="fs-5 mb-3">Overview</h4>

                  <div className="display_row mb-3">
                    <div className="mb-3">
                      <p className="text-color mb-1">My Affiliate Income</p>
                      <h4 className="fw-bold">
                      {totalReferralBonus?.totalReferralBonusSum || 0}
                        {/* {totalReferralRewards
                          ? cutAfterDecimal(
                              totalReferralRewards ,
                              3
                            )
                          : 0} */}
                        <span className="text-color fw-normal fs-5 ms-1">
                          ₹
                        </span>
                      </h4>
                    </div>
                    <div className="mb-3">
                      <p className="text-color mb-1"> Joining Reward</p>
                      <h4 className="fw-bold">
                        {/* {cutAfterDecimal(totalReferralRewards, 3)} */}
                        0
                        <span className="text-color fw-normal fs-5 ms-1">
                          INRx
                        </span>
                      </h4>
                    </div>
                    <div className="mb-3">
                      <p className="text-color mb-1">Premium Commission</p>
                      <h4 className="fw-bold">
                        {cutAfterDecimal(premiumCommision, 4)}
                        <span className="text-color fw-normal fs-5 ms-1">
                          INRx
                        </span>
                      </h4>
                    </div>

                    <div className="mb-3">
                      <p className="text-color mb-1">Staking Apr Commission</p>
                      <h4 className="fw-bold">
                      {cutAfterDecimal((allRefstakes?.INRx?.profitAmount),4)}
                        {/* {cutAfterDecimal(stakingCommision, 4)} */}
                        <span className="text-color fw-normal fs-5 ms-1">
                          INRx
                        </span>
                      </h4>
                    </div>
                    <div className="mb-3">
                      <p className="text-color mb-1">Total Friends</p>
                      <h4 className="fw-bold">{totalReferrals}</h4>
                    </div>
                    <div className="mb-3">
                      <p className="text-color mb-1">Qualified Friends</p>
                      <h4 className="fw-bold">
                      {totalReferralBonus?.referralBonusUsersCount || 0}
                      {/* {totalPremiumReferrals} */}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row spacer pt-0">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="fs-5 mb-3">Referral History</h4>
                  <div class="table-responsive custom_table">
                    <table class="table table-borderless">
                      <thead>
                        <tr>
                          <th scope="col" class="text-start">
                            Referral
                          </th>

                          <th scope="col">First INRx Deposit</th>
                          <th scope="col">Subscribe Premium</th>
                          <th scope="col">Staked INRx</th>
                          <th scope="col">Joined Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list?.length > 0
                          ? list?.map((item, i) => {
                              return (
                                <tr key={i + "allreferrals"}>
                                  <td>
                                    <div class="text-start">
                                      {item?.name?.toUpperCase()}
                                    </div>
                                  </td>
                                  <td>
                                    <div class="">
                                      {item?.rewards?.find(
                                        (it) => it?.firstdepositusdt
                                      )?.firstdepositusdt
                                        ? "Deposited"
                                        : "Not Yet"}
                                    </div>
                                  </td>
                                  <td
                                    className={`${
                                      item?.premium ? "text-success" : ""
                                    }`}
                                  >
                                    {item?.premium
                                      ? "Subscribed"
                                      : "Not Subscribe"}
                                  </td>
                                  <td>
                                    {item?.totalStake
                                      ? item?.totalStake
                                      : "Not Yet"}
                                  </td>
                                  <td class="text-muted">
                                    {new Date(item?.createdAt).toLocaleString()}
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row spacer pt-0">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="fs-5 mb-3">Reward History</h4>
                  <div class="table-responsive custom_table">
                    <table class="table table-borderless">
                      <thead>
                        <tr>
                          <th scope="col" class="text-start">
                            Referral
                          </th>
                          <th scope="col">
                            Affiliate Reward
                            <img
                              src="/images/inrx_circle.svg"
                              className="img_25 ms-1"
                              alt=""
                            />
                          </th>
                          <th scope="col">
                            Premium Commission
                            <img
                              src="/images/inrx_circle.svg"
                              className="img_25 ms-1"
                              alt=""
                            />
                          </th>
                          <th scope="col">
                            Staked Apr
                            <img
                              src="/images/inrx_circle.svg"
                              className="img_25 ms-1"
                              alt=""
                            />
                          </th>
                          <th scope="col">Credit Applied</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list?.length > 0
                          ? list?.map((item, i) => {
                              const afflt_rwrd = item?.incomes?.find(
                                (it) => it?.rewardName == "Referral Bonus"
                              );
                              const premium_commision = item?.incomes?.reduce(
                                (total, it) => {
                                  if (it?.rewardName == "Premium Bonus") {
                                    return total + it?.amount;
                                  } else {
                                    return total;
                                  }
                                },
                                0
                              );
                              const apr_commision = item?.incomes?.reduce(
                                (total, it) => {
                                  if (it?.rewardName == "APR Bonus") {
                                    return total + it?.amount;
                                  } else {
                                    return total;
                                  }
                                },
                                0
                              );

                              return (
                                <tr key={i + "allreferrals"}>
                                  <td>
                                    <div class="text-start">
                                      {item?.name?.toUpperCase()}
                                    </div>
                                  </td>
                                  <td>
                                    <div class="">
                                      {afflt_rwrd ? afflt_rwrd?.amount : 0}
                                    </div>
                                  </td>
                                  <td>
                                    {premium_commision
                                      ? cutAfterDecimal(premium_commision, 8)
                                      : 0}
                                  </td>
                                  <td>
                                    {apr_commision
                                      ? cutAfterDecimal(apr_commision, 8)
                                      : 0}
                                  </td>
                                  <td class="text-muted">YES</td>
                                </tr>
                              );
                            })
                          : null}
                        {/* <tr>
                        <td>
                          <div class="text-start">Willium Wade</div>
                        </td>
                        <td>
                          <div class="">50</div>
                        </td>

                        <td>2499</td>
                        <td>573</td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td>
                          <div class="text-start">Willium Wade</div>
                        </td>
                        <td>
                          <div class="">50</div>
                        </td>

                        <td>2499</td>
                        <td>200</td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td>
                          <div class="text-start">Willium Wade</div>
                        </td>
                        <td>
                          <div class="">50</div>
                        </td>

                        <td>0</td>
                        <td>225</td>
                        <td>Yes</td>
                      </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row spacer pt-0">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="fs-5 mb-3">Staking Reward History</h4>
                  <div class="table-responsive custom_table">
                    <table class="table table-borderless">
                      <thead>
                        <tr>
                          <th scope="col" class="text-start">
                            Referral
                          </th>
                          <th scope="col">Staked INRx</th>
                          <th scope="col">APR</th>
                          <th scope="col">claim</th>
                          <th scope="col">roi</th>
                          <th scope="col">action</th>
                          {/* <th scope="col">Joined Date</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {stakesRef?.length > 0
                          ? stakesRef?.map((item, i) => {
                              return (
                                <tr key={i + "allreferrals"}>
                                  <td>
                                    <div class="text-start">
                                      {item?.referrerName?.toUpperCase()}
                                    </div>
                                  </td>
                                  <td>
                                    {item?.stakeAmount
                                      ? item?.stakeAmount
                                      : "Not Yet"}
                                  </td>
                                  <td>
                                    <div class="">
                                      1%
                                    </div>
                                  </td>
                                  <td
                                    className={`text-success`}
                                  >
                                    {cutAfterDecimal(item?.claimAmount,6)}
                                  </td>
                                  <td  className={`text-success`}>
                                    {cutAfterDecimal(item?.roi,6)}
                                  </td>
                                  <td class="text-muted">
                                  <button className="btn btn-sm btn-success-green" type="button" onClick={()=> claim(item?._id)}>
                                  {loadingState[item?._id] ? (
                                        <div className="spinner-border text-black load-icon mx-1" role="status">
                                          <span className="sr-only">Loading...</span>
                                        </div>
                                      ) : (
                                        "Claim"
                                      )}
                                  </button>
                                  </td>
                                 
                                  {/* <td class="text-muted">
                                    {new Date(item?.createdAt).toLocaleString()}
                                  </td> */}
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="spacer ">
            <div className="col-lg-12">
              <h3 className="heading">FAQ(s) </h3>
            </div>
            <div className="col-lg-12 help-center-accordion">
              <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      How do I refer someone to the INRx platform?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      To refer someone, simply share your unique referral code
                      with them. They must use this code when registering on the
                      INRx platform to ensure you receive your referral rewards.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      When will I receive my referral reward and commissions?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      Referral rewards and commissions will be credited to your
                      INRx wallet within 7 days of your invitee's qualifying
                      transaction, which includes their first USDT deposit,
                      premium subscription purchase, or staking activity.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      Is there a limit to the number of people I can refer?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      No, there is no limit to the number of invitees you can
                      refer. You can refer as many people as you like and earn
                      rewards for each successful referral.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingfour">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapsefour"
                      aria-expanded="false"
                      aria-controls="flush-collapsefour"
                    >
                      What happens if my invitee forgets to use my referral code
                      during registration?
                    </button>
                  </h2>
                  <div
                    id="flush-collapsefour"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingfour"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      Unfortunately, if the invitee does not use your referral
                      code during registration, you will not be eligible to
                      receive the referral rewards or commissions associated
                      with that invitee.
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingnine">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapsenine"
                      aria-expanded="false"
                      aria-controls="flush-collapsenine"
                    >
                      Can I participate in the referral program if I have not
                      completed my KYC verification?
                    </button>
                  </h2>
                  <div
                    id="flush-collapsenine"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingnine"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      No, only users who have completed their KYC verification
                      on the INRx platform are eligible to participate in the
                      referral program.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingten">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseten"
                      aria-expanded="false"
                      aria-controls="flush-collapseten"
                    >
                      What should I do if I suspect fraudulent activity related
                      to my referral rewards?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseten"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingten"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      If you suspect any fraudulent activity, please contact
                      INRx customer support immediately. INRx reserves the right
                      to investigate and disqualify any participants found to be
                      engaging in fraudulent or abusive practices.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="spacer">
            <div className="heading">TERMS & CONDITIONS</div>

            <ul class="green_bulltes">
              <li>
                <div className="list_content">
                  <span className="no_list text-green ">Eligibility:</span>
                  <span>
                    The referral program is open to all registered users of the
                    INRx platform who have successfully completed their KYC
                    verification.
                  </span>
                </div>
              </li>

              <li>
                <div className="list_content">
                  <span className="no_list text-green "> Referral Reward:</span>
                  <span>
                    Referrers will receive a reward of 50 USDT when their
                    invitee makes their first deposit of USDT on the INRx
                    platform.
                  </span>
                </div>
              </li>
              <li>
                <div className="list_content">
                  <span className="no_list text-green ">
                    Premium Subscription Commission:
                  </span>
                  <span>
                    Referrers will earn a 10% commission on any premium
                    subscription fees paid by their invitees.
                  </span>
                </div>
              </li>
              <li>
                <div className="list_content">
                  <span className="no_list text-green ">
                    Staking APR Commission:
                  </span>
                  <span>
                    Referrers will receive a 5% commission based on the annual
                    percentage rate (APR) earned by their invitees from staking
                    activities.
                  </span>
                </div>
              </li>
              <li>
                <div className="list_content">
                  <span className="no_list text-green ">
                    {" "}
                    Reward Crediting:{" "}
                  </span>
                  <span>
                    All rewards and commissions will be credited to the
                    referrer’s INRx wallet within 7 days of the qualifying
                    transaction by the invitee.
                  </span>
                </div>
              </li>
              <li>
                <div className="list_content">
                  <span className="no_list text-green ">
                    Invitee Conditions:
                  </span>
                  <span>
                    The invitee must use the unique referral code provided by
                    the referrer at the time of registration to be eligible for
                    the referral benefits.
                  </span>
                </div>
              </li>
              <li>
                <div className="list_content">
                  <span className="no_list text-green ">
                    Multiple Referrals:
                  </span>
                  <span>
                    There is no limit to the number of invitees a referrer can
                    invite; however, each invitee can only be referred by one
                    referrer.
                  </span>
                </div>
              </li>
              <li>
                <div className="list_content">
                  <span className="no_list text-green ">Fraud and Abuse:</span>
                  <span>
                    INRx reserves the right to disqualify any referrer or
                    invitee suspected of fraud or abuse of the referral program.
                    This includes, but is not limited to, the creation of fake
                    accounts and the use of deceptive practices.
                  </span>
                </div>
              </li>
              <li>
                <div className="list_content">
                  <span className="no_list text-green ">Program Changes: </span>
                  <span>
                    INRx reserves the right to modify or terminate the referral
                    program at any time without prior notice. Any changes will
                    be effective immediately upon posting.
                  </span>
                </div>
              </li>

              <li>
                <div className="list_content">
                  <span className="no_list text-green ">
                    Dispute Resolution:
                  </span>
                  <span>
                    Any disputes arising from the referral program will be
                    subject to the exclusive jurisdiction of the courts of the
                    country where INRx is registered.
                  </span>
                </div>
              </li>
            </ul>
          </div>

          <div className="spacer pt-0">
            <div className="card">
              <div className="card-body">
                <div className="col-lg-12 text-center p-4">
                  <div className="earn_heading">
                    Join INRx Affiliates Program to unlock Up to 45% Commission
                  </div>
                  <div className="mt-4">
                    <a href="#" className="btn custom_btn">
                      Start Earning Now <FaArrowRight className="ms-1" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <div
        class="modal fade"
        id="inviteFriend"
        tabindex="-1"
        aria-labelledby="inviteFriendLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="inviteFriendLabel">
                Invite Friends
              </h1>
              <button
                type="button"
                class="btn customchnage2 "
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div class="modal-body">
              <div className="row">
                <div className="col-lg-6">
                  <lottie-player
                    autoplay
                    loop
                    mode="normal"
                    src="/images/referral-ads.json"
                  ></lottie-player>
                </div>
                <div className="col-lg-6">
                  <div class="my-4">
                    <div class="">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                        My Referral Code
                        </label>
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Referral Code"
                            aria-label="Referral Code"
                            aria-describedby="Referral Code"
                            value={referralId}
                          />
                          <span
                            class="input-group-text text-muted"
                            id="Referral Code"
                          >
                            <span class="text-green fs-14">
                            <div>
                          {copiedLink && (
                            <span
                              id="codeTooltip"
                              effect="solid"
                              className="bg-dark p-1 text-white rounded me-1"
                            >
                              Copied!
                            </span>
                          )}
                          <CopyToClipboard
                            text={referralId}
                            onCopy={handleCopyLink}
                          >
                            <GoCopy
                              className="text-green"
                              data-tip
                              data-for="codeTooltip"
                              style={{ cursor: "pointer" }}
                            />
                          </CopyToClipboard>
                        </div>
                            </span>
                          </span>
                        </div>
                      </div>
                      <div class="mb-4">
                        <label for="exampleInputPassword1" class="form-label">
                          Customize your text
                        </label>

                        <textarea
                          type="text"
                          class="form-control"
                          placeholder="Amount"
                          rows={6}
                          multiple
                          value={referralText}
                          // {`Sign up for a INRx account and claim exclusive rewards from the INRx referral program! Plus, claim up to 10,000 INRx bonus at .https://auth.inrx.io?ref=${referralId}`}
                        ></textarea>
                      </div>

                      <div className="display_row">
                        {/* <Tooltip title="Save Image"> */}
                          <Link onClick={() => handleShare('download')}>
                            <div className="text-center mb-2">
                              <img src="/images/download.svg" alt="" className="img_35" />
                              <div className="fs-13 text-color">Save Image</div>
                            </div>
                          </Link>
                          <canvas ref={canvasRef} style={{ display: 'none' }} />

                        {/* </Tooltip> */}

                        {/* <Tooltip title="Share on WhatsApp"> */}
                          <Link onClick={() => handleShare('whatsapp')}>
                            <div className="text-center mb-2">
                              <img src="/images/whatsapp.svg" alt="" className="img_35" />
                              <div className="fs-13 text-color">WhatsApp</div>
                            </div>
                          </Link>
                        {/* </Tooltip> */}

                        {/* <Tooltip title="Share on Twitter"> */}
                          <Link onClick={() => handleShare('twitter')}>
                            <div className="text-center mb-2">
                              <img src="/images/twitter.svg" alt="" className="img_35" />
                              <div className="fs-13 text-color">Twitter</div>
                            </div>
                          </Link>
                        {/* </Tooltip> */}

                        {/* <Tooltip title="Share on LinkedIn"> */}
                          <Link onClick={() => handleShare('linkedin')}>
                            <div className="text-center mb-2">
                              <img src="/images/linkedin.svg" alt="" className="img_35" />
                              <div className="fs-13 text-color">LinkedIn</div>
                            </div>
                          </Link>
                        {/* </Tooltip> */}

                        {/* <Tooltip title="Share on Facebook"> */}
                          <Link onClick={() => handleShare('facebook')}>
                            <div className="text-center mb-2">
                              <img src="/images/facebook.svg" alt="" className="img_35" />
                              <div className="fs-13 text-color">Facebook</div>
                            </div>
                          </Link>
                        {/* </Tooltip> */}

                        {/* <Tooltip title="Copy Link"> */}
                          <CopyToClipboard text={ref_url} onCopy={() => setOpenSnackbar(true)}>
                            <Link>
                              <div className="text-center mb-2">
                                <img src="/images/copy-link.svg" alt="" className="img_35" />
                                <div className="fs-13 text-color">Copy Link</div>
                              </div>
                            </Link>
                          </CopyToClipboard>
                        {/* </Tooltip> */}

                        <Snackbar
                          open={openSnackbar}
                          autoHideDuration={3000}
                          onClose={() => setOpenSnackbar(false)}
                          message="Referral link copied!"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Referral;
