import React, { useEffect, useState } from "react";
import Header from "../Component/Header";
import Sidebar from "../Component/Sidebar";
import { activityHistory } from "../utils/apis";
import { useSelector } from "react-redux";
import { cutAfterDecimal } from "../utils/actionHandler";

const Activity = () => {
  const { user, sessionId } = useSelector((state) => state.user);
  const [data, setData] = useState([]);

  useEffect(() => {
    activityHistory(user?.mobile, sessionId)
      .then((res) => {
        if (res?.status == 200) {
          const sd = res?.data?.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
          setData(sd);
        } else {
          setData([]);
        }
      })
      .catch((e) => {
        console.log(e, "Error in activityHistory");
      });
  }, []);

  return (
    <>
      <Header />
      <Sidebar />
      <main className="mt-5 pt-5">
        <div className="container-fluid">
          <h4 className="heading">
            My Assets
          </h4>

        
          <div className="managment_section">
            <div className="card rounded-lg overflow-hidden">
              <div className=" card-body">

              <div className="assets_header mb-2">
            <div className="">
              <div className="input-group mb-2">
                <span className="input-group-text" id="search">
                  <i className="fa-solid fa-magnifying-glass text-muted"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="search"
                />
              </div>
            </div>
            <div className="d-flex fs-6 gap-3 mb-2">
              <button
                type="button"
                className="btn custom_btn"
              >
                <i className="bi bi-filter" /> sort
              </button>
              <button
                type="button"
                className="btn custom_btn"
              >
                <i className="bi bi-funnel" /> Filter
              </button>
            </div>
          </div>

                <div className="table-responsive custom_activity_table">
                  <table className="table ">
                    <thead className="sticky-head">
                      <tr>
                        <th scope="col" className="text-start">
                          Activity
                        </th>
                        {/* <th scope="col">ID</th> */}
                        <th>Symbol</th>
                        <th>Amount</th>
                        <th>Date</th>
                      </tr>
                    </thead>                  
                    <tbody>
                      {data?.length ? (
                        data?.map((it, i) => {
                          const amount =
                            it?.actionName == "stake"
                              ? cutAfterDecimal(it?.stakeAmount, 4)
                              : it?.actionName == "swap"
                              ? cutAfterDecimal(it?.payAmount, 4) +
                                "/" +
                                cutAfterDecimal(it?.getAmount, 4)
                              : cutAfterDecimal(it?.amount, 4);
                          const symbol =
                            it?.actionName == "swap"
                              ? it?.payToken + "/" + it?.getToken
                              : it?.symbol;
                          const red =
                            it?.actionName == "swap"
                              ? false
                              : it?.actionName == "stake"
                              ? true
                              : it?.actionName == "claim"
                              ? false
                              : it?.actionName == "withdraw"
                              ? true
                              : false;
                          return (
                            <tr>
                              <td scope="row">
                                <div className="d-flex align-items-center gap-2">
                                  <div className="coin_style2">
                                    <i className="fa-solid fa-arrow-up "></i>
                                    <div className="status-offline"></div>
                                  </div>
                                  <div
                                    className={`text-capitalize ${
                                      red ? "text-danger" : "text-success"
                                    }`}
                                  >
                                    {it?.actionName}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="mt-2 mb-2">{symbol}</div>
                              </td>
                              <td className="text-success">
                                <div className="mt-2 mb-2">{amount}</div>
                              </td>
                              <td className="text-success">
                                <div className="mt-2 mb-2">
                                  {new Date(it?.createdAt).toLocaleString()}
                                </div>
                              </td>
                              {/* <td>
                              <div className="">
                                <em className="fst-normal text-muted">
                                  ₹-0.26
                                </em>
                                <br />
                                0.0000125 INRx
                              </div>
                            </td> */}
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                        <td colSpan={4}>
                        <div className="text-center">
                            <img
                              src="/images/loader.gif"
                              style={{
                                height: "120px",
                                width: "120px",
                                filter: "opacity(0.09)",
                              }}
                            />
                          </div>
                        </td>
                        
                      </tr>
                      )}                      
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Activity;
