import React from "react";
import Earn_accordian from "./Earn_accordian";

const Earn_faq = () => {
  return (
    <>
      <button
        type="button"
        className="btn earn_btn"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        What is INRx Staking?{" "}
      </button>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered  modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                What is INRx Staking?
              </h5>
              <i
                className="fa-solid fa-xmark icon-color"
              
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <div className="mob-font mb-2">
                    You will receive Wrapped Beacon INRx (INR) when you stake
                    INRx via Binance. INR is a utility token that represents
                    your stake INR, in a tradable and transfe- rble from.
                  </div>
                  <p className="mob-font text-green mb-2">
                    Learn about more INRx
                  </p>
                  <div className="fs-5 mb-2">
                    INR<em className="fst-normal text-green">x</em> On-Chain
                    Contract
                  </div>
                  <div className="d-flex gap-3 mb-5 ">
                    <button type="button" className="btn earn_btn  mob-font">
                      Ethereum
                    </button>
                    <button type="button" className="btn earn_btn mob-font">
                      BNB Smart Chain
                    </button>
                  </div>
                  <div className="fs-5 mb-2">
                    INR<em className="fst-normal text-green">x</em> Audit Report
                  </div>
                  <div className="d-flex gap-3 mb-2">
                    <button type="button" className="btn earn_btn  mob-font">
                      Peckshleld
                    </button>
                    <button type="button" className="btn earn_btn  mob-font">
                      Slowmist
                    </button>
                  </div>
                  <div className="d-flex gap-3 mb-5">
                    <button type="button" className="btn earn_btn  mob-font">
                      Supremacy
                    </button>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="account_activity">
                    <div className="">
                      <ul className="nav nav-pills">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-bs-toggle="pill"
                            href="#use-case"
                            role="tab"
                            aria-controls="use-case"
                            aria-selected="true"
                          >
                            Use Cases
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-bs-toggle="pill"
                            href="#reward"
                            role="tab"
                            aria-controls="reward"
                            aria-selected="false"
                          >
                            Rewards & Conversion Radio
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content mt-3">
                        <div
                          className="tab-pane fade show active"
                          id="use-case"
                          role="tabpanel"
                          aria-labelledby="use-case-tab"
                        >
                          <div className="">
                            
                           <Earn_accordian/>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="reward"
                          role="tabpanel"
                          aria-labelledby="reward-tab"
                        >
                          <div className="">
                            
                            <Earn_accordian/>
                           </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex gap-3 mb-2 mt-3 justify-content-end ">
                    <button type="button" className="btn earn_btn  mob-font">
                      FAQ
                    </button>
                    <button type="button" className="btn earn_btn mob-font">
                      Learn more
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Earn_faq;
