import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  claimToken,
  cutAfterDecimal,
  fetchWalletHandler,
  stakeHistory,
} from "../utils/actionHandler";
import { toast } from "react-toastify";

const Redeem = () => {
  const { user, sessionId, userdata } = useSelector((state) => state.user);
  const { allstakes, stakes } = useSelector((state) => state.wallet);
  const [totalReward, setTotalreward] = useState(0);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  var tt;
  useEffect(() => {
    // var iscomplete = 0;
    // stakes?.reduce((pre, item) => {
    //   if (item?.isStakeCompleted) {
    //     iscomplete += 1;
    //   }
    // }, false);
    // console.log(iscomplete, stakes, "stakes");
    // if (iscomplete < stakes?.length) {
    //   tt = setTimeout(() => {
        stakeHistory(userdata, dispatch, sessionId, () => {});
    //   }, 15000);
    // } else {
    //   clearTimeout(tt);
    // }
    // stakeHistory(user, dispatch, sessionId, () => {});
    // return () => {
    //   clearTimeout(tt);
    // };
  }, [userdata]);

  function claim() {
    if (loading) {
      toast.info("Please wait for pending request");
      return;
    }
    setLoading(true);
    claimToken(userdata, sessionId, (msg) => {
      setLoading(false);
      toast.success(msg?msg:"Claimed Successfuly!");
      fetchWalletHandler(userdata?.mobile, sessionId, dispatch);
      setTimeout(() => {
        stakeHistory(userdata, dispatch, sessionId, () => {});
      }, 1500);
    });
  }

  useEffect(() => {
    const allstakeobj = { ...allstakes };
    if (Object.keys(allstakes)?.length) {
      let totalreward = 0;
      Object.keys(allstakes)?.forEach((item) => {
        const amt = allstakeobj[item] ? allstakeobj[item]?.roi : 0;
        totalreward += Number(amt);
      });
      const tamt = Number(Number(totalreward).toFixed(4));
      setTotalreward(tamt);
    }
  }, [allstakes]);

  return (
    <>
      <button
        type="button"
        className="btn earn_btn"
        data-bs-toggle="modal"
        data-bs-target="#Redeem"
      >
        Redeem INRx
      </button>

      <div
        className="modal fade"
        id="Redeem"
        tabIndex="-1"
        aria-labelledby="RedeemLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered staking">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="RedeemLabel">
                Redeem INRx{" "}
              </h1>
              <i
                className="fa-solid fa-xmark icon-color"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="d-flex">
                <div className="d-flex align-items-center jutify-content-center gap-2 m-auto">
                  <div className="border border-warning rounded-pill text-center pe-3 ps-3">
                    AVAILABLE
                  </div>
                </div>
              </div>
              <div className="">
                <div className="mb-2 mt-sm-3 fw-bold fs-3 text-center text-green">
                  +&nbsp;{totalReward ? totalReward : "0.00"}{" "}
                  <img src="images/favicon.svg" alt="" />
                </div>

                <div className="">
                  <div className="mb-2 mt-sm-3 mob-font ">
                    <div className="text-muted me-2"> Claim Reward </div>
                  </div>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control p-1 custombg text-black"
                      placeholder="-----"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      value={totalReward ? totalReward : "0.00"}
                      disabled={true}
                      contentEditable={false}
                    />
                    <span
                      className="input-group-text custombg"
                      id="basic-addon2"
                    >
                      <span className="coin_style4 p-0">
                        <img src="images/favicon.svg" alt="inrx" />{" "}
                      </span>{" "}
                      &nbsp; INRx
                    </span>
                  </div>
                </div>
                <div className="">
                  <div
                    className="alert alert-success d-flex  gap-1 p-2"
                    role="alert"
                  >
                    <i className="bi bi-info-circle"></i>
                    <div className="fs-12">
                      When stacking 1 INRx, you receive less than 1 INR because
                      INR has higher value increases over time as staking
                      rewards accrue after launch. Upon redemption, you will get
                      back your initial INRx and earned rewards.
                    </div>
                  </div>
                  <table className="market-details-table mb-2 table-borderless">
                    <tbody>
                      <tr>
                        <th
                          role="cell"
                          scope="row"
                          className="fw-normal text-muted"
                        >
                          Total Staked
                        </th>
                        <td className=" ">
                          {" "}
                          {allstakes
                            ? cutAfterDecimal(allstakes?.INRx?.stakeAmount, 4)
                            : "0.00"}{" "}
                          INRx
                        </td>
                      </tr>
                      <tr>
                        <th
                          role="cell"
                          scope="row"
                          className="fw-normal text-muted"
                        >
                          Total Claim
                        </th>
                        <td className=" ">
                          {allstakes
                            ? cutAfterDecimal(allstakes?.INRx?.claimAmount, 4)
                            : "0.00"}{" "}
                          INRx
                        </td>
                      </tr>
                      <tr>
                        <th
                          role="cell"
                          scope="row"
                          className="fw-normal text-muted"
                        >
                          Remaining Amount
                        </th>
                        <td className=" ">{allstakes
                            ? cutAfterDecimal(allstakes?.INRx?.availableAmount - allstakes?.INRx?.stakeAmount , 4)
                            : "0.00"}{" "}
                          INRx</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="d-grid mb-3">
                  <button className="custom_btn" type="button" onClick={claim}>
                    {loading ? (
                      <div
                        className="spinner-border text-black load-icon mx-1"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : null}
                    Redeem
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Redeem;
