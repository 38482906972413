import React, { useEffect, useState } from "react";
import Sidebar from "../Component/Sidebar";
import Header from "../Component/Header";
import { useDispatch, useSelector } from "react-redux";
import { withdrawINR } from "../utils/apis";
import { toast } from "react-toastify";
import { cutAfterDecimal, fetchWalletHandler } from "../utils/actionHandler";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import Buy from "./Buy";
import Sell from "./Sell";
import Exchange from "./Exchange";

const Convert = () => {
  const { user, sessionId, userdata } = useSelector((state) => state.user);
  const { assets } = useSelector((state) => state.wallet);
  const [amount, setAmount] = useState("");
  const [otp, setotp] = useState("");
  const [loading, setloading] = useState(false);
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();
  const gettoken = assets.find((it) => it.symbol == "INR");
  const navigate = useNavigate();

  return (
    <>
      <Header />
      <Sidebar />

      <main className="mt-5 pt-5">
        <div className="container-fluid">
          <h1 className="heading text-center">
            Convert <em className="fst-normal text-green">Currency</em>
          </h1>
          <div className="">
            <div className="col-xl-5 col-lg-6 m-auto">
              <div className="row">
                <div
                  className="card text-center  mb-4 mt-3 dashbord-card"
                  style={{ height: "450px" }}
                >
                  <div className="card-body">
                    <div className="">
                      <ul className="nav nav-pills gap-2">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-bs-toggle="pill"
                            href="#Buy"
                            role="tab"
                            aria-controls="Buy"
                            aria-selected="true"
                          >
                            Buy
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-bs-toggle="pill"
                            href="#Sell"
                            role="tab"
                            aria-controls="Sell"
                            aria-selected="false"
                          >
                            Sell
                          </a>
                        </li>
                        {/* <li className="nav-item">
                          <a
                            className="nav-link"
                            data-bs-toggle="pill"
                            href="#Exchange"
                            role="tab"
                            aria-controls="Exchange"
                            aria-selected="false"
                          >
                            Exchange
                          </a>
                        </li> */}
                      </ul>
                      <div className="tab-content mt-3">
                        <div
                          className="tab-pane fade show active"
                          id="Buy"
                          role="tabpanel"
                          aria-labelledby="Buy-tab"
                        >
                          <Buy />
                        </div>
                        <div
                          className="tab-pane fade"
                          id="Sell"
                          role="tabpanel"
                          aria-labelledby="Sell-tab"
                        >
                          <Sell />
                        </div>
                        <div
                          className="tab-pane fade"
                          id="Exchange"
                          role="tabpanel"
                          aria-labelledby="Exchange-tab"
                        >
                          <div className="text-center">
                            <div class="">
                              <Exchange />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Convert;
