import "./App.css";
import "./Css/Style.css";
import { BrowserRouter } from "react-router-dom";
import RoutesMain from "./Routes";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
import Theme from "./Theme/Theme";
import { GlobalStyles } from "./Theme/GlobalStyles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {
  return (
    <>
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Theme>
              <GoogleOAuthProvider clientId={process.env.REACT_APP_OAutht_Id}>
                <GlobalStyles />
                <RoutesMain />
              </GoogleOAuthProvider>
            </Theme>
          </PersistGate>
        </Provider>
      </BrowserRouter>
      <ToastContainer position="bottom-right" />
    </>
  );
}

export default App;
