export const lightTheme = {
  body: "#E5EEE5",
  gray: "#f0f0f0",
  color: "#000" ,
  whitecolor:"#000",
  modelbody:"#fff",
  cardbg: "#fff",
  sidebarbg:"#fff",
  inptgrptxt: "#E5EEE5 ",
  inptborder: "1px solid #E5EEE5 ",
  inptcolor: "#000 ",
  btnborder: "1px solid #cccccc",
  divedertxt: "#737578b5",
  divederborder: "1px solid #7375783d",
  stepiconborder: "2px solid #000",
  stepcolor: "#000",
  appbtnbg: "#000",
  sidebarborder: "1px solid #fff",
  headerborder: "1px solid #fff",
  headerbg:"#fff",
  custombg: "#fff !important",
  custominputborder: "1px solid #fff !important",
  menuitemhoverbg: "#B6D4B6",
  menucolor: "#fff !important",
  dashboard_bg: "#182122",
  bannercolor: "#fff",
  userprrofileborder: "4px dashed #02FF01",
  bannerbtn: "#fff",
  bannerbtncol: "#000",
  navborder: "1px solid #545454",
  active_nav_link_bg: "#000 !important",
  active_nav_color: "#fff !important",
  inpuutborder2: " 1px solid #E5EEE5 !important",
  inputbg2: "#E5EEE5 !important",
  buybtnbg:"#02FF01",
  buybtncol:"#000",
  buyicobg:"#B6D4B6",
  accbg:"linear-gradient(#fffffff7, #ffffff),linear-gradient(175deg, #840840 0%, #DEB83B 35%, #95BA46 67%, #5B9352 100%)",
  cardheader:"#F3F3F3",
  custom_border_col_btn:"#8ab38a",
  custombgbtn:"#8ab38a",
  custombgbtn2:"#B6D4B6",
  coinstyle2:"#e6e6e6",
  offlinesta:"#ccc",
  logo_card:"#E5EEE5",
  tbl_head:"#E6E6E7",
  verficationbg:" linear-gradient(90deg, #8ab38a  20%, #fff 100%);",
  verficationborder:"1px solid #d2d1d1 ",
  accordionbg:"unset !important",
  global_head_color:"#000",
  global_head_bg:"#fff",
  earn_bg:"#ffff",
  earn_color:"#000",
  earn_bordercolor:"#fff",
  dark_color: "#f3f4f7",
  dark_gray:"#fff",
  gray_color:"#00000020",
  button_outline: "#ccc",
  transparent:"transparent",
  greencolor:"#4caf50!important",
  table_border_bottom:"1px solid #efefef !important",
  greenlight:"#B6D4B6",
  custombtn_bg:"#B6D4B6",
   greenborder:'#B6D4B6',
   activecolor:"#4caf50",
   custom_btn:"#B6D4B6",
   inputgrp:"#B6D4B6",
   amount_heading:"#335835",
   box_wrapper:"#fff",
   icon_color:"#23232321",
   box_right:"#a1e6a33b",
   highlight_card:"#021f0294",
    accordionbody:"#65676a",
    select_option:"#f5f5f5",
    scrollbar_bg:"#fff",
   scrollbar_thumb:"#eee",
   menu_iconbg: "#57e55b3b"
};

export const darkTheme = {

  body: "#000",
  color: "#fff" ,
  modelbody:"#1a1a1a",
  cardbg: "#181818",
  sidebarbg:"#0d0d0d",
  headerbg:"#0d0d0d",
  inptgrptxt: "#313839 ",
  inptborder: "1px solid #313839 ",
  inptcolor: "#fff ",
  btnborder: "1px solid #464646",
  stepiconborder: "2px solid #28a745",
  stepcolor: "#fff",
  appbtnbg: "transparent",
  sidebarborder: "1px solid #3332328a",
  headerborder: "1px solid #3332328a",
  menuitemhoverbg: "#2e492e",
  menucolor: "#000 !important",
  dashboard_bg: "#383A99",
  bannercolor: "#fff",
  userprrofileborder: "4px dashed #5854FF",
  bannerbtn: "#534FFF",
  bannerbtncol: "#fff",
  navborder: "1px solid #fff",
  active_nav_link_bg: "#2e492e !important",
  active_nav_color: "#fff !important",
  input2bg: "#182122 !important",
  input2border: "1px solid #182122 !important",
  buybtnbg:"#182122",
  buybtncol:"#fff",
  buyicobg:"#232C2D",

  accbg:"linear-gradient(#191620f0, #191620),linear-gradient(175deg, #840840 0%, #DEB83B 35%, #95BA46 67%, #5B9352 100%)",
  cardheader:"#303038",
  custom_border_col_btn:"#424348",
  custombgbtn:"#424348",
  custombgbtn2:"#232C2D",
  custombg:"#232C2D",
  custominputborder: "1px solid #232C2D !important",
  coinstyle2:"#1A1B1D",
  offlinesta:"#d5dae0",
  logo_card:"#000",
  tbl_head:"#303839 !important",
  verficationbg:" linear-gradient(90deg, #2c772c 20%, #222c2df2 100%)",
  verficationborder:"1px solid #484646 ",
  table_border_bottom:"1px solid #202020 !important",
  accordionbg:"unset !important",
  global_bg:"#080808",
  global_color:"rgb(136 134 134)",
  globalborder_bottom:"1px solid rgb(93 93 93 / 8%)",
  global_head_color:" rgb(182 188 196 / 60%)",
  global_head_bg:"#080808",
  earn_bg:"#232C2D",
  earn_color:"#fff",
  earn_bordercolor:"#232C2D",
  color_th:"#606060",
  dark_color: "#202020",
  dark_gray:"#1c1c1e",
  whitecolor:"#fff",
  gray_color:"#242424",
  button_outline: "#242424",
  transparent:"transparent",
  greencolor:"#02FF01 !important",
  greenlight:"transparent",
  custombtn_bg:"#02FF01",
  greenborder:'#02FF01',
  custom_btn:'#02FF01',
  inputgrp:"#242424",
  amount_heading:"#02FF01",
  box_wrapper:"#242424",
  icon_color:"#423f3f",
  box_right:"#3a3a3aab",
   highlight_card:"#2e492e",
   accordionbody:"#adb1b8",
   select_option:"#242424",
   scrollbar_bg:"#0D0D0D",
   scrollbar_thumb:"#242424",
   gray: "#3d3c3c",
   menu_iconbg: "#4d4d4e"
};
