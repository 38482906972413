import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useDispatch, useSelector } from "react-redux";
import {
  googleSignInHandler,
  handleForgetOtpPassword,
  handleForgetPassword,
  loginWithEmailHandler,
  loginWithMobileHandler,
  qrLoginInfoHandler,
} from "../utils/actionHandler";
import { Link, useNavigate, useParams } from "react-router-dom";
import { setLoginId } from "../redux/qrLoginSlice";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import { GoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";
import { emailOTPVerify, mobileOTPVerify } from "../utils/apis";
import { setAuthInfo, setSession } from "../redux/userInfoSlice";
import { MdOutlineMailOutline } from "react-icons/md";
import { IoChevronBack, IoEyeOutline, IoKeyOutline } from "react-icons/io5";
import { ImMobile2 } from "react-icons/im";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { MdOutlineRemoveRedEye } from "react-icons/md";

const Login = () => {
  const theme = useSelector((state) => state.theme.theme);
  const { login } = useSelector((state) => state.user);
  const { loginId } = useSelector((state) => state.qrlogin);
  const [logindata, setlogindata] = useState({});
  const [useremail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [otp, setotp] = useState("");
  const [step, setstep] = useState(0);
  const [mobilelogin, setmobilelogin] = useState(false);
  const [credential, setCredential] = useState("");
  const [isActiveTab, setIsActiveTab] = useState("email");
  const [isEv, setIsEv] = useState(false);
  const [forgetActive, setForgetActive] = useState(false);
  const [forgetOtpBox, setForgetOtpBox] = useState(false);
  const [forgetStep, setForgetStep] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useGoogleOneTapLogin({
    onSuccess: (credentialResponse) => {
      // console.log(credentialResponse, " your email credential");
      setCredential(credentialResponse?.credential);
    },
    onError: () => {
      console.log("Login Failed");
    },
  });
  var tt;
  function listenLogin() {
    clearTimeout(tt);
    if (login) {
      return;
    }
    if (!mobilelogin) {
      qrLoginInfoHandler(
        loginId,
        "login",
        dispatch,
        (res) => {
          if (typeof res != "boolean") {
            setlogindata(res);
            // console.log(res, "resssss");
            if (res?.data?.loginId != loginId) {
              clearTimeout(tt);
              dispatch(setLoginId(res?.data?.loginId));
            }
            if (!res?.data?.expired) {
              tt = setTimeout(() => {
                listenLogin();
              }, 5000);
            } else {
              clearTimeout(tt);
              dispatch(setLoginId(""));
              setlogindata({ expired: true });
            }
          }
        },
        params?.id ? params?.id?.split("=")[1] : ""
      );
    }
  }

  const handleOtpChange = async (otpValue) => {
    setotp(otpValue);

    if (otpValue.length === 6) {
      setLoading(true); // Show loader
      try {
        let response;
        if (isActiveTab == "email") {
          response = await emailOTPVerify(otpValue, useremail);
        } else {
          response = await mobileOTPVerify(otpValue, useremail);
        }

        console.log(response, "response");

        if (response?.status === 200) {
          console.log("OTP verified successfully");
          setmobilelogin(true);
          dispatch(setAuthInfo({ user: response?.user, login: true }));
          dispatch(setSession(response?.user?.tokenId));
          setmobilelogin(true);
          toast.success(response?.message);
          setstep(0);
        } else {
          console.log("OTP verification failed");
          toast.error(response?.message || "OTP verification failed");
        }
      } catch (error) {
        console.error("Error verifying OTP:", error);
        toast.error(
          "An error occurred during OTP verification. Please try again."
        );
      } finally {
        setLoading(false); // Hide loader
      }
    }
  };

  const handleOtpChangeForget = async (otpValue) => {
    setotp(otpValue);
    if (otpValue.length === 6) {
      setLoading(true); // Show loader
      handleForgetOtpPassword(
        setLoading,
        setmobilelogin,
        setForgetStep,
        dispatch,
        useremail,
        otpValue,
        password,
        "otp"
      );
    }
  };

  useEffect(() => {
    if (loginId && !login) {
      listenLogin();
    }
    return () => clearTimeout(tt);
  }, [loginId, mobilelogin, params, login]);

  useEffect(() => {
    if (login) {
      // console.log("login successfuly via qrcode");
      navigate("/dashboard");
    }
  }, [login]);

  useEffect(() => {
    if (credential) {
      googleSignInHandler(
        credential,
        setmobilelogin,
        dispatch,
        params?.id ? params?.id?.split("=")[1] : ""
      );
    }
  }, [credential]);

  useEffect(() => {
    setForgetActive(false);
    setForgetOtpBox(false);
    setIsEv(false);
    setotp("");
    setPassword("");
    setUserEmail("");
  }, [isActiveTab]);

 function handleReset(){
    setForgetActive(false);
    setForgetOtpBox(false);
    setIsEv(false);
    setotp("");
    setPassword("");
    setUserEmail("");
  }

  return (
    <>
      <div className="container-fluid">
        <div className="container">
          <div className="row logincardmain">
            <div className="col-xl-9 col-lg-10 m-auto">
              <div className="login_container">
                <div className="col-lg-6 mb-3">
                  <div className="card customcard">
                    <div className="card-body">
                      <h5 className="card-title fs-5">
                        {forgetActive
                          ? forgetOtpBox
                            ? "Reset Password"
                            : "Forgot Password"
                          : "Login or Register"}
                      </h5>
                      <hr />
                      <div className="">
                        {forgetActive ? (
                          <>
                            <div className="d-flex flex-column justify-content-between  pwd-min-card">
                              <div>
                                <div className="logo_circle_inrx mt-4">
                                  <img
                                    src="/images/white@72x.png"
                                    alt="INRx"
                                    width="35px"
                                    className="img-fluid"
                                  />
                                </div>

                                {!forgetOtpBox && (
                                  <>
                                    <h6 className="mb-1 fs-16 text-center">
                                      {" "}
                                      Are you forget password or want to reset ?
                                    </h6>
                                    <p className="text-lgray mb-4 fs-14 text-center">
                                      Please enter registered email or mobile
                                      number to change password
                                    </p>

                                    <div className="input-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Registered Email or Mobile Number"
                                        autoComplete="Your Mobile no."
                                        value={useremail}
                                        onChange={(e) =>
                                          setUserEmail(e.target.value)
                                        }
                                      />
                                    </div>
                                    <div className="w-100 mt-3">
                                      <button
                                        className="custom_btn w-100"
                                        type="button"
                                        onClick={() => {
                                          if (!loading) {
                                            handleForgetPassword(
                                              (res) => {
                                                console.log(res, "RES:::::::");
                                                if (res === 1) {
                                                  setForgetOtpBox(true);
                                                } else {
                                                  setForgetOtpBox(false);
                                                }
                                              },
                                              setLoading,
                                              useremail
                                            );
                                          } else {
                                            toast.info(
                                              "Wait we are processing..."
                                            );
                                          }
                                        }}
                                      >
                                        {loading ? (
                                          <div
                                            className="spinner-border text-black load-icon mx-1"
                                            role="status"
                                          ></div>
                                        ) : null}
                                        Continue
                                      </button>
                                    </div>
                                  </>
                                )}

                                {forgetOtpBox && (
                                  <>
                                    {forgetStep != 1 && (
                                      <div className="text-center mt-3 mb-5">
                                        <label
                                          htmlFor="exampleInputEmail1"
                                          className="form-label text-center h6"
                                        >
                                          We have sent a verification code to{" "}
                                          <strong> {useremail}</strong>
                                          {/* <strong>{user?.mobile}</strong> */}
                                        </label>
                                        <div className="mb-3">
                                          {/* <div className="text-center">OTP</div> */}
                                          <OtpInput
                                            value={otp}
                                            onChange={handleOtpChangeForget}
                                            inputMode="numeric"
                                            numInputs={6}
                                            renderSeparator={
                                              <span className="text-secondary mx-1"></span>
                                            }
                                            renderInput={(props) => (
                                              <input
                                                {...props}
                                                className="form-control"
                                                style={{ width: "40px" }}
                                                inputMode="numeric" // Ensures numeric keypad on mobile
                                                pattern="[0-9]*" // Enforces numeric input
                                                type="tel"
                                                onKeyPress={(e) => {
                                                  if (!/[0-9]/.test(e.key)) {
                                                    e.preventDefault();
                                                  }
                                                }}
                                              />
                                            )}
                                            containerStyle={{
                                              justifyContent: "center",
                                              paddingBlock: "2%",
                                            }}
                                          />
                                        </div>
                                        {loading && (
                                          <div className="d-flex align-items-center justify-content-center loader m-3">
                                            <div
                                              className="spinner-border text-black load-icon mx-1"
                                              role="status"
                                            ></div>
                                            Verifying OTP...
                                          </div>
                                        )}{" "}
                                        <div className="text-center">
                                          {" "}
                                          Did'nt receive the OTP?{" "}
                                          <Link to="" className="anchor_link">
                                            Resend
                                          </Link>
                                        </div>
                                      </div>
                                    )}
                                    {forgetStep === 1 && (
                                      <>
                                        <p className="text-lgray mb-3 text-center">
                                          Enter a new password ,the password
                                          must be different from previous used
                                          password{" "}
                                        </p>
                                        <div className="input-group mt-3">
                                          <div className="input-group">
                                            <input
                                              type={
                                                showPassword
                                                  ? "text"
                                                  : "password"
                                              }
                                              className="form-control"
                                              placeholder="New Password"
                                              autoComplete="Your password"
                                              value={password}
                                              onChange={(e) =>
                                                setPassword(e.target.value)
                                              }
                                            />
                                            <span
                                              className="input-group-text"
                                              onClick={togglePasswordVisibility}
                                            >
                                              {showPassword ? (
                                                <FiEyeOff />
                                              ) : (
                                                <FiEye />
                                              )}
                                            </span>
                                          </div>
                                        </div>

                                        <div className="w-100 mt-3">
                                          <button
                                            className="custom_btn w-100"
                                            type="button"
                                            onClick={() => {
                                              if (!loading) {
                                                handleForgetOtpPassword(
                                                  setLoading,
                                                  setmobilelogin,
                                                  setForgetStep,
                                                  dispatch,
                                                  useremail,
                                                  otp,
                                                  password,
                                                  "password"
                                                );
                                              } else {
                                                toast.info(
                                                  "Wait we are processing..."
                                                );
                                              }
                                            }}
                                          >
                                            {loading ? (
                                              <div
                                                className="spinner-border text-black load-icon mx-1"
                                                role="status"
                                              ></div>
                                            ) : null}
                                            Confirm
                                          </button>
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                             <div className="text-center" onClick={handleReset}> <Link className="anchor_link"><IoChevronBack /> Back To Login</Link></div>
                            </div>
                          </>
                        ) : (
                          <>
                           {step === 0 && <ul className="nav nav-pills">
                              <li className="nav-item">
                                <a
                                  className="nav-link active"
                                  data-bs-toggle="pill"
                                  href="#mobile"
                                  role="tab"
                                  aria-controls="mobile"
                                  aria-selected="true"
                                  onClick={() => setIsActiveTab("email")}
                                >
                                  Email ID
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  data-bs-toggle="pill"
                                  href="#qr"
                                  role="tab"
                                  aria-controls="qr"
                                  aria-selected="false"
                                  onClick={() => setIsActiveTab("mobile")}
                                >
                                  Mobile Number
                                </a>
                              </li>
                            </ul>}
                            <div className="tab-content mt-3">
                              <div
                                className="tab-pane fade show active"
                                id="mobile"
                                role="tabpanel"
                                aria-labelledby="mobile-tab"
                              >
                                {/* {forgetActive ? (
                              <>
                                {!forgetOtpBox && (
                                  <>
                                    <div className="input-group-- d-flex gap-1">
                                      <div
                                        className="input-group-text"
                                        id="basic-addon1"
                                      >
                                        <MdOutlineMailOutline />
                                      </div>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Your Email"
                                        autoComplete="Your Email"
                                        value={useremail}
                                        onChange={(e) =>
                                          setUserEmail(e.target.value)
                                        }
                                      />
                                    </div>
                                    <div className=" w-100 mt-3">
                                      <button
                                        className="custom_btn w-100"
                                        type="button"
                                        onClick={() => {
                                          if (!loading) {
                                            handleForgetPassword(
                                              (res) => {
                                                if (res === 1) {
                                                  setForgetOtpBox(true);
                                                } else {
                                                  setForgetOtpBox(false);
                                                }
                                              },
                                              setLoading,
                                              useremail,
                                              ""
                                            );
                                          } else {
                                            toast.info(
                                              "Wait we are procecing..."
                                            );
                                          }
                                        }}
                                      >
                                        {loading ? (
                                          <div
                                            className="spinner-border text-black load-icon mx-1"
                                            role="status"
                                          ></div>
                                        ) : null}
                                        Confirm
                                      </button>
                                    </div>
                                  </>
                                )}

                                {forgetOtpBox && (
                                  <>
                                    <div className="text-center">OTP</div>
                                    <OtpInput
                                      value={otp}
                                      onChange={setotp}
                                      inputMode="numeric"
                                      numInputs={6}
                                      renderSeparator={
                                        <span className=" text-secondary mx-1">
                                          -

                                        </span>
                                      }
                                      renderInput={(props) => (
                                        <input
                                          {...props}
                                          className=" form-control"
                                          style={{ width: "50px" }}
                                        />
                                      )}
                                      containerStyle={{
                                        justifyContent: "center",
                                        paddingBlock: "2%",
                                      }}
                                    />
                                    <div className="input-group-- d-flex gap-1 mt-3">
                                      <div
                                        className="input-group-text"
                                        id="password"
                                      >
                                        <IoKeyOutline />
                                      </div>
                                      <div className="input-group">
                                        <input
                                          type={
                                            showPassword ? "text" : "password"
                                          }
                                          className="form-control"
                                          placeholder="New Password"
                                          autoComplete="Your password"
                                          value={password}
                                          onChange={(e) =>
                                            setPassword(e.target.value)
                                          }
                                        />
                                        <span
                                          className="input-group-text"
                                          onClick={togglePasswordVisibility}
                                        >
                                          {showPassword ? (
                                            <FiEyeOff />
                                          ) : (
                                            <FiEye />
                                          )}
                                        </span>
                                      </div>
                                    </div>

                                    <div className=" w-100 mt-3">
                                      <button
                                        className="custom_btn w-100"
                                        type="button"
                                        onClick={() => {
                                          if (!loading) {
                                            handleForgetOtpPassword(
                                              setLoading,
                                              setmobilelogin,
                                              dispatch,
                                              useremail,
                                              "",
                                              otp,
                                              password
                                            );
                                          } else {
                                            toast.info(
                                              "Wait we are procecing..."
                                            );
                                          }
                                        }}
                                      >
                                        {loading ? (
                                          <div
                                            className="spinner-border text-black load-icon mx-1"
                                            role="status"
                                          ></div>
                                        ) : null}
                                        Confirm
                                      </button>
                                    </div>
                                  </>
                                )} */}

                                <div className="login_input_grp">
                                  {step == 0 ? (
                                    <div
                                      id="passwordHelpBlock"
                                      className="form-text mb-3"
                                    >
                                      Create an account or login with registered
                                      email
                                    </div>
                                  ) : (
                                    <div
                                      id="passwordHelpBlock"
                                      className="form-text mb-3 text-center"
                                    >
                                      An OTP has been sent to the Email ID you
                                      entered.
                                    </div>
                                  )}

                                  {step == 0 ? (
                                    <>
                                      <div className="input-group-- d-flex gap-1">
                                        <div
                                          className="input-group-text"
                                          id="basic-addon1"
                                        >
                                          <MdOutlineMailOutline />
                                        </div>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Your Email"
                                          autoComplete="Your Email"
                                          value={useremail}
                                          onChange={(e) =>
                                            setUserEmail(e.target.value)
                                          }
                                        />
                                      </div>
                                      <div className="input-group-- d-flex gap-1 mt-3">
                                        <div
                                          className="input-group-text"
                                          id="password"
                                        >
                                          <IoKeyOutline />
                                        </div>
                                        <div className="input-group">
                                          <input
                                            type={
                                              showPassword ? "text" : "password"
                                            }
                                            className="form-control"
                                            placeholder="Password"
                                            autoComplete="Your password"
                                            value={password}
                                            onChange={(e) =>
                                              setPassword(e.target.value)
                                            }
                                          />
                                          <span
                                            className="input-group-text"
                                            onClick={togglePasswordVisibility}
                                          >
                                            {showPassword ? (
                                              <FiEyeOff />
                                            ) : (
                                              <FiEye />
                                            )}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="mt-3 mb-3 d-grid">
                                        <button
                                          className="custom_btn"
                                          type="button"
                                          onClick={() => {
                                            if (!loading) {
                                              loginWithEmailHandler(
                                                useremail,
                                                password,
                                                params?.id
                                                  ? params?.id?.split("=")[1]
                                                  : "",
                                                otp,
                                                dispatch,
                                                setLoading,
                                                setmobilelogin,
                                                setIsEv,
                                                (askotp) => {
                                                  setotp("");
                                                  if (askotp === 1) {
                                                    setstep(1);
                                                  } else {
                                                    setstep(0);
                                                  }
                                                }
                                              );
                                            } else {
                                              toast.info(
                                                "Wait we are procecing..."
                                              );
                                            }
                                          }}
                                        >
                                          {loading ? (
                                            <div
                                              className="spinner-border text-black load-icon mx-1"
                                              role="status"
                                            ></div>
                                          ) : null}
                                          Log in
                                        </button>
                                      </div>

                                      {/* <div className="mb-3 text-end">
                                        <Link
                                          className="anchor_link"
                                          onClick={() => setForgetActive(true)}
                                        >
                                          {" "}
                                          Forgot Password?
                                        </Link>
                                      </div> */}
                                    </>
                                  ) : !isEv ? (
                                    <>
                                      <OtpInput
                                        value={otp}
                                        onChange={handleOtpChange}
                                        numInputs={6}
                                        inputMode="numeric"
                                        renderSeparator={
                                          <span className=" text-secondary mx-1"></span>
                                        }
                                        renderInput={(props) => (
                                          <input
                                            {...props}
                                            className=" form-control"
                                            style={{ width: "40px" }}
                                            inputMode="numeric" // Ensures numeric keypad on mobile
                                            pattern="[0-9]*" // Enforces numeric input
                                            type="tel"
                                            onKeyPress={(e) => {
                                              if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                              }
                                            }}
                                          />
                                        )}
                                        containerStyle={{
                                          justifyContent: "center",
                                          paddingBlock: "2%",
                                        }}
                                      />
                                      {loading && (
                                        <div className="d-flex align-items-center justify-content-center loader m-3">
                                          <div
                                            className="spinner-border text-black load-icon mx-1"
                                            role="status"
                                          ></div>
                                          Verifying OTP...
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <OtpInput
                                        value={otp}
                                        onChange={setotp}
                                        numInputs={6}
                                        inputMode="numeric"
                                        renderSeparator={
                                          <span className=" text-secondary mx-1"></span>
                                        }
                                        renderInput={(props) => (
                                          <input
                                            {...props}
                                            className=" form-control"
                                            style={{ width: "40px" }}
                                            inputMode="numeric" // Ensures numeric keypad on mobile
                                            pattern="[0-9]*" // Enforces numeric input
                                            type="tel"
                                            onKeyPress={(e) => {
                                              if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                              }
                                            }}
                                          />
                                        )}
                                        containerStyle={{
                                          justifyContent: "center",
                                          paddingBlock: "2%",
                                        }}
                                      />
                                      <div className="mt-3 mb-3 d-grid">
                                        <button
                                          className="custom_btn"
                                          type="button"
                                          onClick={() => {
                                            if (!loading) {
                                              loginWithEmailHandler(
                                                useremail,
                                                password,
                                                params?.id
                                                  ? params?.id?.split("=")[1]
                                                  : "",
                                                otp,
                                                dispatch,
                                                setLoading,
                                                setmobilelogin,
                                                setIsEv,
                                                (askotp) => {
                                                  setotp("");
                                                  if (askotp === 1) {
                                                    setstep(1);
                                                  } else {
                                                    setstep(0);
                                                  }
                                                }
                                              );
                                            } else {
                                              toast.info(
                                                "Wait we are processing..."
                                              );
                                            }
                                          }}
                                        >
                                          {loading ? (
                                            <div
                                              className="spinner-border text-black load-icon mx-1"
                                              role="status"
                                            ></div>
                                          ) : null}
                                          Log in
                                        </button>
                                      </div>
                                    </>
                                  )}

                                  {step === 0 && (
                                    <>
                                      <div className="divdermain">
                                        <div className="divider">
                                          <span className="divider-text">
                                            or log in with
                                          </span>
                                        </div>
                                      </div>

                                      <div className="row mt-3">
                                        <div className="col-lg-12 mb-2">
                                          <GoogleLogin
                                            shape="rectangle"
                                            className="custom-google-login"
                                            logo_alignment="center"
                                            // theme="filled_blue"
                                            width="100%"
                                            auto_select={false}
                                            onSuccess={(response) => {
                                              // console.log(
                                              //   response,
                                              //   "signin with googleLogin button credential"
                                              // );
                                              setCredential(
                                                response?.credential
                                              );
                                            }}
                                            onError={() => {
                                              toast.error(
                                                "Failed! SignIn with google"
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                              <div
                                className="tab-pane fade"
                                id="qr"
                                role="tabpanel"
                                aria-labelledby="qr-tab"
                              >
                                <div
                                  className="tab-pane fade show active"
                                  id="mobile"
                                  role="tabpanel"
                                  aria-labelledby="mobile-tab"
                                >
                                  <div className="login_input_grp">
                                    {step == 0 ? (
                                      <div
                                        id="passwordHelpBlock"
                                        className="form-text mb-3"
                                      >
                                        Create an account or login with
                                        registered mobile
                                      </div>
                                    ) : (
                                      <div
                                        id="passwordHelpBlock"
                                        className="form-text mb-3 text-center"
                                      >
                                        An OTP has been sent to the mobile
                                        number you entered.
                                      </div>
                                    )}

                                    {step == 0 ? (
                                      <>
                                        <div className="input-group-- d-flex gap-1">
                                          <div
                                            className="input-group-text"
                                            id="password"
                                          >
                                            🇮🇳
                                          </div>

                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Your Mobile no."
                                            autoComplete="Your Mobile no."
                                            value={useremail}
                                            onChange={(e) =>
                                              setUserEmail(e.target.value)
                                            }
                                          />
                                        </div>
                                        <div className="input-group-- d-flex gap-1 mt-3">
                                          <div
                                            className="input-group-text"
                                            id="basic-addon1"
                                          >
                                            <IoKeyOutline />
                                          </div>
                                          <div className="input-group">
                                            <input
                                              type={
                                                showPassword
                                                  ? "text"
                                                  : "password"
                                              }
                                              className="form-control"
                                              placeholder="Password"
                                              autoComplete="Your password"
                                              value={password}
                                              onChange={(e) =>
                                                setPassword(e.target.value)
                                              }
                                            />

                                            <span
                                              className="input-group-text"
                                              onClick={togglePasswordVisibility}
                                            >
                                              {showPassword ? (
                                                <FiEyeOff />
                                              ) : (
                                                <FiEye />
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="mt-3 mb-3 d-grid">
                                          <button
                                            className="custom_btn"
                                            type="button"
                                            onClick={() => {
                                              if (!loading) {
                                                loginWithMobileHandler(
                                                  useremail,
                                                  password,
                                                  params?.id
                                                    ? params?.id?.split("=")[1]
                                                    : "",
                                                  otp,
                                                  dispatch,
                                                  setLoading,
                                                  setmobilelogin,
                                                  (askotp) => {
                                                    console.log(
                                                      askotp,
                                                      "askotp"
                                                    );
                                                    setotp("");
                                                    if (askotp === 1) {
                                                      setstep(1);
                                                    } else {
                                                      setstep(0);
                                                    }
                                                  }
                                                );
                                              } else {
                                                toast.info(
                                                  "Wait we are procecing..."
                                                );
                                              }
                                            }}
                                          >
                                            {loading ? (
                                              <div
                                                className="spinner-border text-black load-icon mx-1"
                                                role="status"
                                              ></div>
                                            ) : null}
                                            Log in
                                          </button>
                                        </div>
                                      </>
                                    ) : (
                                      <div className="text-center">
                                        <OtpInput
                                          value={otp}
                                          onChange={handleOtpChange}
                                          inputMode="numeric"
                                          numInputs={6}
                                          renderSeparator={
                                            <span className=" text-secondary mx-1"></span>
                                          }
                                          renderInput={(props) => (
                                            <input
                                              {...props}
                                              className=" form-control"
                                              style={{ width: "40px" }}
                                              inputMode="numeric" // Ensures numeric keypad on mobile
                                              pattern="[0-9]*" // Enforces numeric input
                                              type="tel"
                                              onKeyPress={(e) => {
                                                if (!/[0-9]/.test(e.key)) {
                                                  e.preventDefault();
                                                }
                                              }}
                                            />
                                          )}
                                          containerStyle={{
                                            justifyContent: "center",
                                            paddingBlock: "2%",
                                          }}
                                        />
                                        {loading && (
                                          <div className="d-flex align-items-center justify-content-center loader m-3">
                                            <div
                                              className="spinner-border text-black load-icon mx-1"
                                              role="status"
                                            ></div>
                                            Verifying OTP...
                                          </div>
                                        )}{" "}
                                        {/* Loader */}
                                      </div>
                                    )}

                                    {step === 0 && (
                                      <>
                                        <div className="divdermain">
                                          <div className="divider">
                                            <span className="divider-text">
                                              or log in with
                                            </span>
                                          </div>
                                        </div>

                                        <div className="row mt-3">
                                          <div className="col-lg-12 mb-2">
                                            <GoogleLogin
                                              shape="rectangle"
                                              logo_alignment="center"
                                              // theme="filled_blue"
                                              width="100%"
                                              auto_select={false}
                                              onSuccess={(response) => {
                                                // console.log(
                                                //   response,
                                                //   "signin with googleLogin button credential"
                                                // );
                                                setCredential(
                                                  response?.credential
                                                );
                                              }}
                                              onError={() => {
                                                toast.error(
                                                  "Failed! SignIn with google"
                                                );
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {step === 0 && (
                              <div className="my-4 text-center">
                                <Link
                                  className="anchor_link"
                                  onClick={() => {
                                      setotp("");
                                      setPassword("");
                                      setUserEmail("");
                                      setForgetActive(true);
                                    }}
                                >
                                  {" "}
                                  Forgot Password?
                                </Link>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-3 d-lg-block d-none">
                  <div className="card customcard">
                    <div className="card-body">
                      <h2 className="fw-normal h5 text-center">
                        Welcome to INRx
                      </h2>
                      <hr />
                      <h6 className="">How to Login</h6>
                      <p className="text-muted">
                        Follow the steps below to log in to your account
                      </p>
                      <div className="steps">
                        <div className="step mt-5">
                          <div className="step-icon">1</div>
                          <div className="step-content">
                            <p>Sign in to INRx</p>
                            {/* <div className="app-buttons">
                              <a
                                href="https://play.google.com/store/apps/details?id=com.trst.id"
                                className="app-button"
                                target="_blank"
                              >
                                <img
                                  src="/images/playstore.png"
                                  alt="Play Store"
                                  width="20"
                                />
                                &nbsp; Play Store
                              </a>
                              <a
                                href="https://apps.apple.com/in/app/trust-id-fast-secure-access/id6479017851"
                                className="app-button"
                                target="_blank"
                              >
                                <img
                                  src="/images/appstore.png"
                                  alt="App Store"
                                  width="20"
                                />
                                &nbsp; App Store
                              </a>
                            </div> */}
                          </div>
                        </div>
                        <div className="step">
                          <div className="step-icon">2</div>
                          <div className="step-content">
                            <p>Complete your KYC on INRx</p>
                          </div>
                        </div>
                        <div className="step">
                          <div className="step-icon">3</div>
                          <div className="step-content">
                            <p>Receive a signup reward of 10,000 INRx</p>
                          </div>
                        </div>
                        <div className="step">
                          <div className="step-icon">4</div>
                          <div className="step-content">
                            <p>Acquire INRx against collateral 1:1</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
