import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
body {
    background-color: ${(props) => props.theme.body};
    color:${(props) => props.theme.color};
  }
  .custompagination nav ul li button {
    color:${(props) => props.theme.color};
  }
  .card-title{
    color:${(props) => props.theme.color};
  }
  .card{
    background-color: ${(props) => props.theme.cardbg};
     border:1px solid ${(props) => props.theme.gray_color}
  }
  .nav-pills .nav-link:not(.dashbord-card .nav-pills .nav-link).active,
   .nav-pills .show>.nav-link{
    color:${(props) => props.theme.greencolor};
        border-bottom: 2px solid ${(props) => props.theme.greencolor};
  }

  .nav-link:not(.nav-pills .nav-link):focus,
  .nav-link:hover {
  color:${(props) => props.theme.greencolor};
  background-color: none !important;
}


.anchor_link {
    color:${(props) => props.theme.greencolor};
}

  .nav-link{
    color:${(props) => props.theme.color};
  }
  .input-group-text , .form-control{
    background-color:${(props) => props.theme.inptgrptxt};
    border:${(props) => props.theme.inptborder};
    color:${(props) => props.theme.inptcolor};   
  }

.form-control:disabled,
 .form-control[readonly] {
 background-color:${(props) => props.theme.inptgrptxt};!important
  opacity: 0.5!important;
  color: #aaa;
}

  // .form-control:focus{
  //   background-color:${(props) => props.theme.dark_gray};
  //   color:${(props) => props.theme.inptcolor};
  // }
  .custom-button{
    color:${(props) => props.theme.color};
    border:${(props) => props.theme.btnborder}
  }
  .divider-text{
    color:${(props) => props.theme.divedertxt};
  }
  .divider::before, .divider::after{
    border-bottom:${(props) => props.theme.divederborder};
  }
  .step-icon {
    border:${(props) => props.theme.stepiconborder};
    color:${(props) => props.theme.stepcolor};
  }
  .app-button{
    background-color:${(props) => props.theme.appbtnbg}
  }
  .sidebar{
    background-color: ${(props) => props.theme.sidebarbg};
    border-right:${(props) => props.theme.sidebarborder};
    
  }    
  .menu-item{
    color:${(props) => props.theme.whitecolor};
  }

  .custombg{
    background-color: ${(props) => props.theme.inputbg2};
    border:${(props) => props.theme.dark_color};
  }

  .custom_dropdown {
  background-color: ${(props) => props.theme.sidebarbg};
  border:1px solid ${(props) => props.theme.greencolor};}


  .menu-item::before{
    color:${(props) => props.theme.buybtncol};
     background-color:${(props) => props.theme.menuitemhoverbg};
  }
  .menu-item:not(.menu-item.active):hover{
    color:${(props) => props.theme.buybtncol};
  } 

  .header{
    border-bottom:${(props) => props.theme.headerborder};
    background-color: ${(props) => props.theme.headerbg};
  }
  .user-menu a {
    color:${(props) => props.theme.color};
  }
    .agreement_box input[type="text"] {
  color:${(props) => props.theme.color};
    }
  .dashboard_banner{
    // background-color: ${(props) => props.theme.dashboard_bg};
    color:${(props) => props.theme.bannercolor};
  }
  // .userprofile{
  //   border:${(props) => props.theme.userprrofileborder}
  // }
  // .menu-item{
  //   color:${(props) => props.theme.color};
  // }
  .bannerbutton{
    background-color:${(props) => props.theme.bannerbtn};
    color:${(props) => props.theme.color}
  }
     .bannerbutton:hover{
    // background-color:${(props) => props.theme.bannerbtn};
    color:${(props) => props.theme.color}
  }
  .dashbord-card .nav-pills .nav-link{
    border:1px solid ${(props) => props.theme.custombgbtn}
  }
  .dashbord-card .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color:${(props) => props.theme.active_nav_link_bg};
  color:${(props) => props.theme.active_nav_color};
}
.dashbord-card  .nav-link:hover{
  background-color:${(props) => props.theme.active_nav_link_bg};
  color:${(props) => props.theme.active_nav_color};
}
.dashbord-card .nav-link:focus
{
  background-color:${(props) => props.theme.active_nav_link_bg};
  color:${(props) => props.theme.active_nav_color};
}
.Buydiv .input-group-text{
background-color:${(props) => props.theme.input2bg};
border:${(props) => props.theme.input2border};
}
.Buydiv  .form-control{
  background-color:${(props) => props.theme.input2bg};
  border:${(props) => props.theme.input2border};
  }
  .buybtn{
    background-color:${(props) => props.theme.buybtnbg};
    color:${(props) => props.theme.buybtncol};
  }
  .inricon , .imgicon2{
    background:${(props) => props.theme.buyicobg};
    color:${(props) => props.theme.buybtncol};

  }

  .accout_div{
    background-image:${(props) => props.theme.accbg};
    color:${(props) => props.theme.buybtncol};

  }
 
// .custombtn2{
//   color:${(props) => props.theme.buybtncol};
// }
.account_main .card-header{
  background-color: ${(props) => props.theme.cardheader};
}
.customchnage{
  background: ${(props) => props.theme.custombgbtn};
  border: ${(props) => props.theme.custom_border_col_btn};
}
 .custom_table .table {
  color:${(props) => props.theme.color};
}
   .custom_table .table th {
  color:${(props) => props.theme.color_th};
}
.custom_activity_table .table , .managment_section .table , .activity_section .table , .identy_tbl .table {
  color:${(props) => props.theme.color};
}

.customchnage2{
  background: ${(props) => props.theme.custombgbtn2};
  color:${(props) => props.theme.buybtncol};
}
  .customchnage2:hover{
  // background: ${(props) => props.theme.custombgbtn2};
  color:${(props) => props.theme.buybtncol};
}

.coin_style2{
  background: ${(props) => props.theme.coinstyle2};
  color:${(props) => props.theme.color_th};
}
.status-offline {
  background: ${(props) => props.theme.custom_border_col_btn};

}
.support_main .accordion-button{
  background-color: ${(props) => props.theme.cardbg};
  color:${(props) => props.theme.color};
}
.support_main .accordion-body {
  background-color: ${(props) => props.theme.cardbg};
  color:${(props) => props.theme.color};
}
.support_main .accordion-button::after{
  color:${(props) => props.theme.color};
}
.model_btn{
  color:${(props) => props.theme.color};
}
.setting_header .btn{
  border: ${(props) => props.theme.model_btn_border};
}
 .modal-content, .offcanvas-body, .offcanvas, .offcanvas-header{
  background-color: ${(props) => props.theme.modelbody};
  color:${(props) => props.theme.color};
}

.accordion-item:not(.help-center-accordion .accordion-item) {
  border: 1px solid ${(props) => props.theme.modelbody};
  background-color: ${(props) => props.theme.modelbody};
}

.accordion-button {
  background-color: ${(props) => props.theme.modelbody};
  color: ${(props) => props.theme.color};
  
}

.accordion-button:not(.collapsed) {
 background-color: ${(props) => props.theme.modelbody};
   color: ${(props) => props.theme.color}; 
}   

.accordion-body:not(.help-center-accordion .accordion-body) {
  border-top: 1px solid ${(props) => props.theme.gray};;
}


.log-card {
  background-color: ${(props) => props.theme.logo_card};
}
.managment_section .tbl-head , .activity_section .tbl-head , .identy_tbl .tbl-head {
  background-color: ${(props) => props.theme.dark_color};
}

.verfication_alert {
  background:  ${(props) => props.theme.verficationbg};
  border:  ${(props) => props.theme.verficationborder};
}
tbody tr td{
  border-bottom: ${(props) => props.theme.table_border_bottom};
  color:${(props) => props.theme.color};

}
.verification_accrodion .accordion-button:not(.collapsed){
  color:${(props) => props.theme.color};
}
.verification_accrodion .accordion-item{
  background-color:${(props) => props.theme.accordionbg};
}
.select_box 
//  .accordion-button
 {
  background-color: ${(props) => props.theme.cardbg};
  color:${(props) => props.theme.color};
}

.secondaryForm .form-control,
.secondaryForm .form-control:focus{
  background-color: ${(props) => props.theme.cardbg};
  color:${(props) => props.theme.color};
  border: 1px solid ${(props) => props.theme.cardbg};
}

.secondaryForm .input-group-text {
  background-color:${(props) => props.theme.inputgrp};
  border:${(props) => props.theme.inputgrp};
  color:${(props) => props.theme.color};
}

.asset_dropdown_menu{
  background-color: ${(props) => props.theme.cardbg};
}

.product_name{
  color:${(props) => props.theme.color};
}

.global_table tbody tr td {
  border-bottom: ${(props) => props.theme.globalborder_bottom};
  color: ${(props) => props.theme.color};
  background: ${(props) => props.theme.global_bg};
}

.global_table thead tr th {
  color: rgb(182 188 196 / 60%);
  background:  ${(props) => props.theme.global_head_bg};
  color: ${(props) => props.theme.global_head_color};
}

.earn_btn {
  background:  ${(props) => props.theme.earn_bg};
  color: ${(props) => props.theme.earn_color};
  border-color:${(props) => props.theme.earn_bordercolor};
}

.navbar-dark .navbar-toggler {
  color:${(props) => props.theme.color};
}

.custom-text , .fa-xmark {
  color:${(props) => props.theme.color};
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root ,
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root ,
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
  color:${(props) => props.theme.color};
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  border-color:${(props) => props.theme.earn_bordercolor};
}
.cutom_border_right{
  border-right:${(props) => props.theme.sidebarborder};
}


.form-check-input:checked {
 background-color:${(props) => props.theme.gray_color};
    border-color:${(props) => props.theme.transparent};
}

.text-green  {
 color:${(props) => props.theme.greencolor};
}

.custom_btn_outline {
// background-color:${(props) => props.theme.custombtn_bg};
    border: 1px solid ${(props) => props.theme.greenborder};
    color:${(props) => props.theme.color};
}

.custom_btn_outline:hover {
background-color:${(props) => props.theme.custombtn_bg};
    border: 1px solid ${(props) => props.theme.greenborder};
    // color:${(props) => props.theme.modelbody};
}
.border-left-heading::before{ 
  border-left: 3px solid ${(props) => props.theme.greenborder};}


.custom-file-upload {
  background-color: ${(props) => props.theme.scrollbar_bg};
  border: 2px dashed ${(props) => props.theme.dark_color}; 
  }
.menu-item.active {
color:${(props) => props.theme.greencolor};
}

  .form-control:not(.custombg):focus {
  background-color:${(props) => props.theme.inptgrptxt};
  border: 1px solid ${(props) => props.theme.inptgrptxt};
  color:${(props) => props.theme.inptcolor};
  }

  .form-control.custombg:focus {
  background-color:${(props) => props.theme.dark_gray};
    // border: 1px solid ${(props) => props.theme.dark_gray};
   color:${(props) => props.theme.color};
  }

.custom_btn {
background-color:${(props) => props.theme.custom_btn};
}

.custom_btn:hover {
background-color:${(props) => props.theme.activecolor};
}

  .button-outline {
  color:${(props) => props.theme.whitecolor};
  border-color:${(props) => props.theme.gray_color};
  }

  .button-outline:hover {
   background:${(props) => props.theme.custom_btn};
    border-color:${(props) => props.theme.custom_btn};
}

.text-accordion,
 .mobile_menus,
 .menu_text,
 .mobile_menus:hover {
 color:${(props) => props.theme.color};
}

.menu_iconbg {
background:${(props) => props.theme.menu_iconbg};
}
 

.amount_heading {
 color:${(props) => props.theme.amount_heading};
}

.box_wrapper{
background-color :${(props) => props.theme.box_wrapper};
color:${(props) => props.theme.color};
}

.gift_icon_wrapper {
color :${(props) => props.theme.icon_color};
}

.box-right {
background:${(props) => props.theme.box_right};
}
.highlighted_card{
background:${(props) => props.theme.highlight_card};
}

.help-center-accordion .accordion-item {
 background-color: ${(props) => props.theme.body};
}
.help-center-accordion .accordion-button{
  background-color: ${(props) => props.theme.body};
  color:${(props) => props.theme.color};
}
.help-center-accordion .accordion-body {
  background-color: ${(props) => props.theme.body};
  color:${(props) => props.theme.accordionbody};
}
.help-center-accordion .accordion-button::after{
  color:${(props) => props.theme.color};
}
  .full_banner {
    background-color: ${(props) => props.theme.highlight_card};
      color: ${(props) => props.theme.color};
  }

  .highlight_card {
   background-color: ${(props) => props.theme.dark_gray};
      color: ${(props) => props.theme.color};
  }

.border-divider {
  border-top: 1px dashed ${(props) => props.theme.custom_border_col_btn};
}

.modal_select_options {
background-color: ${(props) => props.theme.select_option};
border: 2px solid ${(props) => props.theme.select_option};

}

// .form-select { 
//   color: ${(props) => props.theme.color};
//  background-color: ${(props) => props.theme.select_option};  
// border: 1px solid ${(props) => props.theme.select_option};
 
// }
.form-select { 
    background-color:${(props) => props.theme.inptgrptxt};
    border:${(props) => props.theme.inptborder};
    color:${(props) => props.theme.inptcolor};
  }

.form-select:focus {
background-color:${(props) => props.theme.inptgrptxt};
border: 1px solid ${(props) => props.theme.inptgrptxt};
 color:${(props) => props.theme.inptcolor};
}

.input-file::file-selector-button {  
  color: ${(props) => props.theme.color};
  background-color:${(props) => props.theme.inptgrptxt};
  border: 1px solid ${(props) => props.theme.inptgrptxt}; 
}

.input-file {
  background-color:${(props) => props.theme.select_option};
  border: 1px solid ${(props) => props.theme.select_option}; 
  color: ${(props) => props.theme.color};
}
.scrollbar::-webkit-scrollbar {
  width: 5px; 
  height: 8px;
  background-color: ${(props) => props.theme.scrollbar_bg};
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: ${(props) => props.theme.scrollbar_thumb};
}


}
`;

export { GlobalStyles };
