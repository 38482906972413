import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { convertToken } from "../utils/apis";
import { toast } from "react-toastify";
import {
  cutAfterDecimal,
  fetchWalletHandler,
  goPremiumHandler,
} from "../utils/actionHandler";

const Exchange = () => {
  const theme = useSelector((state) => state.theme.theme);
  const { user, sessionId, premium, userdata } = useSelector((state) => state.user);
  const { assets } = useSelector((state) => state.wallet);
  const [swapAmount, setswapAmount] = useState("");
  const [usdtAmount, setusdtAmount] = useState("");
  const [activeCoinData, setactiveCoinData] = useState({});
  const [activeCoin, setActiveCoin] = useState("INR");
  const usdtdata = assets.find((it) => it?.symbol == "USDT");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  function exchange() {
    const data = {
      mobile: userdata.mobile,
      token: sessionId,
      sendToken: "USDT",
      recieveToken: activeCoin,
      sendAmount: Number(usdtAmount),
      recieveAmount: Number(swapAmount),
    };

    if (!premium) {
      setLoading(true);
      goPremiumHandler(userdata?.mobile, sessionId, dispatch, setLoading);
    } else {
      if (loading) {
        toast.info("Please wait for pending request");
        return;
      }
      console.log(data, "data");
      setLoading(true);
      convertToken(data)
        .then((res) => {
          setLoading(false);
          if (res?.status == 200) {
            toast.success(res?.message);
            fetchWalletHandler(userdata?.mobile, sessionId, dispatch);
            // console.log(res);
          } else {
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          setLoading(false);
          toast.error("Something went wrong!");
          console.log(e, "Error in exchange()/exchangeform");
        });
    }
  }

  const handleInput = (val, type) => {
    if (!val) {
      setswapAmount("");
      setusdtAmount("");
      return;
    }
    const key = activeCoin?.toUpperCase() == "INRX" ? "inrx_price" : "price";
    if (type == 0) {
      const swapamt = cutAfterDecimal(Number(val) * usdtdata[key], 4);
      setusdtAmount(val);
      setswapAmount(swapamt);
    } else {
      const swapamt = cutAfterDecimal(Number(val) / usdtdata[key], 4);
      setusdtAmount(swapamt);
      setswapAmount(val);
    }
  };

  useEffect(() => {
    const activedata = assets.find(
      (it) => it.symbol == activeCoin?.toUpperCase()
    );
    setactiveCoinData(activedata);
    setswapAmount("");
    setusdtAmount("");
  }, [assets, activeCoin]);

  return (
    <>
      <div className="Buydiv text-start mt-4">
        <div className="">
          <div className="mb-3">
            <label htmlFor="exampleInputPassword1" className="form-label">
              USDT
            </label>
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">
                <div
                  className="imgicon2"
                  style={{ width: "48px", borderRadius: "3px" }}
                >
                  <img
                    src={
                      assets.find((it) => it.symbol == "USDT")?.icon ||
                      "images/usdt.svg"
                    }
                    alt=""
                    width={"25px"}
                  />
                </div>
              </span>

              <input
                type="text"
                className="form-control"
                placeholder="Enter USDT Amount"
                value={usdtAmount}
                onChange={(e) => handleInput(e.target.value, 0)}
              />
              <span
                className="input-group-text text-muted"
                id="basic-addon1"
                onClick={() =>
                  setusdtAmount(
                    cutAfterDecimal(
                      assets.find((it) => it.symbol == "USDT")?.available,
                      4
                    )
                  )
                }
              >
                <span className="inricon text-muted fs-14">MAX</span>
              </span>
            </div>
            <div className="text-end mt-2">
              <div>
                Balance:{" "}
                <span className="fw-bold text-green">
                  {cutAfterDecimal(
                    assets.find((it) => it.symbol == "USDT")?.available,
                    4
                  )}{" "}
                  USDT
                </span>
              </div>
            </div>
          </div>
          <div className=" flex-row justify-content-between pb-2">
            <div
              className=" test-success form-text pb-1"
              style={{ fontSize: "14px" }}
            >
              Select Swap Coin
            </div>
            <div className=" form-check-inline">
              <input
                type="radio"
                id="inr"
                name="inr"
                className="form-check-input"
                checked={activeCoin == "INR"}
                onChange={(e) => setActiveCoin("INR")}
              />
              <span className="form-check-label px-2">INR</span>
            </div>

            <div className="form-check-inline">
              <input
                type="radio"
                id="inr"
                name="inr"
                className="form-check-input"
                checked={activeCoin == "INRx"}
                onChange={(e) => setActiveCoin("INRx")}
              />
              <span className="form-check-label px-2">INRx</span>
            </div>
          </div>
          <div className="mb-4">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <label htmlFor="exampleInputEmail1" className="form-label">
                Amount
              </label>
              <span>
                Balance :{" "}
                <span className="fw-bold text-green">
                  {cutAfterDecimal(activeCoinData?.available, 4)} {activeCoin}
                </span>
              </span>
            </div>

            <div className="input-group">
              <span className="input-group-text" id="basic-addon1">
                {activeCoin == "INRx" ? (
                  <img src={"images/favicon.svg"} alt="" width={"25px"} />
                ) : (
                  <div className="inricon">₹</div>
                )}
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="Amount"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={swapAmount}
                onChange={(e) => handleInput(e.target.value, 1)}
              />
            </div>

            {/* <div className="d-flex justify-content-between fs-5 mt-5">
              <div className="text-muted">Total :</div>
              <div className="">₹&nbsp;1,324.00</div>
            </div> */}
          </div>

          <div className="d-grid">
            <button
              className="btn custom_danger_btn fw-bold"
              type="button"
              onClick={exchange}
            >
              {loading ? (
                <div
                  className="spinner-border text-white load-icon mx-1"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : null}
              {premium ? "Exchange" : "Go Premium"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Exchange;
