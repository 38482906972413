import React, { useEffect, useState } from "react";
import Header from "../Component/Header";
import Sidebar from "../Component/Sidebar";
import SparklineChart from "../Component/SparklineChart";
import { useDispatch, useSelector } from "react-redux";
import { cutAfterDecimal, fetchWalletHandler } from "../utils/actionHandler";
import { Link } from "react-router-dom";
import { couponClaim } from "../utils/apis";
import { toast } from "react-toastify";
const Assets = () => {
  const { assets, total } = useSelector((state) => state.wallet);
  const { user, sessionId, userdata } = useSelector((state) => state.user);
  const usdt = assets.find((it) => it.symbol == "USDT");
  const [search, setSearch] = useState("");
  const [coupanCode, setCode] = useState("");

  const dispatch = useDispatch();
  const data = [
    10, 20, 15, 30, 25, 40, 35, 23, 12, 54, 32, 45, 67, 89, 34, 56, 78,
  ];
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userdata?.mobile) {
      fetchWalletHandler(userdata?.mobile, sessionId, dispatch);
    }
  }, [userdata?.mobile]);

  async function claimCoupan() {
    if(!coupanCode){
      toast.error("Coupon code not provided")
      return
    }
    setLoading(true);
    couponClaim(userdata?.email, userdata?.mobile, sessionId, coupanCode?.toUpperCase())
    .then((res)=>{
      console.log(res,"rEs")
      if (res?.status == 200) {
        fetchWalletHandler(user?.mobile, sessionId, dispatch);
        toast.success(res.message)
      }else{
        toast.error(res.message)
      }
      setLoading(false);

    }).catch((e) => {
      setLoading(false);
      toast.error("Something went wrong!");
      console.log(e, "Error in buyinrx()/BuyINRxform");
    });
  }

  return (
    <>
      <Header />
      <Sidebar />
      <main className="mt-5 pt-5">
        <div className="container-fluid">
          <div className="card mb-3">
            <div className="card-body w-100">
              <div className="">
                <div className="assets_header">
                  <div className="mb-1">
                    Estimated Balance <i className="fas fa-eye"></i>
                  </div>
                  <div className="d-flex gap-3">
                    <Link to="/deposit" className="btn customchnage2 ">
                      Deposit
                    </Link>
                    <Link to="/withdraw" className="btn customchnage2 ">
                      Withdraw
                    </Link>
                    <Link to="/convert" className="btn customchnage2 ">
                      Convert
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="">
                    <span className="fs-3 fw-bold">
                      {total ? cutAfterDecimal(total, 4) : 0}
                    </span>
                    &nbsp;
                    <span className="fst-normal fs-7">INR</span>
                  </div>
                  {/* <div className="text-muted">= ₹ 17,360,96</div> */}
                  <div className="mt-2">
                    {/* <span className="text-muted ">Today’s PnL</span> */}
                    <span className="text-muted ">Total USD</span>
                    <span className="text-secondary">
                      {" "}
                      - $
                      {total ? cutAfterDecimal(total / usdt?.inrx_price, 4) : 0}
                    </span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mt-2 d-flex justify-content-end">
                    <SparklineChart data={data} color="#02FF01" type="area" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card h-100 rounded-lg">
            <div className="card-body">
              <div className="assets_header mb-4">
                <div className="mb-2">
                  <h4 className="fs-5 mb-1">My Assets <span className="text-lgray fs-14">(Wallet view)</span></h4>                 
                </div>
                <div className="mb-2">
                <div className="input-group">
                   
                    <input
                      type="text"
                      className="form-control py-2"
                      placeholder="Enter Reward Code"
                      aria-label="reward"
                      aria-describedby=""
                      value={coupanCode}
                      onChange={(e) => setCode(e.target.value)}
                    />
                    <span className="input-group-text btn custom_btn p-0">
                 {/* <button className="btn btn custom_btn border-0 " onClick={claimCoupan}>Claim</button> */}
                 <button className="btn btn custom_btn border-0" type="button" onClick={claimCoupan}>
                    {loading ? (
                      <div
                        className="spinner-border text-black load-icon mx-1"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : null}
                    Claim
                  </button>
                </span>
                  </div>
                </div>
              </div>

              <div className="table-responsive custom_table">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col" className="text-start">
                      Assets
                      </th>
                      <th scope="col">Amount</th>
                      {/* <th scope="col">Coin Price</th> */}
                      <th scope="col">Today's PnL</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {assets.map((item, i) => {
                      if(item?.symbol !== "USDT")
                      if (
                        item?.symbol
                          ?.toUpperCase()
                          ?.indexOf(search?.toUpperCase()) > -1
                      ) {
                        return (
                          <tr key={i + "myassets"}>
                            <td scope="row">
                              <div className="d-flex gap-1 align-items-center">
                                <div className="img_30">
                                  <img
                                    src={item?.symbol === "INR" ? "/images/rupee-symbol.png": item?.icon}
                                    alt=""
                                    className="img_30"
                                  />
                                </div>
                                <div className="">{item?.symbol}</div>
                              </div>
                            </td>
                            <td>
                              <div className="">
                                {cutAfterDecimal(item?.available, 4)} <br />
                                <em className="fst-normal text-muted">
                                  ₹
                                  {cutAfterDecimal(
                                    Number(item?.available * item?.inrx_price),
                                    4
                                  )}
                                </em>
                              </div>
                            </td>
                            {/* <td>₹ {cutAfterDecimal(item?.inrx_price, 4)}</td> */}
                            <td className={`text-muted `}>
                              {/* {item?.pnl} ( */}
                              <span className={`${
                                item?.pnl_percent?.toString()?.indexOf("-") > -1
                                  ? " text-danger "
                                  : " text-success "
                              }`}>{item?.pnl_percent?.toString()?.indexOf("-") > -1?"":"+"}{cutAfterDecimal(item?.pnl_percent, 3)} %</span>
                              {/* ) */}
                            </td>
                            <td>
                              <Link className="text-green" to="/deposit">
                                Deposit
                              </Link>
                              <div className="verticle_line"></div>
                              <Link className="text-danger" to="/withdraw">
                                Withdraw
                              </Link>
                              <div className="verticle_line"></div>
                              <Link to="/convert" className=" text-green">
                                Convert
                              </Link>
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Assets;
