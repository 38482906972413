import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  login: false,
  user: {},
  userdata: {},
  sessionId: "",
  premium: false,
  conditionsData:{},
};

const userInfoSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setSession: (state, action) => {
      state.sessionId = action.payload;
    },
    setAuthInfo: (state, action) => {
      state.user = action.payload.user;
      state.login = action.payload.login;
    },
    setUserData: (state, action) => {
      state.userdata = action.payload;
    },
    setPremiumStatus: (state, action) => {
      state.premium = action.payload;
    },
    setConditionsData: (state, action) => {
      state.conditionsData = action.payload;

    },
    setLogOut: (state, action) => {
      state.login = false;
      state.user = {};
      state.sessionId = "";
      state.userdata = {};
      state.premium=false;
      state.conditionsData={};
    },
  },
});

export const {
  setLogin,
  setSession,
  setAuthInfo,
  setLogOut,
  setUserData,
  setPremiumStatus,
  setConditionsData,
} = userInfoSlice.actions;
export default userInfoSlice.reducer;
