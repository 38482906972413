import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTheme } from "../redux/themeSlice";
import "../Css/sidebar.css";
import { LuPieChart, LuUserCog } from "react-icons/lu";
import { LuWallet, LuLoader } from "react-icons/lu";
import { FaRegCommentDots } from "react-icons/fa";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { setLogOut } from "../redux/userInfoSlice";
import { logoutHandler } from "../utils/actionHandler";
import { setLoginId } from "../redux/qrLoginSlice";
import { MdOutlineSwapHorizontalCircle } from "react-icons/md";
import { FaRegShareFromSquare, FaUserGear } from "react-icons/fa6";
import { toast } from "react-toastify";
import { BsTwitterX } from "react-icons/bs";
import { RiExchange2Line } from "react-icons/ri";

const Sidebar = () => {
  const theme = useSelector((state) => state.theme.theme);
  const { user, sessionId, userdata } = useSelector((state) => state.user);
  const [loading, setloading] = useState(false);
  const { mobile, userId, email } = user;
  const { kyc } = userdata;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSwitchToggle = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    dispatch(setTheme(newTheme));
  };

  const handleLinkClick = (event) => {
    if (!kyc) {
      event.preventDefault();
      toast.error("Please complete KYC to access this feature.");
    }
  };

  return (
    <>
      <div
        className="offcanvas offcanvas-start sidebar-nav sidebar d-none d-lg-block"
        tabIndex="-1"
        id="sidebar"
      >
        <div className="offcanvas-body px-0 scrollbar">
          <nav className="sidebar_wrapper">
            <ul className="menu-section">
              <NavLink to="/dashboard" className="menu-item">
                <li data-bs-dismiss="offcanvas">
                  <i className="bi bi-grid"></i>
                  Overview
                </li>
              </NavLink>
              <NavLink
                to={kyc ? "/assets" : "/identity-verfication"}
                className="menu-item"
              >
                <li data-bs-dismiss="offcanvas">
                  <LuWallet />
                  Assets
                </li>
              </NavLink>
              <NavLink
                to={kyc ? "/activity" : "/identity-verfication"}
                className={`menu-item ${!kyc ? "disabled" : ""}`}
                onClick={handleLinkClick}
              >
                <li data-bs-dismiss="offcanvas">
                  <LuPieChart />
                  Activity
                </li>
              </NavLink>
              <NavLink
                to={kyc ? "/deposit" : "/identity-verfication"}
                className={`menu-item ${!kyc ? "disabled" : ""}`}
                onClick={handleLinkClick}
              >
                <li data-bs-dismiss="offcanvas">
                  <i className="bi bi-arrow-up-right-circle"></i>
                  Deposit
                </li>
              </NavLink>
              <NavLink
                to={kyc ? "/withdraw" : "/identity-verfication"}
                className={`menu-item ${!kyc ? "disabled" : ""}`}
                onClick={handleLinkClick}
              >
                <li data-bs-dismiss="offcanvas">
                  <i className="bi bi-arrow-down-left-circle"></i>
                  Withdraw
                </li>
              </NavLink>
              <NavLink
                to={kyc ? "/convert" : "/identity-verfication"}
                className={`menu-item ${!kyc ? "disabled" : ""}`}
                onClick={handleLinkClick}
              >
                <li data-bs-dismiss="offcanvas">
                  <MdOutlineSwapHorizontalCircle size={22} />
                  Convert
                </li>
              </NavLink>
              <NavLink
                to={kyc ? "/earn" : "/identity-verfication"}
                className={`menu-item ${!kyc ? "disabled" : ""}`}
                onClick={handleLinkClick}
              >
                <li data-bs-dismiss="offcanvas">
                  <i className="bi bi-coin"></i>
                  Earn
                </li>
              </NavLink>
              <NavLink
                to={kyc ? "/Rewards" : "/identity-verfication"}
                className={`menu-item ${!kyc ? "disabled" : ""}`}
                onClick={handleLinkClick}
              >
                <li data-bs-dismiss="offcanvas">
                  <i className="bi bi-gift"></i>
                  Rewards
                </li>
              </NavLink>
              <NavLink
                to={kyc ? "/referral" : "/identity-verfication"}
                className={`menu-item ${!kyc ? "disabled" : ""}`}
                onClick={handleLinkClick}
              >
                <li data-bs-dismiss="offcanvas">
                  <FaRegShareFromSquare />
                  Referral
                </li>
              </NavLink>
            </ul>

            <ul className="menu-section">
              <NavLink to="/support" className="menu-item">
                <li data-bs-dismiss="offcanvas">
                  <FaRegCommentDots />
                  Support
                </li>
              </NavLink>
              <NavLink to="/setting" className="menu-item">
                <li data-bs-dismiss="offcanvas">
                  <i className="bi bi-sliders2-vertical"></i>
                  Setting
                </li>
              </NavLink>
            </ul>

            <div className="">
              <ul className="menu-section">
                <div
                  className="menu-item logout-btn"
                  data-bs-dismiss="offcanvas"
                  onClick={() => {
                    setloading(true);
                    logoutHandler(
                      mobile ? mobile : email,
                      userId,
                      sessionId,
                      (res) => {
                        setloading(false);
                        if (res) {
                          dispatch(setLogOut());
                          dispatch(setLoginId(""));
                          navigate("/login");
                        }
                      }
                    );
                  }}
                >
                  <li>
                    {loading ? (
                      <div
                        className="spinner-border text-danger load-icon"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <i className="fa-solid fa-right-from-bracket"></i>
                    )}
                    Logout
                  </li>
                </div>
              </ul>
              <div className="theme-wrapper">
                <div className="d-flex gap-3 align-items-center">
                  <div
                    className="form-check-label"
                    htmlFor="flexSwitchCheckDefault"
                  >
                    <i
                      className={
                        theme === "dark"
                          ? "fa-solid fa-sun fs-5"
                          : "fa-solid fa-moon text-secondary fs-5"
                      }
                    />
                  </div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                      onChange={handleSwitchToggle}
                      checked={theme === "dark"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>

      <div className="d-block d-lg-none">
        {/* <button
          class="btn btn-primary"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#mobileMenuBottom"
          aria-controls="mobileMenuBottom"
        >
          Toggle bottom offcanvas
        </button> */}

        <div
          class="offcanvas offcanvas-bottom border-top-0"
          tabindex="-1"
          id="mobileMenuBottom"
          aria-labelledby="mobileMenuBottomLabel"
          style={{ borderTopLeftRadius: "2rem", borderTopRightRadius: "2rem" }}
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="mobileMenuBottomLabel"></h5>
            <a
              // class="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <i class="fa-solid fa-xmark icon-color fs-4"></i>
            </a>
          </div>
          <div
            class="offcanvas-body small"
            style={{
              borderTopLeftRadius: "2rem",
              borderTopRightRadius: "2rem",
            }}
          >
            <div className="mobile_menu_wrapper">
              <Link to="/dashboard" className="mobile_menus">
                <div className="mobile_menu_item" data-bs-dismiss="offcanvas">
                  <div className="menu_iconbg">
                    <i className="bi bi-grid"></i>
                  </div>
                  <div>INRx</div>
                </div>
              </Link>

              <Link
                to={kyc ? "/setting" : "/identity-verfication"}
                className="mobile_menus"
              >
                <div className="mobile_menu_item" data-bs-dismiss="offcanvas">
                  <div className="menu_iconbg">
                    <LuUserCog />
                  </div>
                  <div>KYC</div>
                </div>
              </Link>

              <Link
                to={kyc ? "/assets" : "/identity-verfication"}
                className="mobile_menus"
              >
                <div className="mobile_menu_item" data-bs-dismiss="offcanvas">
                  <div className="menu_iconbg">
                    <LuWallet />
                  </div>
                  <div> Assets</div>
                </div>
              </Link>

              <Link
                to={kyc ? "/deposit_fiat" : "/identity-verfication"}
                className="mobile_menus"
              >
                <div className="mobile_menu_item" data-bs-dismiss="offcanvas">
                  <div className="menu_iconbg">
                    <i className="bi bi-currency-rupee"></i>
                  </div>
                  <div> Deposit </div>
                </div>
              </Link>
              <Link
                to={kyc ? "/deposit" : "/identity-verfication"}
                className="mobile_menus"
              >
                <div className="mobile_menu_item" data-bs-dismiss="offcanvas">
                  <div className="menu_iconbg">
                    <img
                      src={
                        theme === "dark"
                          ? "images/white@72x.png"
                          : "images/inrx-black.png"
                      }
                      alt=""
                      width={"25px"}
                    />
                  </div>
                  <div> Deposit </div>
                </div>
              </Link>

              <Link
                to={kyc ? "/withdraw_fiat" : "/identity-verfication"}
                data-bs-dismiss="offcanvas"
                className="mobile_menus"
              >
                <div className="mobile_menu_item" data-bs-dismiss="offcanvas">
                  <div className="menu_iconbg">
                    <i className="bi bi-currency-rupee"></i>
                  </div>
                  <div>Withdraw </div>
                </div>
              </Link>

              <Link
                to={kyc ? "/withdraw" : "/identity-verfication"}
                data-bs-dismiss="offcanvas"
                className="mobile_menus"
              >
                <div className="mobile_menu_item" data-bs-dismiss="offcanvas">
                  <div className="menu_iconbg">
                    <img
                      src={
                        theme === "dark"
                          ? "images/white@72x.png"
                          : "images/inrx-black.png"
                      }
                      alt=""
                      width={"25px"}
                    />
                  </div>
                  <div>Withdraw </div>
                </div>
              </Link>

              <Link
                to={kyc ? "/convert" : "/identity-verfication"}
                data-bs-dismiss="offcanvas"
                className="mobile_menus"
              >
                <div className="mobile_menu_item" data-bs-dismiss="offcanvas">
                  <div className="menu_iconbg">
                    <RiExchange2Line />
                  </div>
                  <div>Convert</div>
                </div>
              </Link>

              <Link
                to={kyc ? "/earn" : "/identity-verfication"}
                data-bs-dismiss="offcanvas"
                className="mobile_menus"
              >
                <div className="mobile_menu_item" data-bs-dismiss="offcanvas">
                  <div className="menu_iconbg">₹</div>
                  <div>Earning</div>
                </div>
              </Link>

              <Link
                to={kyc ? "/Rewards" : "/identity-verfication"}
                data-bs-dismiss="offcanvas"
                className="mobile_menus"
              >
                <div className="mobile_menu_item" data-bs-dismiss="offcanvas">
                  <div className="menu_iconbg">
                    <i className="bi bi-gift"></i>
                  </div>
                  <div>Rewards</div>
                </div>
              </Link>
              <Link
                to={kyc ? "/referral" : "/identity-verfication"}
                data-bs-dismiss="offcanvas"
                className="mobile_menus"
              >
                <div className="mobile_menu_item" data-bs-dismiss="offcanvas">
                  <div className="menu_iconbg">
                    <FaRegShareFromSquare />
                  </div>
                  <div> Referral</div>
                </div>
              </Link>

              <Link
                to={kyc ? "/activity" : "/identity-verfication"}
                className="mobile_menus"
              >
                <div className="mobile_menu_item" data-bs-dismiss="offcanvas">
                  <div className="menu_iconbg">
                    <LuPieChart />
                  </div>
                  <div> Activity</div>
                </div>
              </Link>
              <Link
                to="/support"
                data-bs-dismiss="offcanvas"
                className="mobile_menus"
              >
                <div className="mobile_menu_item" data-bs-dismiss="offcanvas">
                  <div className="menu_iconbg">
                    <FaRegCommentDots />
                  </div>
                  <div>Support</div>
                </div>
              </Link>
              <Link
                to="/setting"
                data-bs-dismiss="offcanvas"
                className="mobile_menus"
              >
                <div className="mobile_menu_item" data-bs-dismiss="offcanvas">
                  <div className="menu_iconbg">
                    <i className="bi bi-sliders2-vertical"></i>
                  </div>
                  <div>Setting</div>
                </div>
              </Link>

              <a href="https://x.com/inrxfoundation" className="mobile_menus">
                <div className="mobile_menu_item" data-bs-dismiss="offcanvas">
                  <div className="menu_iconbg">
                    <BsTwitterX />
                  </div>
                  <div> Twiiter</div>
                </div>
              </a>
            </div>

            <div className="d-flex justify-content-between align-items-center mt-3 px-4">
              <div className="d-flex gap-3 align-items-center">
                <div
                  className="form-check-label"
                  htmlFor="flexSwitchCheckDefault"
                >
                  <i
                    className={
                      theme === "dark"
                        ? "fa-solid fa-sun fs-5"
                        : "fa-solid fa-moon text-secondary fs-5"
                    }
                  />
                </div>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                    onChange={handleSwitchToggle}
                    checked={theme === "dark"}
                  />
                </div>
              </div>

              <div>
                <Link className="mobile_menus">
                  <div
                    className="mobile_menu_item"
                    data-bs-dismiss="offcanvas"
                    onClick={() => {
                      setloading(true);
                      logoutHandler(
                        mobile ? mobile : email,
                        userId,
                        sessionId,
                        (res) => {
                          setloading(false);
                          if (res) {
                            dispatch(setLogOut());
                            dispatch(setLoginId(""));
                            navigate("/login");
                          }
                        }
                      );
                    }}
                  >
                    <div className="">
                      {loading ? (
                        <div
                          className="spinner-border text-danger load-icon"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <>
                          <div className="d-flex gap-2 align-items-center">
                            <div>
                              {" "}
                              <i className="fa-solid fa-right-from-bracket text-danger fs-4"></i>
                            </div>
                            <div className="fs-14 mb-1">Logout</div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
