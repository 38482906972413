import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

const ApexChart = () => {
  const theme = useSelector((state) => state.theme.theme);

  // Define theme colors based on the theme
  const axisColor = theme === 'dark' ? '#fff' : '#000'; 
  const axisLabelColor = theme === 'dark' ? '#bbb' : '#333'; 
  const tooltipBackground = theme === 'dark' ? '#333' : '#fff'; 
  const tooltipColor = theme === 'dark' ? '#fff' : '#000'; 
  const legendLabelsColor = theme === 'dark' ? '#ccc' : '#333';
  const series = [
    {
      name: 'Collateral',
      data: [
        { x: new Date('2024-08-19T00:00:00.000Z').getTime(), y: 31 },
        { x: new Date('2024-08-19T01:10:00.000Z').getTime(), y: 40 },
        { x: new Date('2024-08-19T02:30:00.000Z').getTime(), y: 28 },
        { x: new Date('2024-08-19T03:30:00.000Z').getTime(), y: 51 },
        { x: new Date('2024-08-19T04:30:00.000Z').getTime(), y: 82 },
        { x: new Date('2024-08-19T05:10:00.000Z').getTime(), y: 90 },
        { x: new Date('2024-08-19T06:30:00.000Z').getTime(), y: 100 }
      ]
    },
    {
      name: 'Acquisition',
      data: [
        { x: new Date('2024-08-19T00:00:00.000Z').getTime(), y: 11 },
        { x: new Date('2024-08-19T01:30:00.000Z').getTime(), y: 32 },
        { x: new Date('2024-08-19T02:30:00.000Z').getTime(), y: 45 },
        { x: new Date('2024-08-19T03:10:00.000Z').getTime(), y: 32 },
        { x: new Date('2024-08-19T04:30:00.000Z').getTime(), y: 34 },
        { x: new Date('2024-08-19T05:30:00.000Z').getTime(), y: 52 },
        { x: new Date('2024-08-19T06:30:00.000Z').getTime(), y: 41 }
      ]
    }
  ];

  const options = {
    chart: {
      height: 350,
      type: 'area'
    },

    // colors: ['#CC95FB', '#4DE396'], // Colors for series1 and series2
    markers: {
      size: 0,
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    title: {
      text: 'Collateral vs Acquisition',
      align: 'left',
      style: {
        color: theme === 'dark' ? '#fff' : '#000' 
      }
    },
    xaxis: {
      type: 'datetime',
      labels: {
        style: {
          colors: axisLabelColor, 
        },
        formatter: function (value) {
          return dayjs(value).format('DD MMM HH:mm'); 
        }
      },
      axisBorder: {
        show: false,
        color: axisColor, 
      },
      tickAmount: 6 
    },
    yaxis: {
      labels: {
        style: {
          colors: axisLabelColor, 
        }
      },
      axisBorder: {
        show: false,
        color: axisColor, 
      },
      axisTicks: {
        show: false, 
      },
      grid: {
        show: false, 
      }
    },
    tooltip: {
      theme: theme, 
      style: {
        background: tooltipBackground, 
        color: tooltipColor 
      }
    },
    legend: {
      labels: {
        colors: legendLabelsColor 
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100]
      }
    }
  };

  return (
    <div className='card'>
      <div className="card-body">
        <div id="chart">
          <ReactApexChart options={options} series={series} type="area" height={400} />
        </div>
      </div>
    </div>
  );
};

export default ApexChart;
