import React from "react";

const Fund_Password = () => {
  return (
    <>
      <div className="model_btn">
        <button
          type="button"
          className="button-outline "
          data-bs-toggle="modal"
          data-bs-target="#fundxampleModal"
        >
          Setting
        </button>
      </div>

      <div
        className="modal fade"
        id="fundxampleModal"
        tabIndex="-1"
        aria-labelledby="fundxampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-3">
            <div className="modal-header border-0">
              <h1 className="modal-title fs-6" id="fundxampleModalLabel">
                Link Fund Password
              </h1>
              <i
                className="fa-solid fa-xmark icon-color"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div>
                <div className="mb-3">
                  <label
                    htmlFor="exampleInputEmail5"
                    className="form-label mob-font text-muted"
                  >
                    Fund Password
                  </label>
                  <input
                    type="password"
                    className="form-control "
                    id="exampleInputEmail5"
                    aria-describedby="emailHelp"
                    placeholder=" Password"
                  />
                  <div
                    id="emailHelp"
                    className="form-text lh-2"
                    style={{ fontSize: "12px" }}
                  >
                    Password must contain 8-30 characters, including at least
                    one uppercase letter, one lowercase letter and a number.
                  </div>
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="exampleInputEmail6"
                    className="form-label mob-font text-muted"
                  >
                    Confirm Fund Password
                  </label>
                  <input
                    type="password"
                    className="form-control "
                    id="exampleInputEmail6"
                    aria-describedby="emailHelp"
                    placeholder="Confirm Password"
                  />
                </div>

                <div className="d-grid mb-3">
                  <button
                    className="custom_btn"
                    type="button"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Fund_Password;
