import React, { useState } from "react";
import Header from "../Component/Header";
import Sidebar from "../Component/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { goPremiumHandler, logoutHandler } from "../utils/actionHandler";
import { Link, useNavigate } from "react-router-dom";
import { setLogOut } from "../redux/userInfoSlice";
import { setLoginId } from "../redux/qrLoginSlice";
import { FaCheckCircle } from "react-icons/fa";
import Change_password from "./Change_password";

const Account = () => {
  const { user, sessionId, userdata, premium } = useSelector(
    (state) => state.user
  );
  const [loading, setloading] = useState(false);
  const [Loading, setLoading] = useState(false);
  const { mobile, userId, email } = user;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // console.log(user,'user')
  return (
    <>
      <Header />
      <Sidebar />
      <main className="account_main">
        <div className="container-fluid">
          <img src="images/account_top.svg" alt="" className="top_img" />
          <img src="images/account_img1.svg" alt="" className="top_img1" />

          <div className="">
            {/* <h1 className="">My Account</h1> */}
            <div className="main-section d-flex justify-content-center mt-5">
              <div className="col-xl-4 col-lg-8 col-md-8 col-sm-10 col-12">
                <div className="flex_row_center gap-4">
                  <div className="m-mob-auto">
                    <img
                      src={
                        userdata?.profile_image?.indexOf("https") > -1
                          ? userdata?.profile_image
                          : userdata?.profile_image
                          ? `data:image/jpeg;base64,${userdata?.profile_image}`
                          : "/images/user.svg"
                      }
                      style={{
                        height: "135px",
                        width: "135px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                      alt="user"
                    />
                  </div>
                  <div className="m-mob-auto">
                    <h3>{user ? user?.name : "Jade Moe"}</h3>
                    <div className="accout_div">
                      <p className=" mb-0" style={{ padding: "2px 20px" }}>
                        {" "}
                        {premium ? "Premium Accounts" : "Individual Accounts"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card mt-3 mb-4 border-0">
                  <div className="card-body">
                    <div className="">
                      <p className="text-muted mb-0">Trust Name</p>
                      <strong className="fs-5">
                        {user ? user?.name : "Jade Moe"}
                      </strong>
                    </div>
                    {/* <div className="mt-4">
                      <p className="text-muted mb-0">Phone Number</p>
                      <strong className="fs-5">
                        {user ? user.mobile : "7985****81"}
                      </strong>
                    </div> */}

                    <div className="mt-4">
                      {user && user.mobile && (
                        <>
                          <p className="text-muted mb-0">Phone Number</p>
                          <strong className="fs-5">{user.mobile}</strong>
                        </>
                      )}
                    </div>

                    <div className=" d-flex justify-content-between align-items-center mt-4">
                      <div>
                        <p className="text-muted mb-0">Password</p>
                        <strong className="fs-5">*********</strong>
                      </div>
                      <div>
                      <Change_password/>
                        {/* <button type="button" className="btn customchnage2">
                          Change
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card border-0">
                  <div className="card-body">
                    <div className=" d-flex justify-content-between align-items-center ">
                      <div>
                        <p className="text-muted mb-0">Subscription</p>
                        <strong className="fs-5">2499K INRx</strong>
                      </div>
                      <div>
                        <button
                          type="button"
                          className={`btn ${
                            premium ? "bg-success text-white" : "custombtn2"
                          }`}
                          onClick={() => {
                            if (!premium) {
                              setLoading(true);
                              goPremiumHandler(
                                userdata?.mobile,
                                sessionId,
                                dispatch,
                                setLoading
                              );
                            }
                          }}
                          style={{ alignItems: "center" }}
                        >
                          {Loading ? (
                            <div
                              className="spinner-border text-white load-icon mx-1"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : null}
                          {premium ? <FaCheckCircle size={18} /> : null}
                          {premium ? " Subscribed" : " Upgrade to Premium"}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card-header border-0 text-center">
                    <Link to="/identity-verfication" className="text-warning">
                      See premium benefit
                    </Link>
                  </div>
                </div>
                <div className="text-center mt-4">
                  <button
                    type="button"
                    className="custom_btn"
                    onClick={() => {
                      // console.log("clicked");
                      setloading(true);
                      logoutHandler(
                        mobile ? mobile : email,
                        userId,
                        sessionId,
                        (res) => {
                          setloading(false);
                          if (res) {
                            dispatch(setLogOut());
                            dispatch(setLoginId(""));
                            navigate("/login");
                          }
                        }
                      );
                    }}
                  >
                    {loading ? (
                      <div
                        className="spinner-border text-danger load-icon mx-1"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <i className="fa-solid fa-right-from-bracket mx-1"></i>
                    )}
                    Sign Out
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="top_img2">
              <img src="images/account_top2.svg" alt="" />
            </div>
            <div className="top_img3">
              <img src="images/account2.svg" alt="" />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Account;
