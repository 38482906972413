import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  assets: [], // Default theme
  wallets: [],
  total: 0,
  totaldeposit:0,
  prices: [
    { symbol: "USDT", price: 0 },
    { symbol: "USDC", price: 0 },
  ],
  stakes: [],
  allstakes: {
    INRx: {
      symbol: '',
      tokenAmount: 0,
      stakeAmount: 0,
      claimAmount: 0,
      totalAmount: 0,
      availableAmount: 0,
      profitAmount:0,
      startTimestamp: 0,
      endTimestamp: 0,
      isClaimed: false,
      isStakeCompleted: false,
      stakePrice: 0,
      count: 0,
      apyPer:0 ,
      isreferrerStake:false,
      referrerUserId:""
    },
    INR: {
      symbol: '',
      tokenAmount: 0,
      stakeAmount: 0,
      claimAmount: 0,
      totalAmount: 0,
      availableAmount: 0,
      startTimestamp: 0,
      endTimestamp: 0,
      isClaimed: false,
      isStakeCompleted: false,
      stakePrice: 0,
      count: 0,
    },
    USDT: {
      symbol: '',
      tokenAmount: 0,
      stakeAmount: 0,
      claimAmount: 0,
      totalAmount: 0,
      availableAmount: 0,
      startTimestamp: 0,
      endTimestamp: 0,
      isClaimed: false,
      isStakeCompleted: false,
      stakePrice: 0,
      count: 0,
    },
    USDC: {
      symbol: '',
      tokenAmount: 0,
      stakeAmount: 0,
      claimAmount: 0,
      totalAmount: 0,
      availableAmount: 0,
      startTimestamp: 0,
      endTimestamp: 0,
      isClaimed: false,
      isStakeCompleted: false,
      stakePrice: 0,
      count: 0,
    },
  },
  stakesRef: [],
  allRefstakes: {
    INRx: {
      symbol: '',
      tokenAmount: 0,
      stakeAmount: 0,
      claimAmount: 0,
      totalAmount: 0,
      availableAmount: 0,
      totalAmount: 0,
      startTimestamp: 0,
      endTimestamp: 0,
      isClaimed: false,
      isStakeCompleted: false,
      stakePrice: 0,
      count: 0,
      apyPer:0 ,
      isreferrerStake:false,
      referrerUserId:""
    },
    INR: {
      symbol: '',
      tokenAmount: 0,
      stakeAmount: 0,
      claimAmount: 0,
      totalAmount: 0,
      availableAmount: 0,
      startTimestamp: 0,
      endTimestamp: 0,
      isClaimed: false,
      isStakeCompleted: false,
      stakePrice: 0,
      count: 0,
    },
    USDT: {
      symbol: '',
      tokenAmount: 0,
      stakeAmount: 0,
      claimAmount: 0,
      totalAmount: 0,
      availableAmount: 0,
      startTimestamp: 0,
      endTimestamp: 0,
      isClaimed: false,
      isStakeCompleted: false,
      stakePrice: 0,
      count: 0,
    },
    USDC: {
      symbol: '',
      tokenAmount: 0,
      stakeAmount: 0,
      claimAmount: 0,
      totalAmount: 0,
      availableAmount: 0,
      startTimestamp: 0,
      endTimestamp: 0,
      isClaimed: false,
      isStakeCompleted: false,
      stakePrice: 0,
      count: 0,
    },
  },
};

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setWalletData(state, action) {
      state.assets = action.payload.assets;
      state.wallets = action.payload.wallets;
      state.prices = action.payload.prices;
      state.total = action.payload.total;
      state.totaldeposit = action.payload.totaldeposit;
    },
    setStakes: (state, action) => {
      state.stakes = action.payload.stakes;
      state.allstakes = action.payload.allstakes;
    },
    setRefStakes: (state, action) => {
      state.stakesRef = action.payload.stakesRef;
      state.allRefstakes = action.payload.allRefstakes;
    },
  },
});

export const { setWalletData, setStakes, setRefStakes} = walletSlice.actions;
export default walletSlice.reducer;
