import React, { useEffect, useState } from "react";
import Header from "../Component/Header";
import Sidebar from "../Component/Sidebar";
import "../Css/Stepper.css";
import { Link, UNSAFE_LocationContext } from "react-router-dom";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import { depositHistory, withdrawDetails } from "../utils/apis";
import { FaRegCheckCircle, FaSortDown, FaSortUp } from "react-icons/fa";
import { cutAfterDecimal } from "../utils/actionHandler";
import { PiSealWarningLight } from "react-icons/pi";
import { MdError } from "react-icons/md";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { capitalizeFirstLetter } from "../utils/helperFunctions";

const coins = [
  {
    symbol: "INRx",
    name: "Indian stable currency",
    icon: "images/favicon.svg",
  },
  // {
  //   symbol: "USDT",
  //   name: "Tether",
  //   icon: "images/usdt.svg",
  // },
];

const networks = [
  // {
  //   nativeToken: "ETH (ERC20)",
  //   chain: "Ethereum",
  // },
  {
    nativeToken: "BNB (BEP20)",
    chain: "Binance Smart Chain",
  },
  // {
  //   nativeToken: "TRX (TRC20)",
  //   chain: "Tron",
  // },
];

const Deposit = () => {
  const theme = useSelector((state) => state.theme.theme);
  const { wallets, assets } = useSelector((state) => state.wallet);
  const { user, sessionId } = useSelector((state) => state.user);
  const [isverified, setIsverified] = useState(null);
  const [depositlist, setdepositlist] = useState([]);
  const [coinSelected, setCoinSelected] = useState(false);
  const [networkSelected, setNetworkSelected] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [coinindex, setCoinindex] = useState(-1);
  const [chainindex, setChainindex] = useState(-1);
  const [depositDetailsData, setDepositDetails] = useState();
  const token = coins[coinindex]?.symbol;
  const chain = networks[chainindex]?.chain != "Tron" ? "Ethereum" : "Tron";
  const activewallet = wallets?.find((it) => it.blockchain == chain);
  const asset = assets.find((it) => it?.symbol == token?.toUpperCase());
  const activeTokenContract = asset?.contracts?.find(
    (it) => it?.blockchain?.toLowerCase() == chain?.toLowerCase()
  );
  const [tooltipText, setTooltipText] = useState("Copy");

  // console.log(activewallet,'activewallet')
  const handleCollapseToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleCoinSelect = (cindex) => {
    setCoinSelected(true);
    setIsverified(0);
    setCoinindex(cindex);
  };

  const handleNetworkSelect = (nindex) => {
    setNetworkSelected(true);
    setIsverified(1);
    setChainindex(nindex);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(
      activewallet?.address ? activewallet?.address : "0x00000000000000000000000"
    );
    setTooltipText("Copied!");

    // Reset tooltip text after 1.5 seconds
    setTimeout(() => {
      setTooltipText("Copy");
    }, 1500);
  };

  useEffect(() => {
    if (user?.mobile && sessionId) {
      depositHistory(user.mobile, sessionId,"Ethereum")
        .then((res) => {
          if (res?.status == 200) {
            setdepositlist(res?.data);
          }
        })
        .catch((e) => {
          console.log(e, "Error in depositHistory:deposit page");
        });
    }
  }, [user, sessionId]);

  useEffect(()=>{
    withdrawDetails().then((res) => {
      if (res?.status == 200) {
        setDepositDetails(res?.data);
      }
    })
    .catch((e) => {
      console.log(e, "Error in setWithdrawList:withdraw page");
    });
  },[])

  return (
    <>
      <Header />
      <Sidebar />
      <main className="mt-5 pt-5 deposit_main">
        <div className="container-fluid">
          {/* first title  row*/}
          <div className="d-flex justify-content-between align-items-center mb-4">
            <div>
              <h4 className="heading">Deposit INRx</h4>
            </div>
            <div>
              <Link to="/deposit_fiat" className="custom_btn_outline fs-14">
                Deposit INR
                <i className="fa-solid fa-arrow-right-long ms-1"></i>
              </Link>
            </div>
          </div>

          {/* select token and network row */}
          <div className="row">
            {/* select network column */}
            <div className="col-lg-5 mb-3">
              <div className="accordion verification_accrodion">
                <div className="timeline_container">
                  <div className="hide_on_mobile">
                    <div
                      className={
                        coinSelected
                          ? "accor_timeline_no_check"
                          : "accor_timeline_no"
                      }
                    >
                      1
                    </div>
                    <div
                      className={
                        coinSelected
                          ? "accor_timeline_line_check"
                          : "accor_timeline_line"
                      }
                    ></div>
                  </div>
                  <div className="accordion-item border-0">
                    <div>
                      <h2 id="headingOne">
                        <button
                          className="border-0 bg-transparent text-accordion fw-bold h6 mb-0"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Select Coin
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                      >
                        <div className="">
                          <div className="mb-3">
                            <div className="select_box asset_dropdown_wrapper">
                              {/* select coin button */}
                              <div
                                className="asset_dropdown dropdown-toggle"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {coins[coinindex]?.symbol ? <div className="d-flex align-items-center">
                                  <img
                                    src={coins[coinindex]?.icon}
                                    alt="coin symbol"
                                    className="coin_img me-2"
                                  />
                                  <div className="product_name">
                                    {coins[coinindex]?.symbol} &nbsp;
                                    <span className="unit text-lgray">
                                      {coins[coinindex]?.name}
                                    </span>
                                  </div>
                                </div>: <button
                                className="border-0 bg-transparent text-accordion fs-12 mb-0"
                                type="button"
                              >
                                Select Coin
                              </button>}
                                {/* <div>
                                  <img
                                    src={coins[coinindex]?.icon}
                                    className="arrow_img"
                                    alt="arrow-icon"
                                  />
                                </div> */}
                              </div>

                              {/* select coin dropdown */}
                              <ul
                                className="dropdown-menu asset_dropdown_menu"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                <div>
                                  <div className="custom_scrollbar height250">
                                    {coins.map((item, i) => {
                                      return (
                                        <div
                                          className="select_options"
                                          onClick={() => handleCoinSelect(i)}
                                          key={"select_options" + i}
                                        >
                                          <div>
                                            <img
                                              src={item?.icon}
                                              alt="coin symbol"
                                              className="market_coin_img"
                                            />
                                          </div>
                                          <div className="product_name">
                                            <div className="mb-0 fw-bold">
                                              {item?.symbol}
                                            </div>
                                            <div className="text-lgray fs-12">
                                              {item?.symbol}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="timeline_container">
                <div className="hide_on_mobile">
                    <div
                      className={
                        networkSelected
                          ? "accor_timeline_no_check"
                          : "accor_timeline_no"
                      }
                    >
                      2
                    </div>
                    <div
                      className={
                        networkSelected
                          ? "accor_timeline_line_check"
                          : "accor_timeline_line"
                      }
                    ></div>
                  </div>
                  <div className="flex-grow-1">
                    <h2 id="headingTwo">
                      <button
                        className={`border-0 bg-transparent text-accordion fw-bold h6 mb-0 ${
                          coinSelected ? "" : "collapsed"
                        }`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                        disabled={!coinSelected}
                      >
                        Select Network
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className={`accordion-collapse collapse ${
                        coinSelected ? "show" : ""
                      }`}
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="">
                        <div className="mb-3">
                          <div className="asset_dropdown_wrapper select_box">
                            <div
                              className="asset_dropdown dropdown-toggle"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                             {networks[chainindex]?.chain ? <div className="product_name">
                                {networks[chainindex]?.nativeToken}{" "}
                                {networks[chainindex]?.chain}
                              </div>: <button
                              className={`border-0 bg-transparent text-accordion fs-12 mb-0 ${
                                coinSelected ? "" : "collapsed"
                              }`}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                              disabled={!coinSelected}
                            >
                              Select Network
                            </button>}
                              <div>
                                <img
                                  src="https://assets.staticimg.com/kucoin-main-web/2.0.20/svg/arrow.267b17d2.svg"
                                  className="arrow_img"
                                  alt="arrow-icon"
                                />
                              </div>
                            </div>
                            <ul
                              className="dropdown-menu asset_dropdown_menu"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <div>
                                <div className="alert alert-warning">
                                  <div className="d-flex">
                                    <div>
                                      <i className="fa fa-exclamation-circle me-2 text-warning"></i>
                                    </div>
                                    <div className="text-lgray mob-font">
                                      Ensure that the selected deposit network
                                      is the same as the withdrawal network.
                                      Otherwise, your assets could be lost.
                                    </div>
                                  </div>
                                </div> 
                                {/* network dropdown */}
                                <div className="custom_scrollbar height275">
                                  {networks.map((item, i) => {
                                    return (
                                      <div
                                        className="select_options"
                                        onClick={() => handleNetworkSelect(i)}
                                        key={i + "networks-list"}
                                      >
                                        <div className="product_name">
                                          <div className="mb-0 fw-bold">
                                            {item?.nativeToken}
                                          </div>
                                          <div className="text-lgray fs-12">
                                            {item?.chain}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="timeline_container">
                <div className="hide_on_mobile">
                    <div
                      className={
                        networkSelected
                          ? "accor_timeline_no_check"
                          : "accor_timeline_no"
                      }
                    >
                      <i className="fa-solid">3</i>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <h2>
                      <button
                        className={`border-0 bg-transparent text-accordion fw-bold h6 mb-0 ${
                          networkSelected ? "" : "collapsed"
                        }`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                        disabled={!networkSelected}
                      >
                        Deposit Address
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className={`accordion-collapse collapse ${
                        networkSelected ? "show" : ""
                      }`}
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body px-0">
                        <div className="card">
                          <div className="row  align-items-center ">
                            <div className="col-md-4">
                              <div
                                style={{
                                  margin: "10px auto",
                                  maxWidth: 80,
                                  width: "100%",
                                }}
                              >
                                <QRCode
                                  style={{
                                    height: "100px",
                                    maxWidth: "100%",
                                    width: "100%",
                                  }}
                                  value={
                                    activewallet?.address
                                      ? activewallet?.address
                                      : "testing"
                                  }
                                  viewBox={`0 0 256 256`}
                                  bgColor={
                                    theme === "dark"
                                      ? "transparent"
                                      : "transparent"
                                  }
                                  fgColor={theme === "dark" ? "#fff" : "#000"}
                                />
                              </div>
                            </div>
                            <div className="col-md-8">
                              <div className="card-body">
                                <h5 className="card-title text-lgray fs-14">                                  
                                  Wallet Address
                                </h5>
                                <div className="d-flex justify-content-between">
                                  <p
                                    className="card-text text-break fw-semibold"
                                    // style={{ width: "336px" }}
                                  >
                                    {activewallet?.address
                                      ? activewallet?.address
                                      : "0x00000000000000000000000"}
                                  </p>
                                  <p className="">
                                  <Tooltip title={tooltipText}>
                                  <i className="fa-regular fa-copy ms-1" onClick={handleCopy}></i>
                                </Tooltip>
                                  </p>
                                </div>
                                <p className="text-lgray mb-0">
                                  <span>
                                    Confirm that your network is{" "}
                                    <span className="text-green">
                                      {networks[chainindex]?.chain}
                                    </span>
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex_row mt-4">
                          <div className="text-lgray">
                            Contract Address
                          </div>
                          <div className="fw-semibold text-break">
                            {activeTokenContract?.contract
                              ? activeTokenContract?.contract
                              : "0x7083609fce4d1d8dc0c979aab8c869ea2c873402"}
                            &nbsp; &nbsp;
                            <i className="fa-regular fa-copy text-lgray"></i>
                          </div>
                        </div>
                        {coins[coinindex]?.symbol?.toUpperCase() == "INRX" ? (
                          <div className="flex_row mt-1">
                            <div className=" text-lgray">
                              Minimum Deposit Amount
                            </div>
                            <div className="fw-semibold ">{depositDetailsData?.minimum_deposit_inrx} INRx</div>
                          </div>
                        ) : null}
                        <div className="mt-3">
                        
                          <div className="collapse" id="collapseExample">
                            <div className="card card-body table-responsive">
                           
                                  <div className="flex_row mb-2">
                                    <div
                                     
                                      className="fw-normal text-lgray"
                                    >
                                      Wallet Selected
                                    </div>
                                    <div className="fw-semibold "> Web3</div>
                                  </div>
                                  <div className="flex_row mb-2">
                                    <div
                                     
                                      className="fw-normal text-lgray"
                                    >
                                      Expected Arrival
                                    </div>
                                    <div className=" fw-semibold">
                                      After one network confirmations
                                    </div>
                                  </div>
                                  <div className="flex_row mb-2">
                                    <div
                                     
                                      className="fw-normal text-lgray"
                                    >
                                      Minimum Unblock Confirmation
                                    </div>
                                    <div className="fw-semibold ">
                                      After one network confirmations
                                    </div>
                                  </div>
                              
                              <hr />
                              <div className="mob-font">
                                Deposit via Smart Contract are not supported.
                                {/* with the exception of ETH via ERC20, Arbitrum &
                                O optimism network or BNB via BSC network */}
                              </div>
                            </div>
                          </div>
                          <p className="text-center  mt-3">
                            <a
                              className="text-lgray text-center"
                              data-bs-toggle="collapse"
                              href="#collapseExample"
                              role="button"
                              aria-expanded={!isCollapsed}
                              aria-controls="collapseExample"
                              onClick={handleCollapseToggle}
                            >
                              {isCollapsed ? "Read more" : "Read less"}
                              &nbsp;
                              <i
                                className={`fas fa-chevron-${
                                  isCollapsed ? "down" : "up"
                                }`}
                                style={{ fontSize: "10px" }}
                              ></i>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3"></div>

            {/* FAQ's Column */}
            <div className="col-lg-4 mb-3">
              <div className="card">
                <div className="card-body">
                  <h5>FAQs</h5>
                  <hr />

                  {/* <h6 className="flex_row gap-3">
                <div>
                  <img src="images/faq_img1.svg" alt="" />
                </div>
                <div>
                  How to deposit crypto? <br />
                  <small className="text-lgray">4:10</small>
                </div>
              </h6> */}

                  <ul className="faqList">
                    <li>
                      <Link> How to Deposit Crypto Step-by-step Guide</Link>
                    </li>
                    <hr />
                    <li>
                      <Link> Deposit hasn't arrived?</Link>
                    </li>
                    <hr />
                    <li>
                      <Link> Deposit & Withdrawal Start query</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* deposit history table*/}
          <div className="row">
            <div className="col-lg-12 mt-5">
              <div className="">
                <h4 className=""> Deposit History </h4>
              </div>
            </div>

            <div className="d-lg-block d-none mt-3">
              {depositlist?.length == 0 || !depositlist ? (
                <div className="card">
                  <div className="card-body">
                    <div colSpan={6} className="text-center">
                      <img src="images/no-data.png" className="no-data-found" />
                    </div>
                  </div>
                </div>
              ) : (
                depositlist.map((item, i) => {
                  const currenttime = Math.floor(new Date().getTime() / 1000);
                  {/* const status = !item?.success ? "Pending" : "Success"; */}
                  return (
                    <>
                      <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                          <h2 class="accordion-header ">
                            <button
                              className="accordion-button collapsed fs-14 d-flex justify-content-between"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#accordionDetails${i}`}
                              aria-expanded="true"
                              aria-controls={`accordionDetails${i}`}
                            >
                              <div className="d-flex justify-content-between w-75">
                                <div className="d-flex flex-column text-start">
                                  <span className="fw-bold">
                                    {item?.symbol}
                                  </span>
                                  <span>
                                  {/* {item?.timestamp ?new Date(item?.timestamp).toLocaleString() :new Date(item?.createdAt).toLocaleString()} */}
                                  </span>
                                </div>
                                <div className="d-flex flex-column me-5">
                                  <span className="fw-bold fs-14">
                                    {cutAfterDecimal(item?.amount, 4)}
                                  </span>
                                  {/* <span
                                    className={`${
                                      item?.type === "Rupees"
                                        ? status === "Success"
                                          ? "text-green"
                                          : status === "Pending"
                                          ? "text-warning"
                                          : "text-danger"
                                        : "text-green"
                                    }`}
                                  >
                                    {item?.type === "Rupees"
                                      ? status
                                      : "Success"}
                                  </span> */}
                                </div>
                                <div className="d-flex flex-column me-5">
                                  <span className="fw-bold fs-14">
                                  {item?.blockchain === "bep20"
                                      ? "Binance"
                                      : item?.blockchain === "erc20"
                                      ? "Ethereum"
                                      :item?.blockchain === "matic20"
                                      ? "Polygon":"Tron"}
                                  </span>
                                  <span className="">
                                  </span>
                                </div>
                                <div className="d-flex flex-column me-5">
                                  <span className="fw-bold fs-14">
                                  {item?.hash?.slice(0,6) }...{item?.hash?.slice(-9)}
                                  </span>
                                  <span className="">
                                  </span>
                                </div>
                                <div className="d-flex flex-column me-5">
                                  <span className="fw-bold fs-14">
                                  {item?.timestamp ?new Date(item?.timestamp).toLocaleString() :new Date(item?.createdAt).toLocaleString()}
                                  </span>
                                  <span className="">
                                  </span>
                                </div>
                              </div>
                            </button>
                          </h2>
                          <div
                            id={`accordionDetails${i}`}
                            class="accordion-collapse collapse"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <div className="text-center my-4">
                                Quantity
                                <h4 className="fw-bold">
                                  {item?.amount} {item?.symbol}
                                </h4>
                                <p
                                  className={`text-center ${
                                    item?.status === "approve"
                                        ? "text-green"
                                        : item?.status === "pending"
                                        ? "text-warning"
                                        : "text-danger"
                                  }`}
                                >
                                  {item?.type == "Rupees" ? (
                                    item?.status === "approve" ? (
                                      <FaRegCheckCircle className="me-1" />
                                    ) : item?.status === "pending" ? (
                                      <PiSealWarningLight className="me-1" />
                                    ) : (
                                      <MdError className="me-1" />
                                    )
                                  ) : (
                                    <FaRegCheckCircle className="" />
                                  )}
                                  {item?.type == "Rupees"
                                    ? item?.status === "approve"
                                      ? "Succeeded"
                                      : item?.status === "pending"
                                      ? "Pending"
                                      : "Failed"
                                    : "Succeeded"}
                                </p>
                                {/* <p className="text-warning"><PiSealWarningLight className="" /> Pending</p> */}
                              </div>

                              <div className="d-flex justify-content-between mb-2">
                                <div className="text-start">
                                  <p className="mb-0 text-lgray">
                                    Deposit Account
                                  </p>
                                </div>
                                <div className="text-end">
                                  <p className="mb-0">Funding Account</p>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between mb-2">
                                <div className="text-start">
                                  <p className="mb-0 text-lgray">Chain Type</p>
                                </div>
                                <div className="text-end">
                                  <p className="mb-0 pointer"> 
                                  {item?.blockchain === "bep20"
                                      ? "Binance"
                                      : item?.blockchain === "erc20"
                                      ? "Ethereum"
                                      :item?.blockchain === "matic20"
                                      ? "Polygon":"Tron"} </p>
                                </div>
                              </div>

                              <div className="d-flex justify-content-between mb-2">
                                <div className="text-start">
                                  <p className="mb-0 text-lgray">Time</p>
                                </div>
                                <div className="text-end">
                                  <p className="mb-0">
                                    {item?.timestamp ?new Date(item?.timestamp).toLocaleString() :new Date(item?.createdAt).toLocaleString()}
                                  </p>
                                </div>
                              </div>

                              <div className="d-flex justify-content-between mb-2">
                                <div className="text-start">
                                  <p className="mb-0 text-lgray">
                                    Deposit Address
                                  </p>
                                </div>
                                <div className="text-end">
                                  <p className="mb-0">{item?.senderAddress ? item?.senderAddress : "0x00000000"}</p>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between mb-2">
                                <div className="text-start">
                                  <p className="mb-0 text-lgray">
                                    Transaction Hash
                                  </p>
                                </div>
                                <div className="text-end">
                                  <p className="mb-0"><a className="nav-link p-0" href={`https://bscscan.com/tx/${item?.hash}`} target="_blank">{item?.hash} </a></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })
              )}
            </div>

            <div className=" d-lg-none d-block mt-3">
              {depositlist?.length == 0 || !depositlist ? (
                <div className="card">
                  <div className="card-body">
                    <div colSpan={6} className="text-center">
                      <img src="images/no-data.png" className="no-data-found" />
                    </div>
                  </div>
                </div>
              ) : (
                depositlist.map((item, i) => {
                  console.log(item, "item");
                  const currenttime = Math.floor(new Date().getTime() / 1000);
                  {/* const status = !item?.success ? "Pending" : "Success"; */}
                  return (
                    <>
                      <div
                        data-bs-toggle="offcanvas"
                        // data-bs-target="#depositDetails"

                        data-bs-target={`#depositDetails${i}`}
                      >
                        <div key={i + "DepsoitList"}>
                          <div className="card mb-2">
                            <div className="card-body p-2">
                              <div className="d-flex justify-content-between">
                                <div className="text-start">
                                  <span className="fw-bold">
                                    {item?.symbol}
                                  </span>
                                </div>
                                <div className="text-end">
                                  <span className="fw-bold">
                                    {" "}
                                    {cutAfterDecimal(item?.amount, 4)}
                                  </span>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between">
                                <div className="text-end">
                                  {/* {item?.type == "Rupees"
                                  ? item?.hash
                                  : item?.address} */}
                                  {item?.timestamp ?new Date(item?.timestamp).toLocaleString() :new Date(item?.createdAt).toLocaleString()}
                                </div>
                                <div
                                  className={`text-end ${
                                    item?.status === "approve"
                                        ? "text-green"
                                        : item?.status === "pending"
                                        ? "text-warning"
                                        : "text-danger"
                                  }`}
                                >
                                  {item?.status == "approve" ? "Success" : capitalizeFirstLetter(item?.status)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="d-lg-none d-block">
                        <div
                          class="offcanvas offcanvas-bottom"
                          tabindex="-1"
                          // id="depositDetails"
                          id={`depositDetails${i}`}
                          aria-labelledby="depositDetails"
                        >
                          <div class="offcanvas-header">
                            <h5 id="depositDetails">Deposit Details</h5>
                            <div data-bs-dismiss="offcanvas" aria-label="Close"><i class="fa-solid fa-xmark fs-4 icon-color"></i></div>
                          </div>
                          <div class="offcanvas-body">
                            <div className="text-center my-4">
                              Quantity
                              <h4 className="fw-bold">
                                {item?.amount} {item?.symbol}
                              </h4>
                              <p
                                className={`text-center ${
                                  item?.status === "approve"
                                    ? "text-green"
                                    : item?.status === "pending"
                                    ? "text-warning"
                                    : "text-danger"
                                }`}
                              >
                                {item?.type == "Rupees" ? (
                                  item?.status === "approve" ? (
                                    <FaRegCheckCircle className="me-1" />
                                  ) : item?.status === "pending" ? (
                                    <PiSealWarningLight className="me-1" />
                                  ) : (
                                    <MdError className="me-1" />
                                  )
                                ) : (
                                  <FaRegCheckCircle className="" />
                                )}
                                {item?.type == "Rupees"
                                  ? item?.status === "approve"
                                    ? "Succeeded"
                                    : item?.status === "pending"
                                    ? "Pending"
                                    : "Failed"
                                  : "Succeeded"}
                              </p>
                              {/* <p className="text-warning"><PiSealWarningLight className="" /> Pending</p> */}
                            </div>

                            <div className="d-flex justify-content-between mb-2">
                              <div className="text-start">
                                <p className="mb-0 text-lgray">
                                  Deposit Account
                                </p>
                              </div>
                              <div className="text-end">
                                <p className="mb-0">Funding Account</p>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                              <div className="text-start">
                                <p className="mb-0 text-lgray">Chain Type</p>
                              </div>
                              <div className="text-end">
                                <p className="mb-0">  {item?.type === "Ethereum"
                                      ? "Binance"
                                      : item?.type }</p>
                              </div>
                            </div>

                            <div className="d-flex justify-content-between mb-2">
                              <div className="text-start">
                                <p className="mb-0 text-lgray">Time</p>
                              </div>
                              <div className="text-end">
                                <p className="mb-0">
                                {item?.timestamp ?new Date(item?.timestamp).toLocaleString() :new Date(item?.createdAt).toLocaleString()}
                                </p>
                              </div>
                            </div>

                            <div className="d-flex justify-content-between mb-2">
                              <div className="text-start">
                                <p className="mb-0 text-lgray">
                                  Deposit Address
                                </p>
                              </div>
                              <div className="text-end">
                                <p className="mb-0">{item?.senderAddress?item?.senderAddress?.slice(0,6)+"..."+item?.senderAddress?.slice(-6) :"0x000000000" }</p>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                              <div className="text-start">
                                <p className="mb-0 text-lgray">
                                  Transaction Hash
                                </p>
                              </div>
                              <div className="text-end">
                              <p className="mb-0"><a className="nav-link p-0" href={`https://bscscan.com/tx/${item?.hash}`} target="_blank">{item?.hash?.slice(0,6)}...{item?.hash?.slice(-9)} </a></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Deposit;
