import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeUserPassword, setUserPassword } from "../utils/apis";
import { fetchUserHandler } from "../utils/actionHandler";
import { toast } from "react-toastify";
import OtpInput from "react-otp-input";

const Change_password = () => {
  const { user, sessionId, userdata } = useSelector((state) => state.user);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loader, setloader] = useState(false);
  const [userEmailOrMobile, setUserEmailOrMobile] = useState("");
  const [step, setStep] = useState(0);
  const [otp, setOtp] = useState(null);
  const [otpSentType, setOtpSentType] = useState([]);



  const dispatch = useDispatch();
  const passwordmodelref = useRef();

  function changePassword(type) {
      setloader(true);
      changeUserPassword(userEmailOrMobile, sessionId, otp, newPassword,confirmPassword, type)
        .then((res) => {
          setloader(false);
          if (res?.status == 200) {
            if(res?.askOtp){
              setStep(1)
              setOtpSentType(res?.sentVia)
              toast.success(res?.message);
            }else{
              fetchUserHandler(userdata?.mobile, sessionId, dispatch);
              passwordmodelref?.current?.click();
              setUserEmailOrMobile("")
              setNewPassword("")
              setConfirmPassword("")
              setMessage("")
              setStep(0)
              setOtp(null)
              setOtpSentType([])
              toast.success(res?.message);
            }
          } else {
            if (res?.wrong) {
              setMessage(res?.message);
            } else {
              toast.error(res?.message);
            }
          }
        })
        .catch((e) => {
          setloader(false);
          console.log(e, "Error in changeEmail");
          toast.error("Something went wrong");
        });
    
  }
  const handleOtp = async (otpValue) => {
    setOtp(otpValue);
    if (otpValue.length === 6) {
      setloader(true); // Show loader
      changeUserPassword(
          userEmailOrMobile,
          sessionId,
          otpValue,
          null,
          null,
          "otp"
        ).then((res) => {
          setloader(false);
          if (res?.status == 200) {
              setStep(2)
              toast.success(res?.message);
          } else {
              setMessage(res?.message);
              toast.error(res?.message);
          }
        })
        .catch((e) => {
          setloader(false);
          console.log(e, "Error in changeEmail");
          toast.error("Something went wrong");
        });
    }
  };

  const formatOtpSentType = (types) => {
    if (types.length === 1) {
      return types[0]; // Return the single item if there's only one
    }
    if (types.length === 2) {
      return `${types[0]} and ${types[1]}`; // Join the two items with "and"
    }
    return types.join(', '); // For more than two items (if needed), join with commas
  };
  
  const otpSentMessage = `A verification code will be sent to your ${formatOtpSentType(otpSentType)}.`;
  

 function handleClose(){
    setUserEmailOrMobile("")
    setNewPassword("")
    setConfirmPassword("")
    setMessage("")
    setStep(0)
    setOtp(null)
    setOtpSentType([])
  }

  return (
    <>
      <div className="model_btn">
        {/* {!userdata?.mobilelogin ? ( */}
          <button
            type="button"
            className="button-outline btn customchnage2"
            data-bs-toggle="modal"
            data-bs-target="#chabgepasswordxampleModal"
          >
            Change
          </button>
        {/* ) : (
          <span className=" text-green fs-14">Password Updated</span>
        )} */}
      </div>

      <div
        className="modal fade"
        id="chabgepasswordxampleModal"
        tabIndex="-1"
        aria-labelledby="chabgepasswordxampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-3">
            <div className="modal-header border-0">
              <h1
                className="modal-title fs-6"
                id="chabgepasswordxampleModalLabel"
              >
                Change password
              </h1>
              <i
                className="fa-solid fa-xmark icon-color"
                ref={passwordmodelref}
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              />
            </div>
            <div className="modal-body">
              <div>
                {/* <div
                  className="alert alert-success d-flex  gap-1 p-2"
                  role="alert"
                >
                  <i className="bi bi-info-circle"></i>
                  <div className="mob-font">
                    Withdrawal will be restricted for 24 hours after changing
                    your password.
                  </div>
                </div> */}
                {step === 0 && (
                    <>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Registered Email or Mobile Number"
                          autoComplete="Your Mobile no."
                          value={userEmailOrMobile}
                          onChange={(e) =>
                            setUserEmailOrMobile(e.target.value)
                          }
                        />
                      </div>
                      <div className="w-100 mt-3">
                        <button
                          className="custom_btn w-100"
                          type="button"
                          onClick={() => {
                              changePassword("sentOtp")
                          }}
                        >
                          {loader ? (
                            <div
                              className="spinner-border text-black load-icon mx-1"
                              role="status"
                            ></div>
                          ) : null}
                          Continue
                        </button>
                      </div>
                    </>
                  )}
                {step === 1 &&(<div className="mb-3">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label mob-font text-muted"
                  >
                    {otpSentMessage}
                  </label>
                  <div className="mb-3">
                    <OtpInput
                      value={otp}
                      onChange={handleOtp}
                      inputMode="numeric"
                      numInputs={6}
                      renderSeparator={<span className="text-secondary mx-1"></span>}
                      renderInput={(props) => (
                        <input
                          {...props}
                          className="form-control"
                          style={{ width: "40px" }}
                          inputMode="numeric"    // Ensures numeric keypad on mobile
                          pattern="[0-9]*"       // Enforces numeric input
                          type="tel"    
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      )}
                      containerStyle={{
                        justifyContent: "center",
                        paddingBlock: "2%",
                      }}
                    />
                  </div>
                    {loader && (
                      <div className="d-flex align-items-center justify-content-center loader m-3">
                        <div
                          className="spinner-border text-black load-icon mx-1"
                          role="status"
                        ></div>
                        Verifying OTP...
                      </div>
                    )}{" "}
                  </div>)}


                {step ===2 && (
                  <>
                  <div className="mb-1">
                  <label
                    htmlFor="exampleInputEmail2"
                    className="form-label mob-font text-muted"
                  >
                    New Password
                  </label>
                  <input
                    type="password"
                    className="form-control "
                    id="exampleInputEmail2"
                    aria-describedby="emailHelp"
                    placeholder=" Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail3"
                      className="form-label mob-font text-muted"
                    >
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      className="form-control "
                      id="exampleInputEmail3"
                      aria-describedby="emailHelp"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                    </>
                )}
                <div className="py-1 mx-1 my-1 text-warning">
                  {message ? (
                    <>
                      <span className="text-danger">Note: </span>
                      <span>{message}</span>{" "}
                    </>
                  ) : null}
                </div>
                {step ===2 && (<div className="d-grid mb-3">
                  <button
                    className="custom_btn"
                    type="button"
                    onClick={()=>changePassword("password")}
                  >
                    {loader ? (
                      <div
                        className="spinner-border text-black load-icon mx-1"
                        role="status"
                      ></div>
                    ) : null}
                    Confirm
                  </button>
                </div>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Change_password;
