import React from "react";
import { useSelector } from "react-redux";

const Phinisingcode = () => {
  const theme = useSelector((state) => state.theme.theme);
  return (
    <>
      <div className="model_btn">
        <button
          type="button"
          className="button-outline"
          data-bs-toggle="modal"
          data-bs-target="#antixampleModal"
        >
          Setting
        </button>
      </div>

      <div
        className="modal fade"
        id="antixampleModal"
        tabIndex="-1"
        aria-labelledby="antixampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-3">
            <div className="modal-header border-0">
              <h1 className="modal-title fs-6" id="antixampleModalLabel">
                Set Up Anti-phishing Code
              </h1>
              <i
                className="fa-solid fa-xmark icon-color"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div>
                <div
                  className="alert alert-success d-flex  gap-1 p-2"
                  role="alert"
                >
                  <i className="bi bi-info-circle"></i>
                  <div className="mob-font">
                    Withdrawal will be restricted for 24 hours after changing
                    your password.
                  </div>
                </div>
                <div className="">
                  <div
                    id="emailHelp"
                    className="form-text lh-2"
                    
                  >
                    After you've successfully set up the anti-phishing code, it
                    will appear in all official emails from Bybit to prevent
                    phishing attempts.
                  </div>
                  <div className="log-card p-3 mt-1">
                    <img
                      src={
                        theme === "dark"
                          ? "images/logo-dark_name.svg"
                          : "images/logo-light_name.svg"
                      }
                      alt="logo"
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="exampleInputEmail7"
                    className="form-label mob-font text-muted mt-3"
                  >
                    <i className="bi bi-fullscreen"></i> Anti-phishing Code
                  </label>
                  <input
                    type="password"
                    className="form-control "
                    id="exampleInputEmail7"
                    aria-describedby="emailHelp"
                  />
                  <div
                    id="emailHelp"
                    className="form-text"
                    style={{ fontSize: "12px" }}
                  >
                    Please enter 4 to 20 characters that contain letters,
                    numbers and underscores only. Please do not set your
                    frequently used password as the anti-phishing code
                  </div>
                  <div className="row mt-3">
                   <div className="col-lg-6 mb-2">
                   <div className="d-grid">
                      <button
                        type="button"
                        className="btn custom_btn  w-100 border-0 text-dark"
                      >
                        Confirm
                      </button>
                    </div>
                   </div>
                  <div className="col-lg-6 mb-2">
                  <div className="d-grid">
                      <button type="button" className="custom_btn_outline">
                        Cancel
                      </button>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Phinisingcode;
