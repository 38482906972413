import React, { useEffect, useState } from "react";
import Header from "../Component/Header";
import Sidebar from "../Component/Sidebar";
import { deleteLoginHistory, loginHistory } from "../utils/apis";
import { useSelector } from "react-redux";

const Managment = () => {
  const { user, sessionId } = useSelector((state) => state.user);
  const [data, setData] = useState([]);

  useEffect(() => {
    loginHistory(user?.mobile, user?.userId)
      .then((res) => {
        if (res?.status == 200) {
          setData(res?.data);
        } else {
          setData([]);
        }
      })
      .catch((e) => {
        console.log(e, "Error in loginHistory");
      });
  }, []);

  function deleteId(loginId) {
    const fdata = data.filter((it) => it?.loginId != loginId);
    setData(fdata);
    deleteLoginHistory(user?.mobile, user?.userId, sessionId, loginId)
      .then((res) => {
        if (res?.status == 200) {
          setData(res?.data);
        }
      })
      .catch((e) => {
        console.log(e, "Error in delete history");
      });
  }

  return (
    <>
      <Header />
      <Sidebar />
      <main className="mt-5 pt-5">
        <div className="container-fluid">
          <h1 className="heading mb-0">Trusted Device Management</h1>
          <div id="emailHelp" className="form-text  text-muted lh-2 mob-font">
            You will recive a notification when you login in to a device not
            included in your trusted list.
          </div>
          <div className="managment_section mt-3">
            <div className="card rounded-lg">
              <div className="card-body">
                <div className="table-responsive custom_table">
                  <table className="table table-borderless mob-font mb-0">
                    <thead className="tbl-hsead">
                      <tr>
                        <th scope="col">Devices</th>
                        <th scope="col">Last login time</th>
                        <th scope="col">Login Status</th>
                        <th scope="col">IP Address</th>
                        <th>Edit</th>
                      </tr>
                    </thead>

                    <tbody>
                      {data?.length > 0
                        ? data.map((item, i) => {
                            // console.log(item?.agentinfo);
                            const device = item?.agentinfo?.agent.slice(-31);
                            const ip = item?.agentinfo?.ip.split("::ffff:")[1];
                            return (
                              <tr key={i + "loginsall"}>
                                <td>
                                  <div className="">
                                    <i className="fa-solid fa-desktop"></i>
                                    &nbsp;&nbsp;{device}
                                  </div>
                                </td>
                                <td>{item?.createdAt}</td>
                                <td
                                  className={`${
                                    item?.login ? "text-success" : "text-danger"
                                  }`}
                                >
                                  {item?.login ? "Active" : "Logged out"}
                                </td>
                                <td>{ip}</td>
                                <td className="text-success">
                                  {item?.login ? null : (
                                    <div
                                      onClick={() => deleteId(item?.loginId)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <i className="fa-solid fa-trash"></i>{" "}
                                      Delete
                                    </div>
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        : null}
                      {/* <tr>
                    <td>
                      <div className="">
                        <i className="fa-solid fa-desktop"></i>
                        &nbsp;&nbsp;chrome/124.0.0.0 Safari/537.36
                      </div>
                    </td>
                    <td>24-05-10 06:07:00 + 0000 UTC</td>
                    <td>Lucknow India</td>
                    <td>106.202.145.25</td>
                    <td className="text-success">
                      <i className="fa-solid fa-trash"></i> Delete
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="">
                        <i className="fa-solid fa-desktop"></i>
                        &nbsp;&nbsp;chrome/124.0.0.0 Safari/537.36
                      </div>
                    </td>
                    <td>24-05-10 06:07:00 + 0000 UTC</td>
                    <td>Lucknow India</td>
                    <td>106.202.145.25</td>
                    <td className="text-success">
                      <i className="fa-solid fa-trash"></i> Delete
                    </td>
                  </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Managment;
