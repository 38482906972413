import React from "react";
import { Link } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { useSelector } from "react-redux";
import { cutAfterDecimal } from "../utils/actionHandler";
import { aprSet } from "../utils/apis";

const Reward_history = () => {
  const { prices, stakes, allstakes } = useSelector((state) => state.wallet);
  

  return (
    <>
      <Link
        type="button"
        className="text-green mob-font"
        data-bs-toggle="modal"
        data-bs-target="#history"
      >
        INRx Reward History
        <i className="bi bi-chevron-double-right"></i>
      </Link>

      <div
        className="modal fade"
        id="history"
        tabIndex="-1"
        aria-labelledby="historyLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered  modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="historyLabel">
                INRx Reward
              </h5>
              <i
                className="fa-solid fa-xmark icon-color"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="">
                <div className="mob-font text-muted mb-2">
                  Holding INRx represents the staked INRx plus the staking
                  rewards. INRx does not grow in quantity over time but,
                  instead, grow in value (i.e., due to the accumulation of
                  staking rewards, the value of 1 INRx progressively exceeds
                  that 1 INRx). You can chack the daily rewards that have been
                  accrued within your INRx below.
                </div>
                <p className="text-muted mob-font">
                  Compounded rewards over a selected period
                </p>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="text-green">{allstakes
                        ? cutAfterDecimal(allstakes?.INRx?.stakeAmount, 4)
                        : "0.00"}&nbsp;INRx</div>
                  <div className="">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["SingleInputDateRangeField"]}>
                        <DateRangePicker
                          renderInput={(startProps, endProps) => (
                            <>
                              <SingleInputDateRangeField {...startProps} />
                              <SingleInputDateRangeField {...endProps} />
                            </>
                          )}
                          name="allowedRange"
                          style={{ width: "10px", height: "10px" }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="activity_section mt-3 mb-4 minHight_table scrollbar">
                  <table className="table mob-font">
                    <thead className="tbl-head">
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">INRx balance</th>
                        <th scope="col">Stake Amount</th>
                        <th scope="col"> APR</th>
                        <th scope="col">claimed </th>
                      </tr>
                    </thead>
                    <tbody>
                      {stakes?.length == 0 || !stakes ? (
                        <tr>
                          <td colSpan={4} className="text-center">
                            <img
                              src="images/no-data.png"
                              className="no-data-found"
                            />
                          </td>
                        </tr>
                      ) : (
                        stakes.map((item, i) => {
                          return (
                            <tr key={i + "allstakeitems"}>
                              <td scope="col">
                                {new Date(
                                  Number(item?.startTimestamp)*1000
                                )?.toLocaleString()}
                              </td>
                              <td scope="col">{cutAfterDecimal(item?.profitAmount,5)}</td>
                              <td scope="col">{cutAfterDecimal(item?.stakeAmount,4)}</td>
                              <td scope="col"> {aprSet}%</td>
                              <td scope="col">{cutAfterDecimal(item?.claimAmount,4)} </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reward_history;
