import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { convertToken } from "../utils/apis";
import { toast } from "react-toastify";
import { cutAfterDecimal, fetchWalletHandler } from "../utils/actionHandler";

const Sell = () => {
  const theme = useSelector((state) => state.theme.theme);
  const { user, sessionId, userdata } = useSelector((state) => state.user);
  const { assets } = useSelector((state) => state.wallet);
  const [inrAmount, setinrAmount] = useState("");
  const [inrxAmount, setinrxAmount] = useState("");
  const [inrx, setINRX] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  function sellinrx() {
    const data = {
      mobile: userdata.mobile,
      token: sessionId,
      sendToken: "INRx",
      recieveToken: "INR",
      sendAmount: Number(inrAmount),
      recieveAmount: Number(inrxAmount),
    };
    if (loading) {
      toast.info("Please wait for pending request");
      return;
    }
    // console.log(data, "data");
    setLoading(true);
    convertToken(data)
      .then((res) => {
        setLoading(false);
        if (res?.status == 200) {
          toast.success(res?.message);
          fetchWalletHandler(userdata?.mobile, sessionId, dispatch);
          // console.log(res);
        } else {
          toast.error(res?.message);
        }
      })
      .catch((e) => {
        setLoading(false);
        toast.error("Something went wrong!");
        console.log(e, "Error in sellinrx()/SellINRxform");
      });
  }

  const handleInput = (val) => {
    setinrAmount(val);
    setinrxAmount(val);
  };
 
  useEffect(() => {
    const inrxdata = assets.find((it) => it.symbol == "INRX");
    setINRX(inrxdata);
  }, [assets]);

  return (
    <>
      <div className="Buydiv text-start mt-4">
        <div className="">
          <div className="mb-3">
            <label htmlFor="exampleInputPassword1" className="form-label">
              Coin
            </label>
            <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
                <div className="imgicon2" style={{width:"48px", borderRadius:"3px"}}>
                  <img src="images/favicon.svg" alt="" width={"25px"} />
                </div>
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="INRx"
                value={inrxAmount}
                onChange={(e) => handleInput(e.target.value)}
              />
              <span className="input-group-text text-muted" id="basic-addon1" onClick={()=>handleInput(cutAfterDecimal(inrx?.available,4))}>
                  <span className="inricon text-muted fs-14">MAX</span> 
              </span>
            </div>
            <div className="text-end mt-2">
              <div>Balance: <span className="fw-bold text-green">{cutAfterDecimal(inrx?.available,4)}</span></div>
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Amount
            </label>
            <div className="input-group">
              <span className="input-group-text" id="basic-addon1">
                <div className="inricon">₹</div>
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="Amount"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={inrAmount}
                onChange={(e) => handleInput(e.target.value)}
              />
            </div>
           

            {/* <div className="d-flex justify-content-between fs-5 mt-5">
              <div className="text-muted">Total :</div>
              <div className="">₹&nbsp;1,324.00</div>
            </div> */}
          </div>
        

          <div className="d-grid">
            <button className="btn custom_danger_btn" type="button" onClick={sellinrx}>
              {loading ? (
                <div
                  className="spinner-border text-white load-icon mx-1"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <img
                src={
                  theme === "dark"
                    ? "images/iconlogolight.svg"
                    : "images/favicon.svg"
                }
                alt="inrx"
                style={{width:"25px", marginRight:"5px"}}
              />
              )}
              Sell INRx
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sell;
