import React from "react";
import Header from "../Component/Header";
import Sidebar from "../Component/Sidebar";

const Notification = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <main className="mt-5 pt-5 deposit_main">
        <div className="container-fluid">
          <div className="flex_row mb-4">
            <div>
              <h1 className="fs-3" style={{ fontWeight: "600" }}>
                Notification
              </h1>
            </div>
            <div>
              <button type="button" className="btn earn_btn pe-2 ps-2 mob-font">
                <img src="images/brush.svg" alt="" />
                Mark All Read
              </button>
            </div>
          </div>
          <div className="card">
            <div className="notification_container card-body">
              <div className="notification_box">
                <div className="">
                  <i className="bi bi-alarm" />
                  &nbsp; <span className="fw-bold">It’s Snapshot Time!</span>
                </div>
                <div className="mob-font mt-2">
                  LFT Launchpad snapshots are in progress, ending on 2024-05-14,
                  11:59:59 PM UTC. Please ensure your assets meet the
                  requirements.
                </div>
                <div className="accordian_sec mt-3">
                  <div className=" text-muted ">
                    <small>Today 5:50</small>
                  </div>
                  <div className="viewmore text-muted">
                    <small>
                      View more <i className="fa-solid fa-arrow-right"></i>
                    </small>
                  </div>
                </div>
                <hr />
              </div>
              <div className="notification_box">
                <div className="">
                  <i className="bi bi-alarm" />
                  &nbsp; <span className="fw-bold">Only 7 Days Left</span>
                </div>
                <div className="mob-font mt-2">
                  Don't miss your chance to watch the livestream, participate in
                  our pop quiz and share 100,000 BOME. Learn more.
                </div>
                <div className="accordian_sec mt-3">
                  <div className=" text-muted ">
                    <small>Yesterday 14:07</small>
                  </div>
                  <div className="viewmore text-muted">
                    <small>
                      View more <i className="fa-solid fa-arrow-right"></i>
                    </small>
                  </div>
                </div>
                <hr />
              </div>
              <div className="notification_box">
                <div className="">
                  <i className="bi bi-alarm" />
                  &nbsp; <span className="fw-bold">LFT Launchpad Live Now</span>
                </div>
                <div className="mob-font mt-2">
                  Hurry! Earn up to 665 USDT and more rewards simply by inviting
                  friends to Copy Trading. Leverage your connections today!
                </div>
                <div className="accordian_sec mt-3">
                  <div className=" text-muted ">
                    <small>05-01 19:45</small>
                  </div>
                  <div className="viewmore text-muted">
                    <small>
                      View more <i className="fa-solid fa-arrow-right"></i>
                    </small>
                  </div>
                </div>
                <hr />
              </div>
              <div className="notification_box">
                <div className="">
                  <i className="bi bi-alarm" />
                  &nbsp; <span className="fw-bold">Launchpad Live Now</span>
                </div>
                <div className="mob-font mt-2">
                  Hurry! Earn up to 665 USDT and more rewards simply by inviting
                  friends to Copy Trading. Leverage your connections today!
                </div>
                <div className="accordian_sec mt-3">
                  <div className=" text-muted ">
                    <small>05-01 19:45</small>
                  </div>
                  <div className=" viewmore text-muted">
                    <small>
                      View more <i className="fa-solid fa-arrow-right"></i>
                    </small>
                  </div>
                </div>
                <hr />
              </div>
              <div className="notification_box">
                <div className="">
                  <i className="bi bi-alarm" />
                  &nbsp; <span className="fw-bold">It’s Snapshot Time!</span>
                </div>
                <div className="mob-font mt-2">
                  LFT Launchpad snapshots are in progress, ending on 2024-05-14,
                  11:59:59 PM UTC. Please ensure your assets meet the
                  requirements.
                </div>
                <div className="accordian_sec mt-3">
                  <div className=" text-muted ">
                    <small>Today 5:50</small>
                  </div>
                  <div className=" viewmore text-muted">
                    <small>
                      View more <i className="fa-solid fa-arrow-right"></i>
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Notification;
