import React from "react";
import Header from "../Component/Header";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Sidebar from "../Component/Sidebar";
const Account_activity = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <main className="mt-5 pt-5">
        <div className="container-fluid ">
          <h1 className="heading">Account Activity</h1>
          <div className="account_activity">
            <div className="">
              <ul className="nav nav-pills">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-bs-toggle="pill"
                    href="#security"
                    role="tab"
                    aria-controls="security"
                    aria-selected="true"
                  >
                    Security
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-bs-toggle="pill"
                    href="#activity"
                    role="tab"
                    aria-controls="activity"
                    aria-selected="false"
                  >
                    Activity Code
                  </a>
                </li>
              </ul>
              <div className="tab-content mt-4">
                <div
                  className="tab-pane fade show active"
                  id="security"
                  role="tabpanel"
                  aria-labelledby="security-tab"
                >
                  <div className="activity_section">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive custom_table">
                          <table className="table table-borderless">
                            <thead className="tbl-hsead">
                              <tr>
                                <th scope="col">Login Devices</th>
                                <th scope="col">Time</th>
                                <th scope="col"> Country /Region</th>
                                <th scope="col">IP </th>
                                <th>Activities</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="">
                                    <i className="fa-solid fa-desktop"></i>
                                    &nbsp;&nbsp;PC
                                  </div>
                                </td>
                                <td>24-05-10 06:07:00</td>
                                <td> India</td>
                                <td>106.202.145.25</td>
                                <td>A trusted device removed</td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="">
                                    <i className="fa-solid fa-desktop"></i>
                                    &nbsp;&nbsp;PC
                                  </div>
                                </td>
                                <td>24-05-10 06:07:00</td>
                                <td> India</td>
                                <td>106.202.145.25</td>
                                <td>Register user email</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="custompagination d-flex justify-content-end my-3">
                        <Pagination
                          count={11}
                          defaultPage={6}
                          siblingCount={0}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="activity"
                  role="tabpanel"
                  aria-labelledby="activity-tab"
                >
                  <div className="activity_section">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive custom_table">
                          <table className="table table-borderless">
                            <thead className="tbl-head--">
                              <tr>
                                <th scope="col">Login Devices</th>
                                <th scope="col">Time</th>
                                <th scope="col"> Country /Region</th>
                                <th scope="col">IP </th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="">
                                    <i className="fa-solid fa-desktop"></i>
                                    &nbsp;&nbsp;PC
                                  </div>
                                </td>
                                <td>24-05-10 06:07:00</td>
                                <td> India</td>
                                <td>106.202.145.25</td>
                                <td className="text-success">
                                  Login Successful
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="">
                                    <i className="fa-solid fa-desktop"></i>
                                    &nbsp;&nbsp;PC
                                  </div>
                                </td>
                                <td>24-05-10 06:07:00</td>
                                <td> India</td>
                                <td>106.202.145.25</td>
                                <td className="text-success">
                                  Login Successful
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="custompagination d-flex justify-content-end my-3">
                        <Pagination
                          count={11}
                          defaultPage={6}
                          siblingCount={0}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Account_activity;
