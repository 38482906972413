import React, { useRef, useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchUserHandler } from "../utils/actionHandler";
import { setEmailOrMobile } from "../utils/apis";

const SmsSetting = () => {
  const { user, sessionId, userdata } = useSelector((state) => state.user);
  const [loader, setloader] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [isOtpVisible, setIsOtpVisible] = useState(false);
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const emailmodelref = useRef();
  const handleMobileNumberChange = (e) => {
    setMobileNumber(e.target.value);
  };

  const handleConfirmMobileNumber = () => {
     handleSetMobileData()
  };

  const handleOtpChange = (otpValue) => {
    setOtp(otpValue);
  };

  function handleSetMobileData() {
      setloader(true);
      setEmailOrMobile(mobileNumber, "", sessionId, otp)
        .then((res) => {
          console.log(res,"RES:::::::_______")
          setloader(false);
          if (res?.status == 200) {
            if(res?.askOtp){
              setIsOtpVisible(true)
            }else {
              fetchUserHandler(mobileNumber, sessionId, dispatch);
              emailmodelref?.current?.click();
              toast.success(res?.message);
            }
            
          } else {
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          setloader(false);
          console.log(e, "Error in changeEmail");
          toast.error("Something went wrong");
        });
    
  }

  return (
    <>
    {!userdata?.mobilelogin ? (
      <div className="model_btn">
        <button
          type="button"
          className="button-outline"
          data-bs-toggle="modal"
          data-bs-target="#smsSettingexampleModal"
        >
          Verify Now
        </button>
      </div>
      ) : (
        <span className=" text-green fs-14">{userdata?.mobile}</span>
      )}
     

      <div
        className="modal fade"
        id="smsSettingexampleModal"
        tabIndex="-1"
        aria-labelledby="smsSettingexampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-3">
            <div className="modal-header border-0">
              <h1 className="modal-title fs-6" id="smsSettingexampleModalLabel">
                Set Mobile Number
              </h1>
              <div data-bs-dismiss="modal" aria-label="Close">
                <i className="fa-solid fa-xmark fs-4 icon-color" ref={emailmodelref} />
              </div>
            </div>
            <div className="modal-body">
              <div>
                <div className="mb-3">
                  <label
                    htmlFor="mobile-number"
                    className="form-label mob-font text-muted"
                  >
                   Enter a new mobile phone
                    number
                  </label>

                  <div className="input-group mb-3">
                    <div className="input-group-text" id="mobile-number">
                      🇮🇳
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      id="mobile-number"
                      placeholder="New Mobile Number"
                      value={mobileNumber}
                      onChange={handleMobileNumberChange}
                    />
                  </div>
                </div>

                {isOtpVisible && (
                  <>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label mob-font text-muted"
                      >
                        A verification code will be sent to{" "}
                        <strong>{mobileNumber}</strong>
                      </label>
                      <div className="mb-3">
                        <OtpInput
                          value={otp}
                          onChange={handleOtpChange}
                          numInputs={6}
                          renderSeparator={<span className="mx-1"></span>}
                          renderInput={(props) => (
                            <input
                              {...props}
                              className="form-control"
                              style={{ width: "50px" }}
                            />
                          )}
                          containerStyle={{
                            justifyContent: "center",
                            // paddingBlock: "2%",
                          }}
                        />
                      </div>

                      <div className="text-center fs-13">
                        Did'nt received OTP?{" "}
                        <Link className="anchor_link"> Resend </Link>
                      </div>
                    </div>
                  </>
                )}

                <div className="d-grid mb-3">
                  <button
                    className="custom_btn"
                    type="button"
                    onClick={handleConfirmMobileNumber}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmsSetting;
