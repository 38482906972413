import React, { useEffect } from "react";
import Header from "../Component/Header";
import Sidebar from "../Component/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import Stake_inr from "./Stake_inr";
import Redeem from "./Redeem";
import Earn_faq from "./Earn_faq";
import Reward_history from "./Reward_history";
import { cutAfterDecimal, stakeHistory } from "../utils/actionHandler";
import { FaArrowRight, FaCheckCircle } from "react-icons/fa";
import { TfiGift } from "react-icons/tfi";
import { TbClock } from "react-icons/tb";
const Rewards = () => {
  const { userdata, conditionsData} = useSelector((state) => state.user);
  const datakey = userdata?.rewards;
  const data = [
    {
      bonus: 1000,
      key: "firstdepositinr",
      symbol: "INRx",
      title: "Task",
      description:
        "First deposit in INR, with a corresponding amount of 25K (most likely INR)",
    },
    {
      bonus: 1000,
      key: "firstdepositinrx",
      symbol: "INRx",
      title: "Task",
      description:
        "First deposit in INRx, with a corresponding amount of 25K (most likely INR)",
    },
    // {
    //   bonus: 20,
    //   key: "firstdepositusdt",
    //   symbol: "USDT",
    //   title: "Task",
    //   description:
    //     "First deposit in USDT, with a corresponding amount of 500 USDT",
    // },
    {
      bonus: 1000,
      key: "firstwithdrawinr",
      symbol: "INRx",
      title: "Task",
      description:
        "First withdrawal in INR, with a corresponding amount of 10K (most likely INR)",
    },
    {
      bonus: 1000,
      key: "firstwithdrawinrx",
      symbol: "INRx",
      title: "Task",
      description:
        "First withdrawal in INRx, with a corresponding amount of 10K (most likely INR)",
    },
    // {
    //   bonus: 10,
    //   key: "secondwithdrawinr",
    //   symbol: "USDT",
    //   title: "Task",
    //   description:
    //     "Second withdrawal, with a corresponding amount of 10K (most likely INR)",
    // },
  ];

  return (
    <>
      <Header />
      <Sidebar />
      <main className="mt-5 pt-5 deposit_main">
        <div className="mb-3">
          <h1 className="heading mb-1">Rewards</h1>
          <div className="text-muted mob-font">
            Claim rewards by completing tasks and joining events
          </div>
        </div>
        <div className="col-lg-12 col-sm-12 mb-3">
          <div className="highlighted_card">
            <div className="row align-items-center">
              <div className="col-lg-9">
                <div className="highlighted_card_text">
                  Invite a friend and you'll earn{" "}
                  <span className="text-green">{conditionsData?.referral_bonus} INRx</span>
                </div>
                <p className="sub_description mb-0">
                  This entry appears to sum up the transactions, showing a total
                  deposit of 1,000.00 (currency not specified, could be INR).
                </p>
              </div>
              <div className="col-lg-3 text-end">
                <img src="/images/reward.svg" className="reward_img" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-sm-12">
          <div className="box_wrapper">
            <div className="box-left">
              <h5 className="amount_heading">10000 INRx</h5>
              <p className="coupon_code">Signup Bonus</p>
              <div className="gift_icon_wrapper">
                <TfiGift />
              </div>
            </div>
            <div className="box-right">
              <div className="row align-items-center">
                <div className="col-lg-10">
                  <h5 className="box_heading">
                    Receive 10000 INRx upon signing up
                  </h5>
                </div>
                <div className="col-lg-2 text-end">
                  <a href="#" className="custom_btn_outline fs-14">
                    Completed
                    <FaCheckCircle className="ms-1" />
                  </a>
                </div>
              </div>
            </div>
            <div class="corner_badge">
              <TbClock className="me-1" />
              1d 3h
            </div>
          </div>

          {data.map((item, i) => {
            const iscompleted = datakey
            ? datakey?.find((it) => 
              Object.keys(it).some(
                (key) => key.toLowerCase() === item?.key?.toLowerCase() && it[key] === true
              )
              )
            : false;
            return (
              <div className="box_wrapper" key={i + "BonusCards"}>
                <div className="box-left">
                  <h5 className="amount_heading">
                    {item?.bonus} {item?.symbol}
                  </h5>
                  <p className="coupon_code">{item?.title}</p>
                  <div className="gift_icon_wrapper">
                    <TfiGift />
                  </div>
                </div>
                <div className="box-right">
                  <div className="row align-items-center">
                    <div className="col-lg-10">
                      <h5 className="box_heading">{item?.description}</h5>
                    </div>
                    <div className="col-lg-2 text-end">
                      <a href="#" className="custom_btn_outline fs-14">
                        {iscompleted ? "Completed" : "Acomplished"}{" "}
                        {iscompleted ? (
                          <FaCheckCircle className="ms-1" />
                        ) : (
                          <FaArrowRight className="ms-1" />
                        )}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </main>
    </>
  );
};

export default Rewards;
