import React, { useState, useEffect } from "react";
import Header from "../Component/Header";
import Sidebar from "../Component/Sidebar";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { FaCheckCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { BsQrCode } from "react-icons/bs";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import {
  newurl,
  N_addBinficiary,
  N_addUPI,
  N_addUPIWithQRCode,
  N_deleteBankUpi,
  N_getUserBankDetails,
  N_getUserUPIDetails,
  N_UPIVarify,
} from "../utils/apis";
import styled from "styled-components";
import { Button } from "@mui/material";

const Bank_verification = () => {
  const { user, sessionId, userdata } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [accountNo, setAccountNo] = useState("");
  const [UPI_ID, setUPI_ID] = useState("");
  const [bankIFCS, setIFSC] = useState("");
  const [bankDetails, setBankDetails] = useState([]);
  const [upiDetails, setUPIDetails] = useState([]);
  const [showBankForm, setShowBankForm] = useState(1); // New state variable
  const [active, setActive] = useState(0);

  const [selectedMethod, setSelectedMethod] = useState("");

  // Form data for Bank Transfer
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [bankName, setBankName] = useState("");

  const [currentStep, setCurrentStep] = useState(1); // Track the current step
  const [upiID, setUpiID] = useState("");
  const [filedata, setFileData] = useState();
  const [type, setType] = useState("");
  const [loader, setLoader] = useState(false);
  const [uPIData, setUPIData] = useState()
  const [updateId, setUpdateId] = useState();
  const [deletingId, setDeletingId] = useState(null);

  const handleAddBankClick = () => {
    setShowBankForm(1);
    closeDropdown();
  };
  const handleAddUpiClick = () => {
    setShowBankForm(2);
    closeDropdown();
  };

  const handleShowAllBanks = () => {
    setShowBankForm(0);
  };
  const closeDropdown = () => {
    // Get the dropdown button element
    const dropdownButton = document.getElementById("dwn");
    // Close the dropdown by removing the 'show' class from the button
    dropdownButton.classList.remove("show");
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (showBankForm == 2) {
      N_addUPI(userdata?.email, sessionId, UPI_ID).then((res) => {
        if (res.status == 200) {
          toast.success(res.message);
          N_getUserUPIDetails(userdata?.email, sessionId).then((data) => {
            if (data.status === 200) {
              setShowBankForm(0);
              setUPIDetails(data.upi);
              setActive(1);
              setUPI_ID("");
            }
          });
        } else {
          toast.error(res.message);
        }
      });
    } else {
      if (accountNo == "" && bankIFCS == "") {
        toast.error(
          accountNo == ""
            ? "Please Fill Account Number"
            : "Please Fill IFSC Code"
        );
      } else {
        setLoading(true);
        N_addBinficiary(userdata?.email, sessionId, accountNo, bankIFCS).then(
          (d) => {
            if (d.status == 200) {
              toast.success(d.message);
              N_getUserBankDetails(userdata?.email, sessionId).then((data) => {
                if (data.status === 200) {
                  setShowBankForm(0);
                  setBankDetails(data.bank);
                  setActive(0);
                }
              });
            } else {
              toast.error(d.message);
            }

            setLoading(false);
          }
        );
      }
    }
  };

  useEffect(() => {
    N_getUserBankDetails(userdata?.email, sessionId)
      .then((data) => {
        if (data.status === 200) {
          setBankDetails(data.bank);
          if (data.bank.length > 0) {
            setShowBankForm(0);
          }
        }else{
          setBankDetails([]);
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });

    N_getUserUPIDetails(userdata?.email, sessionId)
      .then((data) => {
        if (data.status === 200) {
          setUPIDetails(data.upi);
          if (data.upi.length > 0) {
            setShowBankForm(0);
          }
        }else{
          setUPIDetails([]);
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }, [deletingId]);

  // const [selectedMethod, setSelectedMethod] = useState("");

  // const handlePaymentMethodChange = (e) => {
  //   setSelectedMethod(e.target.value);
  // };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#E5EEE5",
      color: "rgba(0, 0, 0, 0.87)",
      width: 220,
      padding:'1rem',
      border: "1px solid #dadde9",
    },
  }));


  
    // State for form data
    const [selectedMethod2, setSelectedMethod2] = useState("Bank_transfer");
    const [accountName, setAccountName] = useState("Aman Kumar Pandey");
    // const [accountNumber, setAccountNumber] = useState("7713873112");
    // const [bankBranch, setBankBranch] = useState("KKBK0005076");
    // const [bankName, setBankName] = useState("Kotak Mahindra Bank");


    const uploadIMG = (input) => {
      console.log(input?.target?.files,"file")
      if (input.target.files && input.target.files[0]) {
        setFileData(input.target.files[0]);
      }
    };

  // Create an object URL for the image preview
  const imagePreviewUrl = filedata ? URL.createObjectURL(filedata) : '';

  // Handle the selection of payment method

  const handlePaymentMethodChange = (e) => {
    setSelectedMethod(e.target.value);
    setCurrentStep(2);
  };

  const handleNext = () => {
    if(!accountNumber && !ifscCode && !bankName){
      toast.error("Please fill in all bank details")
      return
    }
    if (currentStep === 2) {
      setCurrentStep(3);
    }
  };  

  const handleVarify = () =>{
    setLoader(true)
    N_UPIVarify(userdata?.email, sessionId, upiID, type)
    .then((res) => {
      if (res.status == 200) {
        setLoader(false)
        toast.success(res.message);
        setUPIData(res?.data)
      } else {
        setLoader(false)
        toast.error(res.message);
      }
    })
    .catch((err) => {
      setLoader(false)
      toast.error("Failed to submit UPI details");
    });
  }
  // Handle the confirm button click
  const handleConfirm = (e) => {
    e.preventDefault();
    
    if (currentStep === 2 && selectedMethod === "Bank_transfer") {
      // Move to the final confirmation step based on the selected method
      if (selectedMethod) {
        setCurrentStep(3);
      }
    } else if (currentStep === 3 || selectedMethod === "UPI" ) {
      setLoader(true)
      if (selectedMethod === "Bank_transfer") {
        // Validate Bank Transfer details before submission
        if (accountNumber && ifscCode && bankName && type) {
          N_addBinficiary(userdata?.email, sessionId, accountNumber, ifscCode, bankName,type,updateId)
            .then((d) => {
              if (d.status == 200) {
                setLoader(false)
                toast.success(d.message);
                N_getUserBankDetails(userdata?.email, sessionId).then((data) => {
                  if (data.status === 200) {
                    setShowBankForm(0);
                    setBankDetails(data.bank);
                    setActive(0);
                    setCurrentStep(1); // Reset the modal to the first step
                    const modal = document.getElementById(`${type}-modal`);
                    modal.click();
                    setUpdateId();
                    setType("");
                  }
                });
              } else {
                setLoader(false)
                toast.error(d.message);
              }
            })
            .catch((err) => {
              setLoader(false)
              toast.error("Failed to submit bank details.");
            });
        } else {
          setLoader(false)
          toast.error("Please fill in all bank details");
        }
      } else if (selectedMethod === "UPI") {
        if(!upiID){
          setLoader(false)
          toast.error("Please fill in UPI ID ");
        }
        // Validate UPI details before submission
        if(upiID && filedata && uPIData && type){

          N_addUPIWithQRCode(userdata?.email, sessionId, upiID, filedata,uPIData, type, updateId)
          .then((res) => {
            if (res.status == 200) {
              setLoader(false)
              toast.success(res.message);
              N_getUserUPIDetails(userdata?.email, sessionId).then((data) => {
                if (data.status === 200) {
                  setShowBankForm(0);
                  setUPIDetails(data.upi);
                  setActive(1);
                  setUPI_ID("");
                  setUPIData();
                  setCurrentStep(1); // Reset the modal to the first step
                  const modal = document.getElementById(`${type}-modal`);
                  modal.click();
                  setUpdateId();
                  setType("");
                }
              });
            } else {
              setLoader(false)
              toast.error(res.message);
            }
          })
          .catch((err) => {
            setLoader(false)
            toast.error("Failed to submit UPI details");
          });
        }
        if (upiID && !filedata && uPIData && type) {
          N_addUPI(userdata?.email, sessionId, upiID,uPIData, type, updateId)
            .then((res) => {
              if (res.status == 200) {
                setLoader(false)
                toast.success(res.message);
                N_getUserUPIDetails(userdata?.email, sessionId).then((data) => {
                  if (data.status === 200) {
                    setShowBankForm(0);
                    setUPIDetails(data.upi);
                    setActive(1);
                    setUPI_ID("");
                    setUPIData();
                    setCurrentStep(1); // Reset the modal to the first step
                    const modal = document.getElementById(`${type}-modal`);
                    modal.click();
                    setUpdateId();
                    setType("");
                  }
                });
              } else {
                setLoader(false)
                toast.error(res.message);
              }
            })
            .catch((err) => {
              setLoader(false)
              toast.error("Failed to submit UPI details");
            });
        }
      }
    }
  };

  const handleConfirmDelete = (id, typeId)=>{
    if(userdata.email && sessionId){
      N_deleteBankUpi(userdata.email, sessionId, id, typeId)
      .then((data) => {
        if (data?.status === 200) {
          toast.success(data?.message)
        }else{
          toast.error(data?.message)
        }
        setDeletingId(null);
      }).catch((err) => {
        console.log(err,"err")
        setDeletingId(null);
        toast.error("error");
      });
  } else{
    setDeletingId(null);
    toast.error("Failed!");
  }

  }
  
  const handleCancel = () => {
    setLoader(false)
    setCurrentStep(1);
    setSelectedMethod("");
    setAccountNumber("");
    setIfscCode("");
    setBankName("");
    setUpiID("");
    setFileData();
    setUPIData();
  };


  return (
    <>
      <Header />
      <Sidebar />
      <main className="mt-5 pt-5 setting_main">
        <div class="flex_row mb-4">
          <div className="mb-2">
            <h1 className="heading mb-1">Bank Accounts & UPI</h1>           
          </div>
          {/*  <div class="d-flex gap-2 mb-2">
           

           <div className="dropdown">
              <button
                className={`btn dropdown-toggle ${
                  showBankForm !== 0 ? "btn custom_btn" : "btn custom_btn "
                } `}
                type="button"
                id="accountsmenu"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Add New <i class="fa-solid fa-plus ms-1"></i>
              </button>

              <ul
                class="shadow dropdown-menu border-0"
                id="dwn"
                aria-labelledby="accountsmenu"
              >
                <li>
                  <a class="dropdown-item fs-14" onClick={handleAddUpiClick}>
                    UPI
                  </a>
                </li>
                <li>
                  <a class="dropdown-item fs-14" onClick={handleAddBankClick}>
                    Bank
                  </a>
                </li>
              </ul>
            </div> */}
          {/* <div>
              <div>
                <a
                  class="custom_btn_outline fs-14"
                  onClick={handleShowAllBanks}
                >
                  All Accounts<i class="fa-solid fa-arrow-right-long ms-1"></i>
                </a>
              </div>
            </div> 
          </div>*/}
        </div>

        <div class="col-lg-12 col-sm-12 mb-3">
          <div class="highlighted_card px-5 py-4">
            <div class="row align-items-center">
              <div class="col-lg-8">
                <div class="highlighted_card_text">Payement Method</div>
                <p class="sub_description mb-0">
                The payment method you add will be used for Inr deposit & withdrawal. Please utilize an account registered under your real name to ensure a successful transfer.
                </p>
              </div>
              <div class="col-lg-4 text-end">
                <div
                  className="btn custom_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#addnewaccount"
                  onClick={()=>setType("CREATE_DATA")}
                >
                  <i class="fa-solid fa-plus ms-1"></i> Add New
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12">

         {bankDetails && bankDetails?.map((item,i)=>{
          return (
            <>
            <div className="card mb-2" key={"bank"+i}>
            <div className="card-header">
              <div className="d-flex justify-content-between">
                <div className="border-left-heading">Bank Transfer</div>
                <div className="d-flex gap-2">
                  {/* <div>
                  <Link className="anchor_link" data-bs-target="#updateAccount" data-bs-toggle="modal" onClick={()=>{setCurrentStep(3);setUpdateId(item?._id); setType("UPDATE_DATA"); setSelectedMethod("Bank_transfer")}}>
                      Update
                    </Link>
                  </div> */}
                  <div className="text-lgray">|</div>

                  <div>
                    {deletingId === item?._id ? (
                        <div
                          className="spinner-border text-black load-icon mx-1"
                          role="status"
                        ></div>
                      ) : 
                      <Link className="anchor_link" onClick={()=>{
                          setDeletingId(item?._id);
                          handleConfirmDelete(item?._id,"bank")
                        }}> 
                        Delete
                      </Link>
                    }
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div class="table_wrapper">
                <div class="column_wrapper">
                  <div class="data_vertical">
                    <div class="by-space-item">
                      <span class="fs-12 text-lgray">Name</span>
                    </div>
                    <div class="by-space-item">
                      <span>{item?.name}</span>
                    </div>
                  </div>
                </div>
                <div class="column_wrapper">
                  <div class="data_vertical">
                    <div class="by-space-item">
                      <span class="fs-12 text-lgray">Bank Account Number</span>
                    </div>
                    <div class="by-space-item">
                      <span>{item?.account_number}</span>
                    </div>
                  </div>
                </div>
                <div class="column_wrapper">
                  <div class="data_vertical">
                    <div class="by-space-item">
                      <span class="fs-12 text-lgray">Bank Branch</span>
                    </div>
                    <div class="by-space-item">
                      <span>{item?.ifsc}</span>
                    </div>
                  </div>
                </div>
                <div class="column_wrapper">
                  <div class="data_vertical">
                    <div class="by-space-item">
                      <span class="fs-12 text-lgray">Bank Name</span>
                    </div>
                    <div class="by-space-item">
                      <span>{item?.bank_name}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
            </>
          )
         }) }


         {upiDetails && upiDetails?.map((item, i)=>{
          return (
            <>
            <div className="card mb-2" key={"upi"+i}>
            <div className="card-header">
              <div className="flex_row">
                <div className="border-left-heading">UPI</div>
                <div className="d-flex gap-2">
                  {/* <div>
                  <Link className="anchor_link" data-bs-target="#updateAccount" data-bs-toggle="modal" onClick={()=>{setCurrentStep(3);setUpdateId(item?._id);setType("UPDATE_DATA");setSelectedMethod("UPI")}}>
                      Update
                    </Link>
                  </div> */}
                  <div className="text-lgray">|</div>

                  <div>
                  {deletingId === item?._id ? (
                      <div
                        className="spinner-border text-black load-icon mx-1"
                        role="status"
                      ></div>
                    ) : 
                    <Link className="anchor_link"  onClick={()=>{
                          setDeletingId(item?._id);
                          handleConfirmDelete(item?._id,"upi")
                      }}>
                        Delete
                    </Link>
                   }
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div class="table_wrapper">
                <div class="column_wrapper">
                  <div class="data_vertical">
                    <div class="by-space-item">
                      <span class="fs-12 text-lgray">Name</span>
                    </div>
                    <div class="by-space-item">
                      <span>{item?.data?.full_name}</span>
                    </div>
                  </div>
                </div>
                <div class="column_wrapper">
                  <div class="data_vertical">
                    <div class="by-space-item">
                      <span class="fs-12 text-lgray">UPI ID</span>
                    </div>
                    <div class="by-space-item">
                      <span>{item?.data?.upi_id}</span>
                    </div>
                  </div>
                </div>
                <div class="column_wrapper">
                  <div class="data_vertical">
                    <div class="by-space-item">
                      <span class="fs-12 text-lgray">QR code</span>
                    </div>
                    <div class="by-space-item">
                      <HtmlTooltip placement="top"
                        title={<div className="m-auto text-center"> 
                              <img src={`${newurl}/support/images/bankqr/${item?.upiQr}`} alt="QR Code" style={{ width: '100%', maxWidth: '200px' }} />

                          </div>}
                      >
                       <div>   <BsQrCode /></div>
                      </HtmlTooltip>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
            </>
          )
         }) }
        </div>

        {/* ======Applied d-none class to hide this section for now */}
        <div className="row d-none">
          <div className="col-lg-12 m-auto">
            <div className="card">
              <div className="card-body p-4">
                <div className="account_page">
                  {showBankForm == 0 &&
                  bankDetails.length == 0 &&
                  upiDetails.length == 0 ? (
                    <div className="text-center">
                      <img
                        src="/images/no-data.png"
                        className="no-data-found"
                      />
                      <p className="text-lgray fs-12">
                        There are no bank details or UPI accounts added.
                      </p>
                    </div>
                  ) : null}
                </div>

                <div className="">
                  {showBankForm == 2 && (
                    <div className="">
                      <h4>Add UPI</h4>
                      <p className="text-lgray fs-14">
                        Provide information for the process of adding an UPI
                      </p>
                      <form
                        className="mdfthemetxt"
                        method="post"
                        id="loginForm"
                        autoComplete="off"
                        onSubmit={onSubmit}
                      >
                        <div className="form-group">
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control colr"
                              placeholder="Enter UPI ID"
                              id="bank_upi"
                              name="bank_upi"
                              required=""
                              value={UPI_ID}
                              onChange={(e) => {
                                setUPI_ID(e.target.value);
                              }}
                            />
                            <span className="input-group-text p-0">
                              <button
                                type="submit"
                                className="btn custom_btn border-0 rounded-0"
                              >
                                {loading ? (
                                  <i className="loading-icon fas fa-spinner fa-spin mr-2"></i>
                                ) : null}
                                <span id="reg">Add UPI</span>
                              </button>
                            </span>
                          </div>
                          <small className="text-danger" id="passerr2"></small>
                        </div>
                      </form>
                    </div>
                  )}

                  {showBankForm == 0 &&
                  (bankDetails.length > 0 || upiDetails.length > 0) ? (
                    <div className="mt-3">
                      <nav className="justify-content-start d-flex gap-2 custom_pills ">
                        <div
                          class="nav nav-pills mb-3"
                          id="nav-tab"
                          role="tablist"
                        >
                          <button
                            class={`nav-link${active == 0 ? " active" : ""}`}
                            id="nav-bank-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-bank"
                            type="button"
                            role="tab"
                            aria-controls="nav-bank"
                            aria-selected="true"
                            onClick={() => setActive(0)}
                          >
                            Bank Accounts
                          </button>
                          <button
                            class={`nav-link${active == 1 ? " active" : ""}`}
                            id="nav-upi-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-upi"
                            type="button"
                            role="tab"
                            aria-controls="nav-upi"
                            aria-selected="false"
                            onClick={() => setActive(1)}
                          >
                            UPI
                          </button>
                        </div>
                      </nav>

                      <div class="tab-content" id="nav-tabContent">
                        <div
                          class={`tab-pane fade${
                            active == 0 ? " active" : ""
                          } show`}
                          id="nav-bank"
                        >
                          <div className="jambotron mb-3">
                            <div className="card-body">
                              {bankDetails.length > 0 ? (
                                bankDetails.map((item) => {
                                  return (
                                    <div className="bank-details">
                                      <div className="flex_row mb-2">
                                        <div className="text-lgray">
                                          Bank Name
                                        </div>

                                        <div className="fw-bold">
                                          {item.bank_name}
                                          <FaCheckCircle className="text-green ms-2" />
                                        </div>
                                      </div>

                                      <div className="flex_row mb-2">
                                        <div className="text-lgray">
                                          Account Name
                                        </div>

                                        <div className=""> {item.name}</div>
                                      </div>
                                      <div className="flex_row mb-2">
                                        <div className="text-lgray">
                                          Account Number
                                        </div>

                                        <div className="">
                                          {item.account_number}
                                        </div>
                                      </div>

                                      <div className="flex_row mb-2">
                                        <div className="text-lgray">
                                          IFSC Code
                                        </div>

                                        <div className="">{item.ifsc}</div>
                                      </div>
                                      <div className="flex_row mb-2">
                                        <div className="text-lgray">Status</div>

                                        <div className="">
                                          {item.status == 1
                                            ? "Verified"
                                            : item.status == -1
                                            ? "Pending"
                                            : "Reject"}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="text-center">
                                  <img
                                    src="/images/no-data.png"
                                    className="no-data-found"
                                  />
                                  <p className="text-lgray fs-12">
                                    There are no bank details or UPI accounts
                                    added.
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div
                          class={`tab-pane fade${
                            active == 1 ? " active" : ""
                          } show`}
                          id="nav-upi"
                        >
                          <div className="jambotron mb-3">
                            <div className="card-body">
                              {upiDetails.length > 0 ? (
                                upiDetails.map((item) => {
                                  return (
                                    <div className="bank-details">
                                      <div className="flex_row">
                                        <div className="">
                                          {item.data.upi_id}
                                        </div>
                                      </div>
                                      <div className="flex_row mb-2">
                                        <div className="text-lgray">Name</div>

                                        <div className="">
                                          {item.data.full_name}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="text-center">
                                  <img
                                    src="/images/no-data.png"
                                    className="no-data-found"
                                  />
                                  <p className="text-lgray fs-12">
                                    There are no bank details or UPI accounts
                                    added.
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {showBankForm == 1 ? (
                    <>
                      <div>
                        <h5 className="fw-bold mb-0">Add Bank Details</h5>
                        <p className="text-lgray fs-14">
                          Provide information for the process of adding a bank
                        </p>

                        <form
                          className="mdfthemetxt"
                          method="post"
                          id="loginForm"
                          autoComplete="off"
                          onSubmit={onSubmit}
                        >
                          <div className="form-group mb-3">
                            <input
                              type="text"
                              id="account_no"
                              nmae="account_no"
                              className="form-control colr"
                              placeholder="Enter Account no"
                              value={accountNo}
                              onChange={(e) =>
                                setAccountNo(
                                  e.target.value
                                    .replace(/[^0-9.]/g, "")
                                    .replace(/(\..*?)\..*/g, "$1")
                                )
                              }
                            />
                          </div>
                          <div className="form-group mb-3">
                            <input
                              type="text"
                              name="ifsc_code"
                              id="ifsc_code"
                              className="form-control colr"
                              placeholder="Enter Bank IFSC"
                              value={bankIFCS}
                              onChange={(e) =>
                                setIFSC(e.target.value.toUpperCase())
                              }
                            />
                          </div>

                          <div className="d-grid">
                            <button
                              type="submit"
                              className="btn custom_btn px-4"
                            >
                              {loading ? (
                                <i className="loading-icon fas fa-spinner fa-spin mr-2"></i>
                              ) : null}
                              <span id="reg">Add Bank</span>
                            </button>
                          </div>
                        </form>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ======Applied d-none class to hide this section for now */}
      </main>

      {/* Modal for add account */}
      <div
        className="modal fade"
        id="addnewaccount"
        tabIndex="-1"
        aria-labelledby="addnewaccount"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-3">
            <div className="modal-header border-0">
              <h1 className="fs-6 fw-bold" id="addnewaccount">
                {currentStep === 1
                  ? "Add New Account"
                  : currentStep === 2
                  ? "Select Payment Method"
                  : "Confirm Details"}
              </h1>
              <Link data-bs-dismiss="modal" aria-label="Close" id="CREATE_DATA-modal" onClick={handleCancel} >
                <i className="fa-solid fa-xmark icon-color fs-4"></i>
              </Link>
            </div>
            <div
              className="modal-body scrollbar"
              style={{ maxHeight: "450px", overflow: "auto" }}
            >
              {currentStep === 1 && (
                <div>
                  <label
                    htmlFor="paymentmethod"
                    className="form-label mob-font mb-1"
                  >
                    Payment Method
                  </label>
                  <select
                    className="form-select"
                    id="paymentmethod"
                    value={selectedMethod}
                    onChange={handlePaymentMethodChange}
                  >
                    <option value="">Select Payment Method</option>
                    <option value="Bank_transfer">Bank Transfer</option>
                    <option value="UPI">UPI</option>
                  </select>
                </div>
              )}

              {currentStep === 2 && selectedMethod === "Bank_transfer" && (
                <>
                  <div className="mb-3">
                    <label
                      htmlFor="accountname"
                      className="form-label mob-font mb-1"
                    >
                      Account Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="accountname"
                      value={userdata?.name}
                      disabled
                    />
                    <div className="d-flex gap-1 text-lgray mt-1">
                      <i className="bi bi-info-circle"></i>
                      <div className="mob-font">
                        If it is different from your real name, please contact
                        Customer Support to have it amended
                      </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="accountnumber"
                      className="form-label mob-font mb-1"
                    >
                      Bank Account Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="accountnumber"
                      placeholder="Enter Account Number"
                      value={accountNumber}
                      onChange={(e) => setAccountNumber(e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="ifsc"
                      className="form-label mob-font mb-1"
                    >
                      IFSC Code
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="ifsc"
                      placeholder="Enter IFSC Code"
                      value={ifscCode}
                      onChange={(e) => setIfscCode(e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="bankname"
                      className="form-label mob-font mb-1"
                    >
                      Bank Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="bankname"
                      placeholder="Please Enter Bank Name"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                    />
                  </div>
                </>
              )}

              {currentStep === 2 && selectedMethod === "UPI" && (
                <>
                  <div id="upi-section">
                    <div className="mb-3">
                      <label
                        htmlFor="upiname"
                        className="form-label mob-font mb-1"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="upiname"
                        value={userdata?.name}
                        disabled
                      />
                      <div className="d-flex gap-1 text-lgray mt-1">
                        <i className="bi bi-info-circle"></i>
                        <div className="mob-font">
                          If it is different from your real name, please contact
                          Customer Support to have it amended
                        </div>
                      </div>
                    </div>

                    <div>
                    <label
                        htmlFor="upi_id"
                        className="form-label mob-font mb-1"
                      >
                        UPI ID
                      </label>
                    <div className="input-group mb-2">
                      <input
                        type="text"
                        className="form-control"
                        id="upi_id"
                        value={upiID}
                        onChange={(e) => setUpiID(e.target.value)}
                      />
                      <span class="input-group-text px-3 py-0 bg-success" id="basic-addon2" onClick={()=>{handleVarify()}}>
                      {loader ? (
                        <div
                          className="spinner-border text-black load-icon mx-1"
                          role="status"
                        ></div>
                      ) : 
                      <>
                        <span className="text-white">Verify</span>
                      </>
                      
                      }
                      </span>
                    </div>
                    <div className="fs-12 mb-3 text-success"> {uPIData? "Kyc Name Match:-" + uPIData?.full_name : ""}</div>
                    </div>

                    <div className="mb-3">
                      <label
                        htmlFor="upi_id"
                        className="form-label mob-font mb-1"
                      >
                        QR Code
                      </label>
                      <label className="custom-file-upload">
                      {imagePreviewUrl && (
                        <div className="mt-3">
                          <img
                            src={imagePreviewUrl}
                            alt="QR Code Preview"
                            style={{ maxWidth: '100%', height: 'auto', border: '1px solid #ddd', borderRadius: '4px' }}
                          />
                        </div>
                      )}
                      {!imagePreviewUrl && (
                        <>
                        <i className="fa fa-plus"></i>
                        <input type="file"  onChange={(e) => {
                            uploadIMG(e);
                          }} />
                        </>
                      )}
                        
                      </label>
                    </div>
                  </div>
                </>
              )}

              {(currentStep === 3 && selectedMethod === "Bank_transfer") && (
                <div className="p-4 rounded border border-dark card text-white">
                  <h2 className="fs-6 fw-bold text-uppercase mb-3">Confirm Your Details</h2>
                  <div className="mb-2 d-flex justify-content-between align-items-center">
                    <span>Payment Method:</span>
                    <span className="fw-bold">
                      {selectedMethod === "Bank_transfer" ? "Bank Transfer" : "UPI"}
                    </span>
                  </div>
                  {selectedMethod === "Bank_transfer" ? (
                    <>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <span>Account Name:</span>
                        <span className="fw-bold">{userdata?.name}</span>
                      </div>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <span>Account Number:</span>
                        <span className="fw-bold">{accountNumber}</span>
                      </div>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <span>IFSC Code:</span>
                        <span className="fw-bold">{ifscCode}</span>
                      </div>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <span>Bank Name:</span>
                        <span className="fw-bold">{bankName}</span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <span>Name:</span>
                        <span className="fw-bold">{userdata?.name}</span>
                      </div>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <span>UPI ID:</span>
                        <span className="fw-bold">{upiID}</span>
                      </div>
                    </>
                  )}
                </div>
              )}

            </div>

            <div className="p-3">
              <div className="row">
              { (selectedMethod === "UPI" && uPIData) && <div className="col-lg-12 mb-2">
                  <div className="d-grid">
                    <button
                      type="button"
                      className="btn custom_btn w-100 border-0 text-dark"
                      onClick={handleConfirm}
                    >
                      Save
                    </button>
                  </div>
                </div>}
               { selectedMethod === "Bank_transfer" && <div className="col-lg-6 mb-2">
                  <div className="d-grid">
                    <button
                      type="button"
                      className="btn custom_btn w-100 border-0 text-dark"
                      onClick={currentStep === 3 ? handleConfirm : handleNext}
                    >
                      {currentStep === 3 ? "Confirm" : "Next"}
                    </button>
                  </div>
                </div>}
                <div className= { selectedMethod === "Bank_transfer" ?"col-lg-6 mb-2":"col-lg-12 mb-2"}>
                  <div className="d-grid">
                    <button
                      type="button"
                      data-bs-dismiss="modal"
                      className="custom_btn_outline"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div
      className="modal fade"
      id="updateAccount"
      tabIndex="-1"
      aria-labelledby="updateAccount"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content p-3">
          <div className="modal-header border-0">
            <h1 className="fs-6 fw-bold" id="updateAccount">
              Modify Payment Method
            </h1>
            <Link data-bs-dismiss="modal" aria-label="Close" id="UPDATE_DATA-modal" onClick={handleCancel}>
              <i className="fa-solid fa-xmark icon-color fs-4"></i>
            </Link>
          </div>
          <div
            className="modal-body scrollbar"
            style={{ maxHeight: "450px", overflow: "auto" }}
          >
            {/* Conditional Rendering Based on Selected Method */}
            {selectedMethod === "Bank_transfer" && (
              <>
              <div className="mb-3">
                    <label
                      htmlFor="accountname"
                      className="form-label mob-font mb-1"
                    >
                      Account Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="accountname"
                      value={userdata?.name}
                      disabled
                    />
                    <div className="d-flex gap-1 text-lgray mt-1">
                      <i className="bi bi-info-circle"></i>
                      <div className="mob-font">
                        If it is different from your real name, please contact
                        Customer Support to have it amended
                      </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="accountnumber"
                      className="form-label mob-font mb-1"
                    >
                      Bank Account Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="accountnumber"
                      placeholder="Enter Account Number"
                      value={accountNumber}
                      onChange={(e) => setAccountNumber(e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="ifsc"
                      className="form-label mob-font mb-1"
                    >
                      IFSC Code
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="ifsc"
                      placeholder="Enter IFSC Code"
                      value={ifscCode}
                      onChange={(e) => setIfscCode(e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="bankname"
                      className="form-label mob-font mb-1"
                    >
                      Bank Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="bankname"
                      placeholder="Please Enter Bank Name"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                    />
                  </div>
              </>
            )}

            {selectedMethod === "UPI" && (
              <div id="upi-section">
                    <div className="mb-3">
                      <label
                        htmlFor="upiname"
                        className="form-label mob-font mb-1"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="upiname"
                        value={userdata?.name}
                        disabled
                      />
                      <div className="d-flex gap-1 text-lgray mt-1">
                        <i className="bi bi-info-circle"></i>
                        <div className="mob-font">
                          If it is different from your real name, please contact
                          Customer Support to have it amended
                        </div>
                      </div>
                    </div>

                    <div>
                    <label
                        htmlFor="upi_id"
                        className="form-label mob-font mb-1"
                      >
                        UPI ID
                      </label>
                    <div className="input-group mb-2">
                      <input
                      readOnly
                        type="text"
                        className="form-control"
                        id="upi_id"
                        value={
                          upiDetails?.find((detail) => detail._id === updateId)?.data?.upi_id || ""
                        }
                        // value={upiID}
                        // onChange={(e) => setUpiID(e.target.value)}
                      />
                      {/* <span class="input-group-text px-3 py-0 bg-success" id="basic-addon2" onClick={()=>{handleVarify()}}>
                      {loader ? (
                        <div
                          className="spinner-border text-black load-icon mx-1"
                          role="status"
                        ></div>
                      ) : 
                      <>
                        <span className="text-white">Verify</span>
                      </>
                      
                      }
                      </span> */}
                    </div>
                    <div className="fs-12 mb-3 text-success"> {uPIData? "Kyc Name Match:-" + uPIData?.full_name : ""}</div>
                    </div>


                    <div className="mb-3">
                      <label
                        htmlFor="upi_id"
                        className="form-label mob-font mb-1"
                      >
                        QR Code
                      </label>
                      <label className="custom-file-upload">
                      {imagePreviewUrl && (
                        <div className="mt-3">
                          <img
                            src={imagePreviewUrl}
                            alt="QR Code Preview"
                            style={{ maxWidth: '100%', height: 'auto', border: '1px solid #ddd', borderRadius: '4px' }}
                          />
                        </div>
                      )}
                      {!imagePreviewUrl && (
                        <>
                        <i className="fa fa-plus"></i>
                        <input type="file"  onChange={(e) => {
                            uploadIMG(e);
                          }} />
                        </>
                      )}
                        
                      </label>
                    </div>
                  </div>
            )}
          </div>

          <div className="p-3">
            <div className="row">
            { (selectedMethod === "UPI" && uPIData) && <div className="col-lg-12 mb-2">
                  <div className="d-grid">
                    <button
                      type="button"
                      className="btn custom_btn w-100 border-0 text-dark"
                      onClick={handleConfirm}
                    >
                      Save
                    </button>
                  </div>
                </div>}
             {selectedMethod === "Bank_transfer" && <div className="col-lg-6 mb-2">
                <div className="d-grid">
                  <button
                    type="button"
                    className="btn custom_btn w-100 border-0 text-dark"
                    onClick={handleConfirm}
                  >
                    Confirm
                  </button>
                </div>
              </div>}
              <div className={selectedMethod === "Bank_transfer" ? "col-lg-6 mb-2" :"col-lg-12 mb-2"}>
                <div className="d-grid">
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    className="custom_btn_outline"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>




      {/* <div
        className="modal fade"
        id="addnewaccount"
        tabIndex="-1"
        aria-labelledby="addnewaccount"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-3">
            <div className="modal-header border-0">
              <h1 className="fs-6 fw-bold" id="addnewaccount">
                Add New Account
              </h1>
              <Link data-bs-dismiss="modal" aria-label="Close">
                <i className="fa-solid fa-xmark icon-color fs-4"></i>
              </Link>
            </div>
            <div
              className="modal-body scrollbar"
              style={{ maxHeight: "450px", overflow: "auto" }}
            >
              <div className="mb-3">
                <label
                  htmlFor="paymentmethod"
                  className="form-label mob-font mb-1"
                >
                  Payment Method
                </label>
                <select
                  className="form-select"
                  id="paymentmethod"
                  value={selectedMethod}
                  onChange={handlePaymentMethodChange}
                >
                  <option value="">Select Payment Method</option>
                  <option value="Bank_transfer">Bank Transfer</option>
                  <option value="UPI">UPI</option>
                </select>
              </div>

              {selectedMethod === "Bank_transfer" && (
                <div id="banktransfer-section">
                  <div className="mb-3">
                    <label
                      htmlFor="accountname"
                      className="form-label mob-font mb-1"
                    >
                      Account Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="accountname"
                      value="Aman Kumar Pandey"
                      disabled
                    />
                    <div className="d-flex gap-1 text-lgray mt-1">
                      <i className="bi bi-info-circle"></i>
                      <div className="mob-font">
                        If it is different from your real name, please contact
                        Customer Support to have it amended
                      </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="accountnumber"
                      className="form-label mob-font mb-1"
                    >
                      Bank Account Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="accountnumber"
                      placeholder="Enter Account Number"
                    />
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="bankbranch"
                      className="form-label mob-font mb-1"
                    >
                      Bank Branch
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="bankbranch"
                      placeholder="Enter Bank Branch"
                    />
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="bankname"
                      className="form-label mob-font mb-1"
                    >
                      Bank Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="bankname"
                      placeholder="Please Enter Bank Name"
                    />
                  </div>
                </div>
              )}

              {selectedMethod === "UPI" && (
                <div id="upi-section">
                  <div className="mb-3">
                    <label
                      htmlFor="upiname"
                      className="form-label mob-font mb-1"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="upiname"
                      value="Aman Kumar Pandey"
                      disabled
                    />
                    <div className="d-flex gap-1 text-lgray mt-1">
                      <i className="bi bi-info-circle"></i>
                      <div className="mob-font">
                        If it is different from your real name, please contact
                        Customer Support to have it amended
                      </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="upi_id"
                      className="form-label mob-font mb-1"
                    >
                      UPI ID
                    </label>
                    <input type="text" className="form-control" id="upi_id" />
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="upi_id"
                      className="form-label mob-font mb-1"
                    >
                      QR Code
                    </label>
                    <label className="custom-file-upload">
                      <i className="fa fa-plus"></i>
                      <input type="file" />
                    </label>
                  </div>
                </div>
              )}
            </div>

            <div className="p-3">
              <div className="row">
                <div className="col-lg-6 mb-2">
                  <div className="d-grid">
                    <button
                      type="button"
                      className="btn custom_btn w-100 border-0 text-dark"
                    >
                      Confirm
                    </button>
                  </div>
                </div>
                <div className="col-lg-6 mb-2">
                  <div className="d-grid">
                    <button
                      type="button"
                      data-bs-dismiss="modal"
                      className="custom_btn_outline "
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}


      {/* <div
      className="modal fade"
      id="updateAccount"
      tabIndex="-1"
      aria-labelledby="updateAccount"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content p-3">
          <div className="modal-header border-0">
            <h1 className="fs-6 fw-bold" id="updateAccount">
              Modify Payment Method
            </h1>
            <Link data-bs-dismiss="modal" aria-label="Close">
              <i className="fa-solid fa-xmark icon-color fs-4"></i>
            </Link>
          </div>
          <div
            className="modal-body scrollbar"
            style={{ maxHeight: "450px", overflow: "auto" }}
          >
            <div id="banktransfer-section">

            <div className="mb-3">
              <label
                htmlFor="paymentmethod"
                className="form-label mob-font mb-1"
              >
                Payment Method
              </label>
              <div
                className="form-control"
                id="paymentmethod"
              >
                {selectedMethod2 === "Bank_transfer" ? "Bank Transfer" : "UPI"}
              </div>
            </div>
              <div className="mb-3">
                <label
                  htmlFor="accountname"
                  className="form-label mob-font mb-1"
                >
                  Account Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="accountname"
                  value={accountName}
                  onChange={(e) => setAccountName(e.target.value)}
                />
                <div className="d-flex gap-1 text-lgray mt-1">
                  <i className="bi bi-info-circle"></i>
                  <div className="mob-font">
                    If it is different from your real name, please contact
                    Customer Support to have it amended
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <label
                  htmlFor="accountnumber"
                  className="form-label mob-font mb-1"
                >
                  Bank Account Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="accountnumber"
                  placeholder="Enter Account Number"
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label
                  htmlFor="ifscCode"
                  className="form-label mob-font mb-1"
                >
                  Bank Branch
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="ifscCode"
                  placeholder="Enter Bank Branch"
                  value={ifscCode}
                  onChange={(e) => setIfscCode(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label
                  htmlFor="bankname"
                  className="form-label mob-font mb-1"
                >
                  Bank Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="bankname"
                  placeholder="Please Enter Bank Name"
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="p-3">
            <div className="row">
              <div className="col-lg-6 mb-2">
                <div className="d-grid">
                  <button
                    type="button"
                    className="btn custom_btn w-100 border-0 text-dark"
                    onClick={handleConfirm}
                  >
                    Confirm
                  </button>
                </div>
              </div>
              <div className="col-lg-6 mb-2">
                <div className="d-grid">
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    className="custom_btn_outline"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
    </>
  );
};

export default Bank_verification;
