import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setEmailOrMobile, setUserEmail } from "../utils/apis";
import { fetchUserHandler } from "../utils/actionHandler";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";
import { MdOutlineMailOutline } from "react-icons/md";

const Change_email = () => {
  const { user, sessionId, userdata } = useSelector((state) => state.user);
  const [email, setEmail] = useState("");
  const [loader, setloader] = useState(false);
  const [isOtpVisible, setIsOtpVisible] = useState(false);
  const [otp, setOtp] = useState("");

  const dispatch = useDispatch();
  const emailmodelref = useRef();


  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleOtpChange = (otpValue) => {
    setOtp(otpValue);
  };

  const handleConfirmEmail = () => {
    handleSetEmailData()
  };

  function handleSetEmailData() {
    setloader(true);
    setEmailOrMobile("", email, sessionId, otp)
      .then((res) => {
        console.log(res,"RES:::::::_______")
        setloader(false);
        if (res?.status == 200) {
          if(res?.askOtp){
            setIsOtpVisible(true)
          }else {
            fetchUserHandler(email, sessionId, dispatch);
            emailmodelref?.current?.click();
            toast.success(res?.message);
          }
          
        } else {
          toast.error(res?.message);
        }
      })
      .catch((e) => {
        setloader(false);
        console.log(e, "Error in changeEmail");
        toast.error("Something went wrong");
      });
  
}

  return (
    <>
    {!userdata?.ev ? (
      <div className="model_btn">
        <button
          type="button"
          className="button-outline"
          data-bs-toggle="modal"
          data-bs-target="#smsEmailSettingexampleModal"
        >
          Setting
        </button>
      </div>
      ) : (
        <span className=" text-green fs-14">{userdata?.email}</span>
      )}
     

      <div
        className="modal fade"
        id="smsEmailSettingexampleModal"
        tabIndex="-1"
        aria-labelledby="smsEmailSettingexampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-3">
            <div className="modal-header border-0">
              <h1 className="modal-title fs-6" id="smsEmailSettingexampleModalLabel">
                Set Email
              </h1>
              <div data-bs-dismiss="modal" aria-label="Close">
                <i className="fa-solid fa-xmark fs-4 icon-color" ref={emailmodelref} />
              </div>
            </div>
            <div className="modal-body">
              <div>
                <div className="mb-3">
                  <label
                    htmlFor="email-address"
                    className="form-label mob-font text-muted"
                  >
                   Enter a new Email
                  </label>
            
                  <div className="input-group-- d-flex mb-3 gap-1">
                    <div className="input-group-text" id="email-address">
                    <MdOutlineMailOutline />
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      id="email-address"
                      placeholder="New Email"
                      value={email}
                      onChange={handleEmailChange}
                    />
                  </div>
                </div>

                {isOtpVisible && (
                  <>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label mob-font text-muted"
                      >
                        A verification code will be sent to{" "}
                        <strong>{email}</strong>
                      </label>
                      <div className="mb-3">
                        <OtpInput
                          value={otp}
                          onChange={handleOtpChange}
                          numInputs={6}
                          renderSeparator={<span className="mx-1"></span>}
                          renderInput={(props) => (
                            <input
                              {...props}
                              className="form-control"
                              style={{ width: "50px" }}
                            />
                          )}
                          containerStyle={{
                            justifyContent: "center",
                            // paddingBlock: "2%",
                          }}
                        />
                      </div>

                      <div className="text-center fs-13">
                        Did'nt received OTP?{" "}
                        <Link className="anchor_link"> Resend </Link>
                      </div>
                    </div>
                  </>
                )}

                <div className="d-grid mb-3">
                  <button
                    className="custom_btn"
                    type="button"
                    onClick={handleConfirmEmail}
                  >
                  {loader ? (
                    <div
                      className="spinner-border text-black load-icon mx-1"
                      role="status"
                    ></div>
                  ) : null}
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Change_email;
