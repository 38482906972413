import React, { useEffect, useState } from "react";
import Sidebar from "../Component/Sidebar";
import Header from "../Component/Header";
import { useDispatch, useSelector } from "react-redux";
import { withdrawDetails, withdrawINR, withdrawINRBankDetails, withdrawOtpSend } from "../utils/apis";
import { toast } from "react-toastify";
import { cutAfterDecimal, fetchWalletHandler } from "../utils/actionHandler";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";

const Withdraw_inr = () => {
  const { user, sessionId, userdata } = useSelector((state) => state.user);
  const { assets } = useSelector((state) => state.wallet);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [amount, setAmount] = useState("");
  const [otp, setotp] = useState("");
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [step, setStep] = useState(0);
  const [withdrawDetailsData, setWithdrawDetails] = useState({})
  const [bankData, setBankData] = useState([])
  const gettoken = assets.find((it) => it.symbol == "INR");
  const [selectedBankId, setSelectedBankId] = useState(null);
  const [mobileOtp, setMobileOtp] = useState("");
  const [emailOtp, setEmailOtp] = useState("");
  const handleSelection = (id) => {
    setSelectedBankId(id);
  };

  function withdrawOtp(){
    const data = {
      email: userdata.email,
      mobile: userdata.mobile,
      tokenId: sessionId,
      amount: amount,
      coin: "INR",
      type: "INR"
    };

    if (userdata?.mobile && userdata?.email && sessionId && amount && selectedBankId){
    setLoading(true);
    withdrawOtpSend(data)
    .then((res) => {
      if (res.status == 200) {
        setStep(1)
        // const modal = new window.bootstrap.Modal(document.getElementById('verification_modal'));
        // modal.show();
        toast.success(res.message);
      } else {
        console.log(res);
        toast.error(res?.message);
      }
      setLoading(false);
    })
    .catch((e) => {
      setLoading(false);
      toast.error("Something went wrong!");
      console.log(e, "Error in withdraw():Withdraw_inr.jsx");
    });
  } else{
      toast.error("Something went wrong!")
  }
  }

  function withdraw() {
    const dataWithDraw = {
      email: userdata.email,
      mobile: userdata.mobile,
      amount: amount,
      tokenId: sessionId,
      bankId: selectedBankId,
      emailOtp: emailOtp,
      mobileOtp: mobileOtp
    };
    setLoading(true);
    withdrawINR(dataWithDraw)
      .then((res) => {
        setLoading(false);
        setAmount("");
        setotp("");
        setStep(0);
        if (res?.status == 200) {
          fetchWalletHandler(userdata?.mobile, sessionId, dispatch);
          toast.success(res?.message);
        } else {
          toast.error(res?.message);
        }
      })
      .catch((e) => {
        setStep(0);
        setLoading(false);
        console.log(e, "Error in withsraw inr");
      });
  }

  useEffect(() => {
    if (otp?.length == 6) {
      withdraw();
    }
  }, [otp]);

  useEffect(()=>{
    const withdrawDataBank = {
      email: userdata?.email,
      mobile: userdata?.mobile,
      tokenId: sessionId
    }
    setLoader(true);
    withdrawINRBankDetails(withdrawDataBank)
      .then((res) => {
        if (res?.status === 200) {
          setBankData(res?.data);
          setLoader(false);
        } else {
          setLoader(false);
          console.log("Failed to retrieve bank data:", res?.message);
        }
      })
      .catch((e) => {
        setLoader(false);
        console.log(e, "Error in withdrawINRBankDetails: withdraw page");
      })
    withdrawDetails().then((res) => {
      if (res?.status == 200) {
        setWithdrawDetails(res?.data);
      }
    })
    .catch((e) => {
      console.log(e, "Error in setWithdrawList:withdraw page");
    });
  },[userdata, sessionId])

  return (
    <>
      <Header />
      <Sidebar />

      <main className="mt-5 pt-5">
        <div className="container-fluid">
          <h1 className="heading text-center">
            Withdraw <em className="fst-normal text-green">INR</em>
          </h1>
          <div className="containser">
            <div className="col-xl-5 col-lg-6 m-auto">
              <div className="row secondaryForm">
                <div className="col-lg-12">
                  {step == 0 ? (
                    <>
                      <div className="mb-2 mt-sm-3 d-flex justify-content-between ">
                        <div className="text-muted me-2">Amount</div>
                      </div>
                      <div className="mb-3">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter withdraw Amount"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                          />

                          <span
                            className="input-group-text"
                            id="basic-addon1"
                            onClick={() => {
                              setAmount(
                                gettoken?.available
                                  ? cutAfterDecimal(gettoken?.available, 4)
                                  : 0.0
                              );
                            }}
                          >
                            MAX
                          </span>
                        </div>
                        <div className="text-end mt-2">
                          <span className="text-muted"> Balance:</span>
                          <span className="fw-bold text-green ms-2">
                            {gettoken?.available
                              ? cutAfterDecimal(gettoken?.available, 4)
                              : 0.0}
                          </span>
                        </div>

                        <div className="d-flex justify-content-between fs-12 mt-2">
                          <div className="text-muted">
                            Minimum Withdrawal Amount
                          </div>
                          <div className="fw-bold">{withdrawDetailsData?.minimum_withdraw_inr} INR</div>
                        </div>

                        <div className="d-flex justify-content-between fs-12">
                          <div className="text-muted">Withdrawal Fee</div>
                          <div className="fw-bold">{withdrawDetailsData?.withdraw_inr_fee} INR</div>
                        </div>
                      </div>
                      <div className="">
                  <div>
                    {loader ? (
                      <div className="d-flex justify-content-center align-items-center p-3">
                      <div className="spinner-border text-black load-icon mx-1" role="status">
                        <span className="sr-only"></span>
                      </div>
                      Loading...
                      </div>
                    ) : (
                      <>
                        {bankData.length > 0 ? (
                          <>
                            <div className="card mb-1">
                              {bankData.map((item, i) => (
                                <React.Fragment key={i}>
                                  {item?.type === "bankAccount" && (
                                    <div className="card-body">
                                      <div className="">
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name="accountSelection"
                                            id={`bank-${item._id}`}
                                            value={item._id}
                                            checked={selectedBankId === item._id}
                                            onChange={() => handleSelection(item._id)}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={`bank-${item._id}`}
                                          >
                                            Bank Account
                                          </label>
                                        </div>
                                        <div className="ms-4">
                                          <div className="d-flex justify-content-between mb-2">
                                            <div className="text-muted">Transfer Mode</div>
                                            <div>
                                              <em className="fst-normal text-success">
                                                Bank
                                              </em>
                                            </div>
                                          </div>
                                          <div className="d-flex justify-content-between mb-2">
                                            <div className="text-muted">Bank Name</div>
                                            <div>{item?.data?.ifsc_details?.bank_name}</div>
                                          </div>
                                          <div className="d-flex justify-content-between mb-2">
                                            <div className="text-muted">Account Number</div>
                                            <div>{item?.account_number}</div>
                                          </div>
                                          <div className="d-flex justify-content-between mb-2">
                                            <div className="text-muted">IFSC</div>
                                            <div>{item?.data?.ifsc_details?.ifsc}</div>
                                          </div>
                                          <div className="d-flex justify-content-between mb-2">
                                            <div className="text-muted">Name</div>
                                            <div>{item?.data?.full_name}</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                    
                                  {item?.type === "upiAccount" && (
                                    <div className="card-body">
                                      <div className="">
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name="accountSelection"
                                            id={`upi-${item._id}`}
                                            value={item._id}
                                            checked={selectedBankId === item._id}
                                            onChange={() => handleSelection(item._id)}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={`upi-${item._id}`}
                                          >
                                            UPI Account
                                          </label>
                                        </div>
                                        <div className="ms-4">
                                          <div className="d-flex justify-content-between mb-2">
                                            <div className="text-muted">Transfer Mode</div>
                                            <div>
                                              <em className="fst-normal text-success">
                                                UPI
                                              </em>
                                            </div>
                                          </div>
                                          <div className="d-flex justify-content-between mb-2">
                                            <div className="text-muted">UPI Id</div>
                                            <div>{item?.data?.upi_id}</div>
                                          </div>
                                          <div className="d-flex justify-content-between mb-2">
                                            <div className="text-muted">Name</div>
                                            <div>{item?.data?.full_name}</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  <hr/>
                                </React.Fragment>
                              ))}
                            </div>
                          </>
                        ) : (
                          <div className="alert alert-warning">
                            No bank or UPI accounts found. Please add an account.
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  </div>
                  <div className="d-grid mb-3">
                        <button
                          
                          className="custom_btn"
                          type="button"
                          // data-bs-toggle="modal"
                          // data-bs-target="#verification_modal"
                          onClick={withdrawOtp}
                          disabled={loading}

                        >
                          {loading ? (
                            <div
                              className="spinner-border text-black load-icon mx-1"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : null}
                          Withdraw
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="text-center mb-3">
                      <div className="">
                      <div className="mb-3">
                        <p className="text-center mb-0">Enter 6-digit OTP sent to your registered email id</p>
                        <OTPInput
                            value={emailOtp}
                            onChange={setEmailOtp}
                            inputMode="numeric"
                            numInputs={6}
                            renderSeparator={
                              <span className="text-secondary mx-1"></span>
                            }
                            renderInput={(props) => (
                              <input
                                {...props}
                                className="form-control"
                                style={{ width: "40px" }}
                                inputMode="numeric" // Ensures numeric keypad on mobile
                                pattern="[0-9]*" // Enforces numeric input
                                type="tel"
                                onKeyPress={(e) => {
                                  if (!/[0-9]/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            )}
                            containerStyle={{
                              justifyContent: "center",
                              paddingBlock: "2%",
                            }}
                          />
                      </div>

                        <hr/>

                      <div className="mb-3">
                        <p className="text-center mb-0">Enter 6-digit OTP sent to your registered mobile number</p>
                        <OTPInput
                            value={mobileOtp}
                            onChange={setMobileOtp}
                            inputMode="numeric"
                            numInputs={6}
                            renderSeparator={
                              <span className="text-secondary mx-1"></span>
                            }
                            renderInput={(props) => (
                              <input
                                {...props}
                                className="form-control"
                                style={{ width: "40px" }}
                                inputMode="numeric" // Ensures numeric keypad on mobile
                                pattern="[0-9]*" // Enforces numeric input
                                type="tel"
                                onKeyPress={(e) => {
                                  if (!/[0-9]/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            )}
                            containerStyle={{
                              justifyContent: "center",
                              paddingBlock: "2%",
                            }}
                          />
                      </div>
                      <div className="w-100 mb-3">
                        <button
                          className="custom_btn w-100"
                          type="button"
                          onClick={withdraw}
                          disabled={loading}
                        >
                          {loading ? (
                            <div
                              className="spinner-border text-black load-icon mx-1"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : null}
                          Withdraw
                        </button>
                      </div>
                      </div>
                    </div>
                  )}
               
                  
                  </div>
                {/* <div className="col-lg-1"></div> */}
                {/* <div className="col-lg-5">
              <h5 className="fw-bold">FAQs</h5>

              <div>
                <div className="support_main sec_inr">
                  <div
                    className="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h2
                        className="accordion-header mob-font text-muted"
                        id="flush-headingOne"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          Why can’t I withdraw funds after multiple attempts?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapse "
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Placeholder content for this accordion, which is
                          intended to demonstrate the{" "}
                          <code>.accordion-flush</code> class. This is the first
                          item's accordion body.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button
                          className="accordion-button collapsed text-muted mob-font"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          Why does request processing take a long time?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Placeholder content for this accordion, which is
                          intended to demonstrate the{" "}
                          <code>.accordion-flush</code> class. This is the
                          second item's accordion body. Let's imagine this being
                          filled with some actual content.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Withdraw_inr;
