import React, { useEffect } from "react";
import Sidebar from "../Component/Sidebar";
import Header from "../Component/Header";
import Sell from "./Sell";
import Buy from "./Buy";
import ApexChart from "../Component/ApexChart";
import { useDispatch, useSelector } from "react-redux";
import { cutAfterDecimal, stakeHistory, stakeRefHistory } from "../utils/actionHandler";
import Exchange from "./Exchange";
import { aprSet } from "../utils/apis";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const { total, totaldeposit, allstakes } = useSelector(
    (state) => state.wallet
  );
  const { user, userdata, sessionId } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      stakeHistory(userdata, dispatch, sessionId, () => {});
      stakeRefHistory(userdata, dispatch, sessionId, () => {});

    }, 1500);
  }, [userdata]);

  return (
    <>
      <Header />
      <Sidebar />

      <main className="mt-5 pt-5">
        <div>
          <div className="row">
            <div className="col-xl-8 col-lg-12 col-md-12 mb-3 col-sm-12 mb-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="dashboard_banner mb-5">
                    <h2>
                    Get Participate in Allahabad Holy City Event <br/>
                    Get Rewarded { " "}
                      <em className="fst-normal" style={{ color: "#CCF888" }}>
                      Upto 10,000 Instant
                      </em>{" "}
                      <br />
                    </h2>
                    <Link
                      to="/assets"
                      className="btn btn-lg bannerbutton mt-2"
                    >
                      Claim Now
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-sm-4 col-lg-4 col-6">
                  <div className="total-assets-card bg_card1">
                    <div className="card-icon card-icon1">
                      <img src="images/asset-light.svg" alt="" />
                    </div>
                    <div className="amount">
                      ₹{total ? cutAfterDecimal(total, 4) : 0}
                    </div>
                    <div className="asset-text">Total Assets</div>
                  </div>
                </div>
                <div className="col-sm-4 col-lg-4 col-6">
                  <div className="total-assets-card bg_card2">
                    <div className="card-icon card-icon2">
                      <img src="images/assets.svg" alt="" />
                    </div>
                    <div className="amount">
                      {allstakes
                        ? cutAfterDecimal(allstakes?.INRx?.stakeAmount, 4)
                        : "0.00"}{" "}
                      INRx
                    </div>
                    <div className="asset-text">Total Stake</div>
                  </div>
                </div>
                <div className="col-sm-4 col-lg-4 col-6">
                  <div className="total-assets-card bg_card3">
                    <div className="card-icon card-icon3">
                      <img src="images/apy.svg" alt="" />
                    </div>
                    <div className="amount">{aprSet}%</div>
                    <div className="asset-text">APR</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                 <ApexChart />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 mb-3">
              <div className="card text-center mb-4">
                <div className="card-body">
                  <div className="arc_circle">
                    <img
                      src={userdata?.profile_image?.indexOf('https')>-1?
                        userdata?.profile_image:
                        userdata?.profile_image
                          ? `data:image/jpeg;base64,${userdata?.profile_image}`
                          : "/images/user.svg"
                      }
                      alt=""
                      className="userprofile"
                    />
                  </div>
                  <div className="">
                    <p className="mb-0 fs-5 fw-medium">Total Balance</p>
                    <p className="fs-3 fw-bold">
                      ₹{total ? cutAfterDecimal(total, 4) : 0}
                    </p>
                    <div className="d-flex gap-2 justify-content-center align-items-center mb-4 mt-3">
                      <div className="user_per">+12.34%</div>
                      <div className="user_arrowup">
                        <i className="bi bi-arrow-up-right "></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="card text-center  mb-4 mt-3 dashbord-card"
                style={{ height: "450px" }}
              >
                <div className="card-body px-lg-5">
                  <div className="">
                    <ul className="nav nav-pills gap-2">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-bs-toggle="pill"
                          href="#Buy"
                          role="tab"
                          aria-controls="Buy"
                          aria-selected="true"
                        >
                          Buy
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="pill"
                          href="#Sell"
                          role="tab"
                          aria-controls="Sell"
                          aria-selected="false"
                        >
                          Sell
                        </a>
                      </li>
                      {/* <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="pill"
                          href="#Exchange"
                          role="tab"
                          aria-controls="Exchange"
                          aria-selected="false"
                        >
                          Exchange
                        </a>
                      </li> */}
                    </ul>
                    <div className="tab-content mt-3">
                      <div
                        className="tab-pane fade show active"
                        id="Buy"
                        role="tabpanel"
                        aria-labelledby="Buy-tab"
                      >
                        <Buy />
                      </div>
                      <div
                        className="tab-pane fade"
                        id="Sell"
                        role="tabpanel"
                        aria-labelledby="Sell-tab"
                      >
                        <Sell />
                      </div>
                      <div
                        className="tab-pane fade"
                        id="Exchange"
                        role="tabpanel"
                        aria-labelledby="Exchange-tab"
                      >
                        <Exchange />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Dashboard;
