import React, { useEffect, useState } from "react";
import Header from "../Component/Header";
import Sidebar from "../Component/Sidebar";
import { Link } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { MdOutlineDoubleArrow } from "react-icons/md";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { toast } from "react-toastify";
import {  
  generateAadharOtp,
  verifyUserAutoKYC,
  verifyUserKYC,
} from "../utils/apis";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserHandler } from "../utils/actionHandler";
import OtpInput from "react-otp-input";

const Identity_verification = () => {
  const { user, sessionId, userdata } = useSelector((state) => state.user);
  const [activeKycType, setActiveKycType] = useState(1);
  const [activeDocument, setActiveDocument] = useState(0);
  const [step, setStep] = useState(-1); // 0: Document selection, 1: Fill document info, 2: Upload front, 3: Upload back
  const [doc_number, setDocNumber] = useState("");
  const [doc_front, setDocFront] = useState("");
  const [doc_back, setDocBack] = useState("");
  const [mobile, setMobile] = useState("");
  const [loading, setLoading] = useState(false);
  const [region, setRegion] = useState({ country: "", region: "" });
  const [clientId, setClientId] = useState("");
  const [otp, setOtp] = useState("");

  const dispatch = useDispatch();
  const doctypes = [
    { type: "pan", item: "Pan Card", icon: "/images/pan-card.svg", auto: true },
    {
      type: "passport",
      item: "Passport",
      icon: "/images/passport.svg",
      auto: false,
    },
    {
      type: "voterid",
      item: "Voter ID",
      icon: "/images/voter-id.svg",
      auto: false,
    },
    {
      type: "adhaar",
      item: "Aadhar Card",
      icon: "/images/aadhaar-card.svg",
      auto: true,
    },
  ];

  const dockycType = [
    { type: "manual", item: "Manual kyc", icon: "/images/passport.svg" },
    { type: "auto", item: "Auto Kyc", icon: "/images/pan-card.svg" },
  ];

  console.log(user,"user")
  const handleConfirmClick = () => {
    // console.log(region, "region");
    // if (!region?.country) {
    //   toast.info("Please select country");
    //   return;
    // }

    // if (!region?.region) {
    //   toast.info("Please select state");
    //   return;
    // }

    setStep(1);
  };

  const handleContinueClick = () => {
    if (!doc_number) {
      toast.info("Please enter " + doctypes[activeDocument].item + " Number");
      return;
    }
    // if (!mobile) {
    //   toast.info("Please enter mobile number");
    //   return;
    // }
    setStep(2);
  };

  const handleUploadFrontBackClick = () => {
    if (!doc_front) {
      toast.info("Please upload " + doctypes[activeDocument]?.item + " front");
      return;
    }
    setStep(3);
  };

  const handleBackClick = () => {
    setStep(step - 1);
  };

  const selectCountry = (val) => {
    setRegion({ ...region, country: val });
  };

  const selectRegion = (val) => {
    setRegion({ ...region, region: val });
  };

  // console.log(region, doctypes[activeDocument], ' fhndjkg shf');
  console.log(user,"user",userdata)
  function submitData() {
    setLoading(true);
    verifyUserKYC(
      userdata?.email,
      sessionId,
      doc_number,
      doctypes[activeDocument]?.type,
      region?.country,
      region?.region,
      userdata?.mobile,
      [doc_front, doc_back]
    )
      .then((res) => {
        setLoading(false);
        // console.log(res);
        if (res?.data?.status == 200) {
          setDocBack("");
          setDocFront("");
          setDocNumber("");
          setRegion({ country: "", region: "" });
          setStep(0);
          fetchUserHandler(userdata?.email, sessionId, dispatch);
          const modal = document.getElementById("kyc-modal");
          modal.click();
          toast.success(res?.data?.message);
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log(e, "Erorr in kyc upload");
      });
  }

  function handleConfirmAutoKyc(otp) {
    setLoading(true);
    console.log(userdata?.email, userdata?.mobile, sessionId, "sessionId");
    verifyUserAutoKYC(
      userdata?.email,
      sessionId,
      doc_number,
      doctypes[activeDocument]?.type,
      region?.country,
      region?.region,
      userdata?.mobile,
      clientId,
      otp
      // [doc_front, doc_back]
    )
      .then((res) => {
        setLoading(false);
        console.log(res,"RES:::::");
        if (res?.status == true) {
          if(res.is_pan != true){
            const aadhaarIndex = doctypes.findIndex((it) => it.type === "pan");
            if (aadhaarIndex !== -1) {
              setActiveDocument(aadhaarIndex);
            }
          }else{
            setActiveDocument();
            setStep(0);
            toast.success(res?.message);
            fetchUserHandler(userdata?.email, sessionId, dispatch);
            const modal = document.getElementById("kyc-modal");
            modal.click();
          }
          setClientId("")
          setDocBack("");
          setDocFront("");
          setDocNumber("");
          setRegion({ country: "", region: "" });
         
        } else {
          toast.error(res?.message);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log(e, "Erorr in kyc upload");
        toast.error(e.message)
      });
  }

  function generateOtpApi() {
    console.log(activeDocument,"activeDocument",doctypes[activeDocument]?.type,activeKycType)
    if (doctypes[activeDocument]?.type === "pan") {
      handleConfirmAutoKyc();
    } else {
      setLoading(true);
      generateAadharOtp(
        userdata?.email,
        sessionId,
        doc_number,
        doctypes[activeDocument]?.type,
        userdata?.mobile
      )
        .then((res) => {
          console.log(res, "RSSSS______");
          if (res?.status === true) {
            setClientId(res?.data);
          } else {
            toast.error(res?.message);
          }
          setLoading(false);
          
        })
        .catch((e) => {
          setLoading(false);

          console.log(e, "error");
        });
    }
  }

  const handleOtpChange = async (otpValue) => {
    setOtp(otpValue);
    if (otpValue.length === 6) {
      setLoading(true); // Show loader
      handleConfirmAutoKyc(otpValue); // Replace `useremail` with the actual email or user ID
    }
  };
  
  const  handleClose = async ()=>{

    setActiveKycType(0);
    setActiveDocument(0);
    setClientId("");
    setDocBack("");
    setDocFront("");
    setDocNumber("");
    setRegion({ country: "", region: "" });
    setStep(-1);
  }
  // const handleKeyDown = (event) => {
  //   event.preventDefault(); // Prevent default Enter key behavior
  //   console.log(event,"event")
  //   if (event.key === 'Enter' && activeKycType === 1) {
  //     generateOtpApi();
  //   }
  // };
  return (
    <>
      <Header />
      <Sidebar />
      <main className="mt-5 pt-5 setting_main">
        <div>
          <div>
            <div className="row">
              <div className="col-lg-8 mb-4">
                <div>
                  <div className="verfication_alert">
                    <h3 className="heading mb-1">Verify your identity</h3>
                    for enhanced account security and expanded service access.
                   {(userdata?.ev && userdata?.mobilelogin) && <div className="mt-3">
                      {userdata?.kyc_status == "No Kyc" ||
                      userdata?.kyc_status == "rejected" ? (
                        <button
                          className="btn custom_btn"
                          data-bs-toggle="modal"
                          data-bs-target="#kyc_modal"
                        >
                          KYC Verification{" "}
                          <i className="fa-solid fa-arrow-right" />
                        </button>
                      ) : (
                        <button className="btn custom_btn">
                          KYC {userdata?.kyc_status?.toUpperCase()}
                        </button>
                      )}
                      
                    </div>}
                    {!userdata?.ev && (
                      <div className="mt-3">
                        <Link to="/setting" className="btn custom_btn">
                          Email Verification{" "}
                          <i className="fa-solid fa-arrow-right" />
                        </Link>
                        </div>
                      )}
                      {!userdata?.mobilelogin && (
                        <div className="mt-3">
                        <Link to="/setting" className="btn custom_btn">
                          Mobile Verification{" "}
                          <i className="fa-solid fa-arrow-right" />
                        </Link>
                        </div>
                      )}
                  </div>
                </div>
                <div className="card mt-3">
                  <div className="card-body">
                    <div className="identy_tbl">
                      <h5>
                        <i className="fa-solid fa-gift fs-4 me-2 text-green" />
                        Individual Verification Benefits
                      </h5>
                      <div className="table-responsive mt-3">
                        <table className="table table-borderless">
                          <thead className="tbl-head">
                            <tr>
                              <th scope="col" className="text-start">
                                Service
                              </th>
                              <th scope="col">Individual</th>
                              <th scope="col">Premium</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td scope="row" className="text-start">
                                Deposit
                              </td>
                              <td>Unlimited</td>
                              <td>Unlimited</td>
                            </tr>
                            <tr>
                              <td scope="row" className="text-start">
                                Earn
                              </td>
                              <td>Unlimited</td>
                              <td>Unlimited</td>
                            </tr>
                            <tr>
                              <td scope="row" className="text-start">
                                Daily INR Withdrawal
                              </td>
                              <td>50k</td>
                              <td>
                                <div className="text-green">500K INR</div>
                              </td>
                            </tr>
                            <tr>
                              <td scope="row" className="text-start">
                                Daily INRx Withdrawals
                              </td>
                              <td>100K</td>
                              <td>
                                <div className="text-green">1M INRx</div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="p-3 tbl-head rounded">
                          <h6>Verification requires the following:</h6>
                          <div className="d-flex gap-4 align-items-center">
                            <div>
                              <i className="fa-solid fa-id-card text-green me-2" />
                              <span className="text-color">
                                Government ID Photo
                              </span>
                            </div>
                            <div>
                              <i className="bi bi-check-circle-fill text-green me-2"></i>
                              <span className="text-color">Liveness Check</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 b-4">
                <div className="card ">
                  <div className="card-body">
                    <p className="fs-5">
                      <strong>FAQ</strong>
                    </p>
                    <ul className="faqList">
                      <li>
                        <Link>Why is my country of origin not listed?</Link>
                      </li>
                      <hr />
                      <li>
                        <Link>
                          Are there any Verification Rules I should be aware of?
                        </Link>
                      </li>
                      <hr />
                      <li>
                        <Link>Can I complete verification as a Business?</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <div
        className="modal fade"
        id="kyc_modal"
        tabIndex="-1"
        aria-labelledby="kyc_modalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered scrollbar">
          <div className="modal-content">
            <div className="modal-header pb-0">
              <h5 className="fs-16 fw-bold mb-0" id="kyc_modalLabel">
                Identity Verification
              </h5>
              <button
                type="button"
                className="bg-transparent fs-4 border-0 text-color"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="kyc-modal"
                onClick={()=>handleClose()}
              >
                x
              </button>
            </div>
            <div className="modal-body">
              <div className="px-2">
                {step === -1 &&
                  dockycType.map((item, i) => {
                    if(item?.type ==="auto")
                    return (
                      <div
                        className={`modal_select_options ${
                          activeKycType === i ? "active" : ""
                        }`}
                        onClick={() => {
                          setActiveKycType(i);
                          if (i === 0) {
                            setStep(0);
                          } else {
                            setStep(1);
                            if( userdata?.is_pan === false && userdata?.is_adhaar === true){
                              const aadhaarIndex = doctypes.findIndex((it) => it.type === "pan");
                              if (aadhaarIndex !== -1) {
                                setActiveDocument(aadhaarIndex);
                              }
                            }else {
                              const aadhaarIndex = doctypes.findIndex((it) => it.type === "adhaar");
                              if (aadhaarIndex !== -1) {
                                setActiveDocument(aadhaarIndex);
                              }
                            }
                            
                          }
                        }}
                      >
                        <img
                          src={item?.icon}
                          alt="coin symbole"
                          className="deposit_imgs filter_gray"
                        />
                        <div className="content_div">
                          <div className="mb-0">{item?.item}</div>
                        </div>
                        {activeKycType === i && (
                          <span className="active_symbol">
                            <span className="check_mark">
                              <FaCheck />
                            </span>
                          </span>
                        )}
                      </div>
                    );
                  })}

                {step === 0 && (
                  <>
                    <div id="verification_type" className="secondaryForm">
                      <div className="mb-3">
                        <p className="text-lgray">
                          INRx is currently available only for India 🇮🇳, we
                          accept only Indian Kyc right now
                        </p>
                        {/* <label className="text-muted fs-12 mb-1">
                          <span className="text-red">*</span>Country/Region
                        </label>
                        <div>
                          <CountryDropdown
                            value={region.country}
                            onChange={(val) => selectCountry(val)}
                          />
                        </div> */}

                        {/* <RegionDropdown
                          country={region.country}
                          value={region.region}
                          onChange={(val) => selectRegion(val)}
                        /> */}
                      </div>
                      <div className="mb-5">
                        <label className="text-muted fs-12">
                          <span className="text-red">*</span>Verify With
                        </label>
                        {doctypes.map((item, i) => {
                          if (
                            (activeKycType === 1 && item.auto === true) ||
                            activeKycType === 0
                          )
                            return (
                              <div
                                className={`modal_select_options ${
                                  activeDocument === i ? "active" : ""
                                }`}
                                onClick={() => setActiveDocument(i)}
                              >
                                <img
                                  src={item?.icon}
                                  alt="coin symbole"
                                  className="deposit_imgs filter_gray"
                                />
                                <div className="content_div">
                                  <div className="mb-0">{item?.item}</div>
                                </div>
                                {activeDocument === i && (
                                  <span className="active_symbol">
                                    <span className="check_mark">
                                      <FaCheck />
                                    </span>
                                  </span>
                                )}
                              </div>
                            );
                        })}
                      </div>
                      <div>
                        <h6>Requirements ID</h6>
                        <ul className="fs-12 text-lgray">
                          <li>
                            1. Use your valid government-issued identification
                            document for verification.
                          </li>
                          <li>
                            2. Choose a different document type if you've
                            previously verified another account.
                          </li>
                          <li>
                            3. Ensure that the uploaded document is an original.
                            Please note that photocopies will not be accepted.
                          </li>
                          <li>
                            4. The following documents are not supported:
                            Student Visa, Working Visa, Travel Visa
                          </li>
                        </ul>
                        <h6 className="mt-3">Selfie</h6>
                        <ul className="fs-12 text-lgray">
                          <li>
                            1. Ensure proper lighting and a clutter-free
                            background.
                          </li>
                          <li>2. Frame your face prominently in the shot.</li>
                        </ul>
                      </div>

                      <div className="px-2 py-3">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="d-grid">
                              <button
                                type="button"
                                className="btn button-outline"
                                onClick={handleBackClick}
                                style={{ padding: "0.7rem" }}
                              >
                                Back
                              </button>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="d-grid">
                              <button
                                class="btn custom_btn"
                                onClick={handleConfirmClick}
                              >
                                Continue
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {step === 1 && clientId == "" && (
                  <div>
                    <div className="pt-2">
                      <h6 className="fw-bold mb-3">
                        {" "}
                        Fill Document ({doctypes[activeDocument]?.item}) Info
                      </h6>
                      <div class="row mb-3">
                        <div class="col-lg-12">
                          <p class="text-danger mb-0 fs-14"></p>
                        </div>
                        <div class="col-lg-12">
                          <div class="form-group mb-3">
                            <label for="id_no" className="text-lgray">
                              {doctypes[activeDocument]?.item} Number
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="id_no"
                              name="id_no"
                              value={doc_number}
                              onChange={(e) => setDocNumber(e?.target?.value)}
                            />
                          </div>
                          {/* <div class="form-group mb-3">
                            <label for="id_no" className="text-lgray">
                              Mobile Number
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="id_no"
                              name="id_no"
                              value={mobile}
                              onChange={(e) => setMobile(e?.target?.value)}
                            />
                            <div>
                              <span>Note: </span>
                              <span>Please enter your mobile number</span>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>

                    <div className="mb-3">
                      <div className="row g-2">
                       {activeKycType === 0 && (<div className="col-lg-6">
                          <div className="d-grid">
                            <button
                              type="button"
                              className="btn button-outline"
                              onClick={handleBackClick}
                              style={{ padding: "0.7rem" }}
                            >
                              Back 
                            </button>
                          </div>
                        </div>)}

                        {activeKycType === 0 && (
                          <div className="col-lg-12">
                            <div className="d-grid">
                              <button
                                class="btn custom_btn"
                                onClick={handleContinueClick}
                              >
                                Proceed
                              </button>
                            </div>
                          </div>
                        )}
                        <div 
                          // tabIndex="0" // Allows the div to be focusable to capture keydown events
                          // onKeyDown={handleKeyDown}
                        >
                        {activeKycType === 1 && (
                          <div className="col-lg-12">
                            <div className="d-grid">
                              <button
                                class="btn custom_btn"
                                onClick={generateOtpApi}
                              >
                               {loading ? (
                                  <div className="d-flex align-items-center justify-content-center loader">
                                    <div
                                      className="spinner-border text-black load-icon mx-1"
                                      role="status"
                                    ></div>
                                    Verifying...
                                  </div>
                                ):"Proceed"}
                              </button>
                            </div>
                          </div>
                        )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {clientId != "" && (
                  <div className="mb-3">
                  <label
                        htmlFor="exampleInputEmail1"
                        className="form-label mob-font text-muted"
                      >
                        A verification code will be sent to your mobile number.{" "}
                        {/* <strong>{user?.mobile}</strong> */}
                      </label>
                      <div className="mb-3">
                    <OtpInput
                      value={otp}
                      onChange={handleOtpChange}
                      numInputs={6}
                      renderSeparator={
                        <span className=" text-secondary mx-1">-</span>
                      }
                      renderInput={(props) => (
                        <input
                          {...props}
                          className=" form-control"
                          style={{ width: "10%" }}
                          inputMode="numeric"    // Ensures numeric keypad on mobile
                          pattern="[0-9]*"       // Enforces numeric input
                          type="tel"    
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      )}
                      containerStyle={{
                        justifyContent: "center",
                        paddingBlock: "2%",
                      }}
                    />
                    </div>
                    {loading && (
                      <div className="d-flex align-items-center justify-content-center loader m-3">
                        <div
                          className="spinner-border text-black load-icon mx-1"
                          role="status"
                        ></div>
                        Verifying OTP...
                      </div>
                    )}{" "}
                    {/* Loader */}
                  </div>
                )}
                {step === 2 && (
                  <div className="secondaryForm">
                    <div className="upload_front">
                      <h6 class="mb-0 fw-bold">
                        {" "}
                        Upload {doctypes[activeDocument]?.item} Front Image
                      </h6>

                      <div className="row mb-3">
                        <div className="col-lg-10 m-auto mt-4">
                          <img
                            src={
                              doc_front
                                ? URL.createObjectURL(doc_front)
                                : "/images/pancard-front.png"
                            }
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="text-muted fs-12 mb-1">
                          <span className="text-red">*</span>Upload{" "}
                          {doctypes[activeDocument]?.item} Front
                        </div>
                        <input
                          type="file"
                          className="input-file"
                          onChange={(e) => {
                            setDocFront(e?.target.files[0]);
                          }}
                        />
                      </div>

                      <div className="mt-3">
                        <p className="mb-1">
                          <MdOutlineDoubleArrow className="text-green me-2" />
                          Make sure the image is completely in the frame
                        </p>
                        <p className="mb-1">
                          <MdOutlineDoubleArrow className="text-green me-2" />
                          Only the front of the pan card should be visible
                        </p>
                        <p>
                          <MdOutlineDoubleArrow className="text-green me-2" />
                          Make sure that your details are clearly visible
                        </p>
                      </div>

                      <div className="py-2">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="d-grid">
                              <button
                                type="button"
                                className="btn button-outline"
                                onClick={handleBackClick}
                                style={{ padding: "0.7rem" }}
                              >
                                Back
                              </button>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="d-grid">
                              <button
                                type="button"
                                className="btn custom_btn"
                                onClick={handleUploadFrontBackClick}
                              >
                                Proceed
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {step === 3 && (
                  <div className="secondaryForm">
                    <div className="upload_back">
                      <h6 class="mb-0 fw-bold">
                        {" "}
                        Upload {doctypes[activeDocument]?.item} Back Image
                      </h6>

                      <div className="row mb-3">
                        <div className="col-lg-10 m-auto mt-4">
                          <img
                            src={
                              doc_back
                                ? URL.createObjectURL(doc_back)
                                : "/images/pancard-back.png"
                            }
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <label className="text-muted fs-12 mb-1">
                          <span className="text-red">*</span>Upload{" "}
                          {doctypes[activeDocument]?.item} Back
                        </label>
                        <input
                          type="file"
                          className="input-file"
                          onChange={(e) => {
                            setDocBack(e?.target?.files[0]);
                          }}
                        />
                      </div>

                      <div className="mt-3">
                        <p className="mb-1">
                          <MdOutlineDoubleArrow className="text-green me-2" />
                          Make sure the image is completely in the frame
                        </p>
                        <p className="mb-1">
                          <MdOutlineDoubleArrow className="text-green me-2" />
                          Only the front of the pan card should be visible
                        </p>
                        <p>
                          <MdOutlineDoubleArrow className="text-green me-2" />
                          Make sure that your details are clearly visible
                        </p>
                      </div>
                      <div className="py-2">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="d-grid">
                              <button
                                type="button"
                                className="btn button-outline"
                                onClick={handleBackClick}
                                style={{ padding: "0.7rem" }}
                              >
                                Back
                              </button>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="d-grid">
                              <button
                                type="button"
                                className="btn custom_btn"
                                onClick={submitData}
                              >
                                {loading ? (
                                  <div
                                    className="spinner-border text-black load-icon mx-1"
                                    role="status"
                                  ></div>
                                ) : null}
                                Final Submission
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Identity_verification;
