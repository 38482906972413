import { toast } from "react-toastify";
import {
  setAuthInfo,
  setConditionsData,
  setLogOut,
  setPremiumStatus,
  setSession,
  setUserData,
} from "../redux/userInfoSlice";
import { setRefStakes, setStakes, setWalletData } from "../redux/walletSlice";
import {
  claimReward,
  fetchUser,
  fetchWallet,
  getLoginInfo,
  getStakeData,
  goPremium,
  googleSignIn,
  logOut,
  loginWithEmail,
  loginWithMobile,
  forgetEmailOrMobile,
  forgetOtpMatch,
  getStakeRefData,
  conditionsGet,
  claimRewardRef,
} from "./apis";
import { setLoginId } from "../redux/qrLoginSlice";

// value: provide decimal value
//d: provide how much digit you want after decimal
export function cutAfterDecimal(value, d) {
  const numstr = value?.toString();
  if (numstr) {
    const tnum =
      numstr?.indexOf("e") > -1
        ? Number(numstr)?.toLocaleString("fullwide", { useGrouping: false })
        : numstr;
    if (tnum?.indexOf(".") > -1) {
      const s = tnum?.slice(tnum?.indexOf("."));
      const endstr = s?.length >= d ? s?.slice(0, d) : s;
      return (
        tnum.slice(0, tnum?.indexOf(".")) +
        "" +
        (endstr.length > 1 ? endstr : ".0")
      );
    } else {
      return tnum;
    }
  } else {
    return 0;
  }
}

export function getAmPm(timestamp) {
  const date = new Date(timestamp);
  const hours = date.getHours();
  return hours >= 12 ? "PM" : "AM";
}

export function formatTime(time) {
  return time <= 9 ? "0" + time : time;
}

export function qrLoginInfoHandler(loginid, action, dispatch, cb, ref_code) {
  getLoginInfo(loginid, action, ref_code)
    .then((res) => {
      if (res) {
        if (res?.status == 200) {
          if (res?.user) {
            dispatch(setAuthInfo({ user: res?.user, login: true }));
            dispatch(setSession(res?.user?.tokenId));
            cb(true);
          } else {
            cb(res);
          }
        } else {
          cb(true);
        }
      } else {
        cb(true);
      }
    })
    .catch((e) => {
      console.log(e, "Error in qrLoginInfoHandler:actionHanlder.js");
      cb(true);
    });
}

export function logoutHandler(mobile, userId, sessionId, cb) {
  // console.log(mobile, userId, sessionId)
  logOut(mobile, userId, sessionId)
    .then((res) => {
      if (res?.logout) {
        cb(true);
      } else {
        cb(false);
      }
    })
    .catch((e) => {
      cb(false);
      console.log(e, "Error in logoutHandler()");
    });
}

export function fetchWalletHandler(mobile,tokenId, dispatch) {
  fetchWallet(mobile, tokenId)
    .then((res) => {
      if (res?.status == 200) {
        dispatch(setWalletData(res));
      }
    })
    .catch((e) => {
      console.log(e, "Error in fetchWalletHandler()");
    });
}

export function stakeHistory(userdata, dispatch, tokenId, cb) {
  if (userdata.userId) {
    const data = { mobile: userdata.mobile, tokenId: tokenId };
    getStakeData(data)
      .then((resp) => {
        // console.log(resp.data,' stakelist')
        if (resp.status == 200) {
          dispatch(setStakes({ stakes: resp.data, allstakes: resp.stakes }));
        } else {
          dispatch(setStakes({ stakes: [], allstakes: {} }));
        }
        if (cb) {
          cb();
        }
      })
      .catch((e) => {
        if (cb) {
          cb();
        }
        console.log(e, "Error");
      });
  }
}

export function stakeRefHistory(userdata, dispatch, tokenId, cb) {
  if (userdata.userId) {
    const data = { mobile: userdata.mobile, tokenId: tokenId };
    getStakeRefData(data)
      .then((resp) => {
        // console.log(resp.data,' stakelist')
        if (resp.status == 200) {
          dispatch(setRefStakes({ stakesRef: resp.data, allRefstakes: resp.stakes }));
        } else {
          dispatch(setRefStakes({ stakesRef: [], allRefstakes: {} }));
        }
        if (cb) {
          cb();
        }
      })
      .catch((e) => {
        if (cb) {
          cb();
        }
        console.log(e, "Error");
      });
  }
}

export function claimToken(userdata, tokenId, cb) {
  if (userdata.userId) {
    const data = {
      mobile: userdata.mobile,
      tokenId: tokenId,
    };
    claimReward(data)
      .then((resp) => {
        // console.log(resp.stakes,' stakelist')
        // Alert.alert(resp.message);
        if (cb) {
          cb(resp?.message);
        }
      })
      .catch((e) => {
        if (cb) {
          cb("Something went worng!");
        }
        console.log(e, "Error");
      });
  }
}
export function claimTokenRef(userdata, tokenId, stakeId, cb) {
  if (userdata.userId) {
    const data = {
      mobile: userdata.mobile,
      tokenId: tokenId,
      stakeId:stakeId,
    };
    claimRewardRef(data)
      .then((resp) => {
        // console.log(resp.stakes,' stakelist')
        // Alert.alert(resp.message);
        if (cb) {
          cb(resp?.message);
        }
      })
      .catch((e) => {
        if (cb) {
          cb("Something went worng!");
        }
        console.log(e, "Error");
      });
  }
}

export function fetchUserHandler(mobile, tokenId, dispatch) {
  fetchUser(mobile, tokenId)
    .then((res) => {
      if (res?.status == 200) {
        console.log(res.data)
        dispatch(setUserData(res?.data));
        // dispatch(setAuthInfo({ user: res?.data, login: true }));

        if (res?.data?.hasOwnProperty("premium")) {
          dispatch(setPremiumStatus(res?.data?.premium));
        }
        if (res?.data?.hasOwnProperty("login")) {
          if (!res?.data?.login) {
            dispatch(setLogOut());
            dispatch(setLoginId(""));
            window.location.href = "/login";
          }
        }
      }
    })
    .catch((e) => {
      console.log(e, "Error in fetchUserHandler()");
    });
}

export function loginWithEmailHandler(
  mobile,
  password,
  referrerId,
  otp,
  dispatch,
  loading,
  setmobilelogin,
  setIsEv,
  cb
) {
  loading(true);
  loginWithEmail(mobile, password, referrerId, otp)
    .then((res) => {
      loading(false);
      if (res?.status == 200) {
        // console.log(res);
        if (res?.askotp) {
          setIsEv(res.ev)
          cb(1);
        } else {
          setmobilelogin(true);
          dispatch(setAuthInfo({ user: res?.user, login: true }));
          dispatch(setSession(res?.user?.tokenId));
          setmobilelogin(true);
          toast.success(res?.message);
          cb(0);
        }
      } else {
        cb(0);
        toast.error(res?.message);
      }
    })
    .catch((e) => {
      cb(0);
      loading(false);
      console.log(e, "Error in fetchUserHandler()");
      toast.error("Sometihng went wrong");
    });
}

export function loginWithMobileHandler(
  mobile,
  password,
  referrerId,
  otp,
  dispatch,
  loading,
  setmobilelogin,
  cb
) {
  loading(true);
  loginWithMobile(mobile, password, referrerId, otp)
    .then((res) => {
      loading(false);
      if (res?.status == 200) {
        // console.log(res);
        if (res?.askotp) {
          cb(1);
        } else {
          setmobilelogin(true);
          dispatch(setAuthInfo({ user: res?.user, login: true }));
          dispatch(setSession(res?.user?.tokenId));
          setmobilelogin(true);
          toast.success(res?.message);
          cb(0);
        }
      } else {
        cb(0);
        toast.error(res?.message);
      }
    })
    .catch((e) => {
      cb(0);
      loading(false);
      console.log(e, "Error in fetchUserHandler()");
      toast.error("Sometihng went wrong");
    });
}

export function handleForgetPassword(cb,loading,emailandmobile){
  loading(true);
 return forgetEmailOrMobile(emailandmobile)
    .then((res) => {
      loading(false);
      if (res?.status == 200) {
        // console.log(res);
        if (res?.askotp) {
          cb(1);
        }else{
          cb(0);
        }
      } else {
        cb(0)
        loading(false);
        toast.error(res?.message);
      }
    })
    .catch((e) => {
      loading(false);
      console.log(e, "Error in fetchUserHandler()");
      toast.error("Sometihng went wrong");
    });
}

export function handleForgetOtpPassword(loading,setmobilelogin,setForgetStep,dispatch,emailandmobile,otp,newPassword, type){
  loading(true);
 return forgetOtpMatch(emailandmobile, otp, newPassword, type)
    .then((res) => {
      loading(false);
      if (res?.status == 200) {
        if(res?.otp){
          setForgetStep(1)
        }else{
          setmobilelogin(true);
          dispatch(setAuthInfo({ user: res?.user, login: true }));
          dispatch(setSession(res?.user?.tokenId));
          setmobilelogin(true);
          setForgetStep(0);
          toast.success(res?.message);
        }
        console.log(res,"MATCH___");
          
          // cb(0);
        
      } else {
        // cb(0)
        loading(false);
        toast.error(res?.message);
      }
    })
    .catch((e) => {
      loading(false);
      console.log(e, "Error in fetchUserHandler()");
      toast.error("Sometihng went wrong");
    });
}

export function googleSignInHandler(
  credential,
  setmobilelogin,
  dispatch,
  reffarrerId
) {
  googleSignIn(credential, reffarrerId)
    .then((res) => {
      if (res?.status == 200) {
        console.log(res, "google signin reposne");
        setmobilelogin(true);
        dispatch(setAuthInfo({ user: res?.user, login: true }));
        dispatch(setSession(res?.user?.tokenId));
        setmobilelogin(true);
        toast.success(res?.message);
      } else {
        toast.error(res?.message);
      }
    })
    .catch((e) => {
      console.log(e, "Error in googleSignInHandler()");
    });
}

export function goPremiumHandler(mobile, tokenId, dispatch, loading) {
  loading(true);
  goPremium(mobile, tokenId)
    .then((res) => {
      loading(false);
      if (res?.status == 200) {
        dispatch(setPremiumStatus(true));
        toast.success(res?.message);
      } else {
        toast.error(res?.message);
      }
    })
    .catch((e) => {
      loading(false);
      console.log(e, "Error in goPremiumHandler()");
      toast.error("Sometihng went wrong");
    });
}


export function fetchConditionsData(dispatch) {
  conditionsGet()
    .then((res) => {
      if (res?.status == 200) {
        dispatch(setConditionsData(res?.data));
      }
    })
    .catch((e) => {
      console.log(e, "Error in fetchWalletHandler()");
    });
}
