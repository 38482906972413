import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { enable2FA } from "../utils/apis";
import { fetchUserHandler } from "../utils/actionHandler";
import { toast } from "react-toastify";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";

const Authentication = () => {
  const { user, sessionId, userdata } = useSelector((state) => state.user);
  const theme = useSelector((state) => state.theme.theme);
  const [otp, setOTP] = useState("");
  const [loader, setloader] = useState(false);
  const [secret, setSecret] = useState("");
  const [qrUrl, setqrUrl] = useState("");
  const dispatch = useDispatch();
  const gauthmodelref = useRef();
  const action = ["generate", "enable", "check"];

  function gAuthEnable(apiAction) {
    enable2FA(user?.mobile, sessionId, apiAction, otp)
      .then((res) => {
        setloader(false);
        if (res?.status == 200) {
          if (res?.action === action[0]) {
            setSecret(res?.secret);
            setqrUrl(res?.qrCode);
            toast.success(res?.message);
          } else {
            fetchUserHandler(user?.mobile, sessionId, dispatch);
            gauthmodelref?.current?.click();
            toast.success(res?.message);
          }
        } else {
          toast.error(res?.message);
        }
      })
      .catch((e) => {
        setloader(false);
        console.log(e, "Error in change2fa");
        toast.error("Something went wrong");
      });
  }

  function change2fa() {
    if (user?.mobile) {
      setloader(true);
      gAuthEnable(action[1]);
    }
  }

  useEffect(() => {
    const gauthbutton = document.getElementById("gauth-enable");
    if (gauthbutton) {
      gauthbutton.addEventListener("click", () => {
        gAuthEnable(action[0]);
      });
    }
  }, []);

  useEffect(() => {
    if (otp?.length >= 6) {
      gAuthEnable(action[1]);
    }
  }, [otp]);

  return (
    <>
      <div className="model_btn">
        {!userdata?.gAuth ? (
          <button
            type="button"
            className="button-outline "
            data-bs-toggle="modal"
            data-bs-target="#authxampleModal"
            id="gauth-enable"
          >
            Setting
          </button>
        ) : (
          <span className="text-green fs-14">Enabled</span>
        )}
      </div>

      <div
        className="modal fade"
        id="authxampleModal"
        tabIndex="-1"
        aria-labelledby="authxampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-3">
            <div className="modal-header border-0">
              <h1 className="modal-title fs-6" id="authxampleModalLabel">
                security Verification
              </h1>
              <i
                className="fa-solid fa-xmark icon-color"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={gauthmodelref}
              />
            </div>
            <div className="modal-body">
              <div>
                <div>
                  {secret ? (
                    <>
                      <input
                        type="text"
                        className=" form-control"
                        defaultValue={secret}
                      />
                      <img
                        style={{
                          height: "30%",
                          maxWidth: "30%",
                          width: "30%",
                        }}
                        className="my-2"
                        src={qrUrl}
                        // value={qrUrl ? qrUrl : "testing"}
                        // viewBox={`0 0 256 256`}
                        // bgColor={
                        //   theme === "dark" ? "transparent" : "transparent"
                        // }
                        // fgColor={theme === "dark" ? "#fff" : "#000"}
                      />
                    </>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="exampleInputEmail4"
                    className="form-label mob-font text-muted"
                  >
                    <i className="fa-regular fa-envelope"></i> A verification
                    code would be found your Google Authenticator app. copy this
                    secret or scan this qr by your Google Authenticato app.
                  </label>
                  <input
                    type="text"
                    className="form-control "
                    id="exampleInputEmail4"
                    aria-describedby="emailHelp"
                    placeholder=" Enter Code"
                    value={otp}
                    onChange={(e) => setOTP(e.target.value)}
                  />
                  {!loader && !secret ? (
                    <div
                      style={{
                        position: "absolute",
                        flex: 1,
                        background: "rgba(0,0,0,0.8)",
                        color:"#fff",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        // height: "66%",
                        width: "100%",
                        borderRadius: 20,
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          top: "45%",
                          left: "25%",
                        }}
                      >
                        <div
                          className="spinner-border text-warning load-icon mx-1"
                          role="status"
                        ></div>
                        <span>fetching G-AUTH secret</span>
                      </div>
                    </div>
                  ) : null}
                  <div
                    id="emailHelp"
                    className="form-text"
                    style={{ fontSize: "12px" }}
                  >
                    Not able to receive verification code?{" "}
                    <em className="fst-normal" style={{ color: "#02FF01" }}>
                      Resend Verification code
                    </em>
                  </div>
                </div>
                <Link
                  className="text-lgray fs-12"
                 
                >
                  Having problems with verification?
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Authentication;
