import { configureStore } from "@reduxjs/toolkit";
import themeReducer from "./themeSlice";
import userInfoReducer from "./userInfoSlice";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import qrLoginSlice from "./qrLoginSlice";
import walletSlice from "./walletSlice";

const persistConfig = {
  key: "inrx-persist",
  storage: storage,
  blacklist: ["qrlogin", "wallet"],
  whitelist: ["user", "theme"],
};

const allReducers = combineReducers({
  user: userInfoReducer,
  theme: themeReducer,
  qrlogin: qrLoginSlice,
  wallet: walletSlice,
});

const persistedReducer = persistReducer(persistConfig, allReducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});

let persistor = persistStore(store);

export { store, persistor };
