import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loginId: '', // Default theme
};

const qrLoginSlice = createSlice({
  name: 'qrlogin',
  initialState,
  reducers: {
    setLoginId(state, action) {
      state.loginId = action.payload;
    },
  },
});

export const { setLoginId } = qrLoginSlice.actions;
export default qrLoginSlice.reducer;
